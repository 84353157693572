import { LOGIN_TYPES } from './ProjectConstants';
import { localStorageKeys } from './localStorageKeys';

// All helper functions of localstorage should come here

export const setLoginType = (loginType) => {
  localStorage.setItem(localStorageKeys.loginType, loginType);
};

export const getLoginType = (loginType) => localStorage.getItem(localStorageKeys.loginType) || LOGIN_TYPES.mesh;

export const clearLoginType = () => {
  localStorage.removeItem(localStorageKeys.loginType);
};
