import { showSnackbarAction, hideSnackbarAction } from 'actions/snackbar';

export const SNACKBAR_SEVERITIES = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export function hideSnackbar(timeout = 2000) {
  return function (dispatch) {
    setTimeout(() => {
      dispatch(hideSnackbarAction());
    }, timeout);
  };
}

export function showSuccessSnackbar(message = '', timeout) {
  return function (dispatch) {
    dispatch(showSnackbarAction({ severity: SNACKBAR_SEVERITIES.success, message }));
    dispatch(hideSnackbar(timeout));
  };
}

export function showErrorSnackbar(message = '', timeout = 5000) {
  return function (dispatch) {
    dispatch(showSnackbarAction({ severity: SNACKBAR_SEVERITIES.error, message }));
    dispatch(hideSnackbar(timeout));
  };
}

export function showInfoSnackbar(message = '', timeout = 5000) {
  return function (dispatch) {
    dispatch(showSnackbarAction({ severity: SNACKBAR_SEVERITIES.info, message }));
    dispatch(hideSnackbar(timeout));
  };
}
