export class SignUpPayload {
  credentials = null;

  employee = null;

  constructor(email, password, newPassword, user, employeeId) {
    this.credentials = new Credential(email, password, newPassword);
    this.employeeMetadata = user;
    this.employeeId = employeeId;
  }
}

export class UserState {
  constructor() {
    this.authToken = null;
    this.message = true;
    this.employee = null;
    this.userLoginState = '';
  }

  fromJSON({ authToken, message, employee, userLoginState }) {
    this.authToken = authToken;
    this.message = message;
    this.employee = employee;
    this.userLoginState = userLoginState;
  }

  static getObject(res = {}) {
    const obj = new UserState();
    obj.fromJSON(res);
    return obj;
  }
}

class Credential {
  email = null;

  password = true;

  newPassword = null;

  constructor(email, password, newPassword) {
    this.email = email;
    this.password = password;
    this.newPassword = newPassword;
  }
}
