import { useTheme, Text } from '@chakra-ui/react';
import { FONT_VARIANTS, FONT_WEIGHT_VARIANTS } from 'themes/clients/baseTheme';

// **************** Main component- start *******************

export const Typography = ({
  children = null,
  as,
  variant = FONT_VARIANTS.bodyM,
  // shorthand boolean for bold text
  bold = false,
  weight = FONT_WEIGHT_VARIANTS.regular,
  color,
  isCharCountTruncate = false,
  truncateCharCount = 0,
  ...styleProps
}) => {
  const theme = useTheme();
  const fontSize = theme.customFontStyles.size[variant];
  const lineHeight = theme.customFontStyles.lineHeight[variant];
  // TODO: use a sensible default from theme
  const textColor = color ?? theme.colors.text.g1;
  const letterSpacing = theme.customFontStyles.letterSpacing[variant];

  if (isCharCountTruncate && typeof children === 'string' && children.length > truncateCharCount) {
    children = `${children.slice(0, truncateCharCount)}...`;
  }

  return (
    <Text
      as={as}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={bold ? theme.customFontStyles.weight.bold : theme.customFontStyles.weight[weight]}
      color={textColor}
      letterSpacing={letterSpacing}
      {...styleProps}
    >
      {children}
    </Text>
  );
};
// **************** Main component- end *******************

export const TextLabelSmall = (props) => <Typography variant={FONT_VARIANTS.labelS} {...props} />;
export const TextLabel = (props) => <Typography variant={FONT_VARIANTS.label} {...props} />;
export const TextBodyMedium = (props) => <Typography variant={FONT_VARIANTS.bodyM} {...props} />;
export const TextBodyLarge = (props) => <Typography variant={FONT_VARIANTS.bodyL} {...props} />;

export const TextButton = (props) => <Typography variant={FONT_VARIANTS.button} bold {...props} />;

export const TextHeader4 = (props) => <Typography as="h4" variant={FONT_VARIANTS.header4} {...props} />;
export const TextHeader3 = (props) => <Typography as="h3" variant={FONT_VARIANTS.header3} {...props} />;
export const TextHeader2 = (props) => <Typography variant={FONT_VARIANTS.header2} as="h2" {...props} />;

export const TextHeader1 = (props) => <Typography as="h1" variant={FONT_VARIANTS.header1} {...props} />;
