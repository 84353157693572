// encoding safe cookie helper
export const docCookies = {
  getItem(sKey) {
    if (!sKey || !this.hasItem(sKey)) {
      return null;
    }
    return unescape(
      document.cookie.replace(
        new RegExp(`(?:^|.*;\\s*)${escape(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*`),
        '$1'
      )
    );
  },

  setItem(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return;
    }

    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Tue, 19 Jan 2038 03:14:07 GMT' : `; max-age=${vEnd}`;
          break;
        case String:
          sExpires = `; expires=${vEnd}`;
          break;
        case Date:
          sExpires = `; expires=${vEnd.toGMTString()}`;
          break;
        default:
      }
    }
    const x = `${escape(sKey)}=${escape(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${
      sPath ? `; path=${sPath}` : ''
    }${bSecure ? '; secure' : ''}`;
    document.cookie = x;
  },
  removeItem(sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return;
    }
    const x = `${escape(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${sPath ? `; path=${sPath}` : ''}${
      sDomain ? `; domain=${sDomain}` : ''
    }`;
    document.cookie = x;
  },
  hasItem(sKey) {
    const x = document.cookie;
    return new RegExp(`(?:^|;\\s*)${escape(sKey).replace(/[\-\.\+\*]/g, '\\$&')}\\s*\\=`).test(x);
  },
  keys: /* optional method: you can safely remove it! */ function () {
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/);
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = unescape(aKeys[nIdx]);
    }
    return aKeys;
  },
};
