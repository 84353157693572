export const routes = {
  surveyDetail: '/survey/:surveyId',
  meshStore: '/employee/:employeeId/meshStore/:summaryType',
  strengthDetail: '/employee/:employeeId/strengths/:strengthId',
  driverDetail: '/employee/:employeeId/drivers/:driverId',
  deliverableDetail: '/employee/:employeeId/deliverables/:deliverableId',
  discoverDetail: '/employee/:loggedInEmployeeId/discover/:employeeId',
  meshin: '/meshin',
  meshup: '/meshup',
  profile: '/employee/:employeeId',
  home: '/',
  feedDetail: '/feed/:feedId',
  managers: {
    insights: '/managers/insights/deliver',
    lifecycle: '/managers/lifecycle/hire',
    team: '/managers/team/:teamId',
  },
  login: '/login',
  loginWithRegion: '/login/:region',
  // netchex specific login routes
  loginNetChexRoute: '/login/company',
  loginNetChexCallbackRoute: '/login/callback',

  nonAuthCharts: {
    nonAuthEmailChart: '/nonAuthEmailChart',
  },
  staticReviewReportESignSuccess: '/eSignSuccess',
  logout: '/logout',
  msTeams: {
    index: '/msteams',
    Final: '/msteams/final',
    Login: '/msteams/login',
    Goals: '/msteams/goals',
    Tasks: '/msteams/tasks',
    Feedback: '/msteams/feedback',
    TeamHub: '/msTeamsTeamHub',
    Search: '/msTeamsSearch',
    TabConfig: '/msTeamsTabConfig',
    ConnectorConfig: '/msTeamsConnectorConfig',
    msTeamsPerformanceReview: {
      index: '/msTeamsReview',
      forms: '/msTeamsReview/:reviewId/employee/:employeeId',
    },
  },
  salesforce: {
    callback: '/sf-callback',
  },
  gmail: '/gmailplugin',
  outlook: {
    home: '/outlook',
    login: '/outlook/login',
  },
  selectOrganization: '/selectOrganization',
  emailRegister: '/signup',
  validateEmail: '/emailVerification',
  forgotPassword: '/forgotPassword',
  forgotPasswordConfirmation: '/forgotPasswordConfirmation',
  selectDepartment: '/selectDepartment',
  selectTeam: '/selectTeam',
  invite: '/invite',
  profileSetup: '/profileSetup',
  admin: '/settings',
  moduleSettings: '/moduleSettings',
  teamSettings: '/settings/teams/:teamId',
  // settings: '/settings/myProfile',
  referral: '/settings/referral',
  internalTools: {
    campaign: '/settings/internalTools/campaign',
  },
  settings: {
    index: '/settings',
    myProfile: '/settings/myProfile',
    referral: '/settings/referral',
    knowledgeHub: '/settings/knowledge-hub',
    team: '/settings/teams/:teamId',
    // not valid now, but keeping for redirect service
    meshTags: '/settings/meshTags',
    objectives: '/settings/objectives',
    // not valid now, but keeping for redirect service
    objectiveSettings: '/settings/objectiveSettings',
    competencies: '/settings/competencies',
    company: '/settings/company',
    modules: '/settings/modules',
    applause: '/settings/applause',
    employees: '/settings/employees',
    functionAndGrade: '/settings/function-grade',
    notifications: '/settings/notifications',
    myNotifications: '/settings/my-notifications', // need to get clarity on the route path
    customAccess: '/settings/customAccess',
    newUX: '/settings/newUX',
    // plugins routes
    plugins: {
      slack: '/settings/plugins/slack',
      msTeams: '/settings/plugins/msTeams',
      salesforce: '/settings/plugins/salesforce',
      jira: '/settings/plugins/jira',
      darwinbox: '/settings/plugins/darwinbox',
      hris: '/settings/plugins/hris',
    },
    // helpcenter routes
    userguide: '/settings/userguide',
    troubleshoot: '/settings/troubleshoot',
    checkIn: {
      settings: '/settings/checkIn/settings',
      questions: '/settings/checkIn/questions',
      preferences: '/settings/checkIn/preferences',
      templates: '/settings/checkIn/templates',
      calendar: '/settings/checkIn/calendar',
      verifyCalendar: '/calendar/verify/',
    },
    reportBug: '/settings/reportBug',
    // reviews routes
    review: {
      reviewProcess: '/settings/reviews/reviewCycles',
      reviewCycle: '/settings/reviews/reviewCycle/:formId/:step',
      questions: '/settings/reviews/questions',
      // questionDetails: '/settings/reviews/questionDetails/:questionId',
      reviewSettings: '/settings/reviews/calibrationSettings',
      reviewTemplate: {
        list: '/settings/reviews/template',
      },
    },
    engagement: {
      pulseSurvey: '/settings/engagement/pulse-survey',
      questions: '/settings/engagement/questions',
      pulseKickstart: '/settings/engagement/pulseKickstart/:formId/:step',
      drivers: '/settings/engagement/drivers',

      templates: '/settings/engagement/templates',
    },
    growth: {
      index: '/settings/level-up',
      jobFunctionTable: '/settings/level-up/:jobFunctionId',
    },
  },
  // tagDetail: '/tag/:tagId',
  teamSummary: '/teamSummary',
  // mobile only
  tasksSummary: '/tasksSummary',
  searchResults: '/search',
  performanceReview: {
    index: '/review',
    audit: '/review/audit',
    performancePreview: '/review/performancePreview',
    manage: '/review/manage',
    talentPlanning: '/review/calibrationView',
    insights: '/review/insights',
    // teamStatus: '/review/teamStatus',
    forms: '/review/:reviewId/employee/:employeeId',
    formsOld: '/review/:reviewId/employee/:employeeId/v0',
    result: '/review/result/:reviewId/employee/:employeeId',
    resultChartOnly: '/review/result/chart/:reviewId/employee/:employeeId',
  },
  analytics: {
    index: '/analytics',
    goals: '/analytics/goals',
    oneOnOne: '/analytics/oneOnOne',
    feedback: '/analytics/feedback',
    levelUp: '/analytics/develop',
    continuousFeedback: '/analytics/feedback',
  },
  engagement: {
    index: '/engagement',
    surveyQuestionnaire: '/engagement/survey/:surveyId',
    pastSurvey: '/engagement/submittedSurvey/:surveyId',
    manage: '/engagement/insights',
  },
  initiative: {
    index: '/initiative',
    audit: '/initiative/audit',
    cards: '/initiative/cards',
  },
  template: {
    review: {
      index: '/template/review/:templateId',
      preview: '/template/review/:templateId/preview',
    },
    checkIn: {
      index: '/template/checkin/:templateId',
    },
    engagement: {
      index: '/template/engagement/:templateId',
      preview: '/template/engagement/:templateId/preview',
    },
  },
  oneOnOne: {
    index: '/oneOnOne',
    instance: '/oneOnOne/:instanceId',
    legacyInstance: '/oneOnOne/legacy/id/:instanceId',
    masterPageRabbit: '/oneOnOne/:masterId',
    instanceRabbit: '/oneOnOne/:masterId/:instanceId',
    timeline: '/oneOnOne/:instanceId/timeline',
    audit: '/oneOnOne/audit',
    insights: '/oneOnOne/insights',
  },
  levelUp: {
    index: '/develop',
    myTeam: '/develop/myTeam',
    myTeamInsights: '/analytics/develop/myTeam',
  },
  continuousFeedback: {
    index: '/feedback',
    myPraisesAndAdvices: '/feedback/myPraisesAndAdvices',
  },
  goals: {
    index: '/goals',
    audit: '/goals/audit',
    list: '/goals/list',
    card: '/goals/card',
    tree: '/goals/tree',
    insights: '/goals/insights',
    archived: '/goals/archived',
  },
  personaInsights: {
    index: '/personaInsights',
  },
  embeddedAnalytics: {
    index: '/embeddedAnalytics/:id',
    connectIframe: '/connect-visier',
  },
};
