import { routes } from 'constants/routes';

import { endpoints, isInternalMeshServer } from '../constants/endpoints';
import { sendRequest } from './sendRequest';

export const userEmailStateApi = (email) => {
  const url = endpoints.auth.userEmailState;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: {
      email,
    },
  });
};

export const userEmailRegisterApi = (email) => {
  const url = endpoints.auth.userEmailRegister;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: {
      email,
    },
  });
};

export const validateEmailApi = ({ email, temporaryPassword, referralCode, timezoneOffset }) => {
  const url = endpoints.auth.validateEmail;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: { email, temporaryPassword, referralCode, timezoneOffset },
  });
};

export const updateEmployeeApi = (employee, uuid) => {
  const url = `${endpoints.auth.updateEmployee}/${uuid}`;
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    body: { ...employee },
    queryParams: {
      viaUI: true,
    },
  });
};

export const signupUserApi = (user) => {
  const url = endpoints.auth.singup;
  return sendRequest({
    url,
    method: 'POST',
    body: user,
    credentials: 'include',
  });
};

export const signinUserApi = ({ email, password }) => {
  const url = endpoints.auth.signin;
  return sendRequest({
    url,
    method: 'POST',
    body: {
      email,
      password,
    },
    credentials: 'include',
  });
};

// {"message":"success","entity":[{"loginType":"SAML","text":"Login","url":"http://localhost:8080/storage-layer-0.0.1-SNAPSHOT/service/custom/auth/login/signIn"}]}
export const getLoginOptionsApi = () => {
  const url = endpoints.auth.loginType;
  return sendRequest({
    url,
    method: 'GET',
    // credentials: 'include'
  });
};

export const getAuth0LoginUrlApi = ({ email, invitationId, organization }) => {
  const url = isInternalMeshServer ? endpoints.auth.auth0LoginInternal : endpoints.auth.auth0Login;

  const baseUrl = `${window.location.origin}/login`;

  return sendRequest({
    url,
    method: 'GET',
    queryParams: {
      baseUrl,
      ...(!invitationId && { email }),
      ...(organization && { organization }),
      ...(invitationId && { invitation: invitationId }),
    },
  });
};

export const getAuth0LogoutUrlApi = () => {
  const url = endpoints.auth.auth0Logout;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
};

export const auth0AuthenticateApi = (code) => {
  const url = endpoints.auth.auth0Authenticate;

  const baseUrl = `${window.location.origin}/`;

  return sendRequest({
    url,
    method: 'GET',
    queryParams: { code, baseUrl },
    credentials: 'include',
  });
};

export const msTeamsConnectUserApi = (userObjectId) => {
  const url = endpoints.auth.msTeamsConnectUserApi;

  return sendRequest({
    url,
    method: 'PUT',
    queryParams: { 'aad-object-id': userObjectId },
    credentials: 'include',
  });
};

export const msTeamsDisconnectUserApi = () => {
  const url = endpoints.auth.msTeamsConnectUserApi;

  return sendRequest({
    url,
    method: 'DELETE',
    credentials: 'include',
  });
};

export const profilePhotoUploadApi = ({ attachment, mediaType }, employeeId) => {
  const url = endpoints.auth.photo(employeeId);
  const data = new FormData();
  data.append('attachment', attachment);
  data.append('mediaType', mediaType);
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: data,
    isMultiPart: true,
  });
};

export const getEmployeeApi = (employeeId) => {
  const url = `${endpoints.auth.employee}/${employeeId}`;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
};

export const resetPasswordApi = ({ email, password, newPassword }) => {
  const url = endpoints.auth.resetPassword;
  return sendRequest({
    url,
    method: 'POST',
    body: {
      email,
      password,
      newPassword,
    },
    credentials: 'include',
  });
};

export const resetAuth0PasswordApi = () => {
  const url = endpoints.auth.resetAuth0Password;

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
  });
};

export const userLoginEventApi = ({ employeeId, userLoginEvent, userAction }) => {
  const url = endpoints.auth.userLoginEvent(employeeId);
  return sendRequest({
    url,
    method: 'POST',
    queryParams: { userLoginEvent, userAction },
    credentials: 'include',
  });
};

export const forgotPasswordApi = ({ email }) => {
  const url = endpoints.auth.forgotPassword;
  return sendRequest({
    url,
    method: 'POST',
    queryParams: { email },
    credentials: 'include',
  });
};

export const forgotPasswordConfirmationApi = ({ email, verificationCode, password }) => {
  const url = endpoints.auth.forgotPasswordConfirmation;
  return sendRequest({
    url,
    method: 'POST',
    body: { email, verificationCode, password },
    credentials: 'include',
  });
};

// NetChex login

export const getNetChexAuth0LoginUrlApi = () => {
  const url = endpoints.auth.netChexAuthUrl;

  const baseUrl = window.location.origin;

  return sendRequest({
    url,
    method: 'GET',
    queryParams: {
      baseUrl,
    },
  });
};

export const netChexAuthorizeCodeApi = ({ code, companyId }) => {
  const url = endpoints.auth.netChexAuthorizeCode;

  const baseUrl = window.location.origin;

  return sendRequest({
    url,
    method: 'GET',
    queryParams: { code, companyId, baseUrl },
    credentials: 'include',
  });
};
