/* eslint-disable default-case */
import { useCallback, useEffect, useState } from 'react';

import { Flex } from '@chakra-ui/react';
import { Button } from '@mesh.ai/rabbit';

import ContentWrapper from './ContentWrapper';
import { useGetAllFilters } from './dataHooks';

const ENABLED_FILTERS = {
  LOCATION: 'LOCATION',
  COMPANY_ID: 'COMPANY_ID',
};

const FILTER_KEY_MAP = {
  LOCATION: 'Location',
  COMPANY_ID: 'Company',
};
const CategoryMultiSelect = ({ selectedOptions, onSave, onCancel }) => {
  const [selectedCategory, setSelectedCategory] = useState();
  const [categoryData, setCategoryData] = useState({});
  const [categoryListMetaData, setCategoryListMetaData] = useState([]);
  const [selectedCategoryOptionList, setSelectedCategoryOptionList] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});

  const { data, isLoading } = useGetAllFilters();

  // eslint-disable-next-line consistent-return
  const isOptionSelected = (optionLabel, optionId, categoryKey) => {
    const categorySelectedOptions = (selectedOptions && selectedOptions[categoryKey]) || [];

    switch (categoryKey) {
      case ENABLED_FILTERS.LOCATION: {
        if (categorySelectedOptions.includes(optionLabel)) return true;
        return false;
      }

      case ENABLED_FILTERS.COMPANY_ID: {
        const targetOption = categorySelectedOptions.find((selectedOption) => {
          const { companyName, id } = selectedOption || {};
          return optionLabel === companyName && optionId === id;
        });
        if (targetOption) return true;
        return false;
      }
    }
  };

  // eslint-disable-next-line default-param-last
  const getFiltersList = (filters = [], key) => {
    let filterList = [];
    switch (key) {
      case ENABLED_FILTERS.LOCATION: {
        filterList = filters.map((filter) => ({
          label: filter,
          isSelected: isOptionSelected(filter, null, ENABLED_FILTERS.LOCATION),
        }));
        break;
      }

      case ENABLED_FILTERS.COMPANY_ID: {
        filterList = filters.map((filter) => ({
          id: filter.id,
          label: filter.companyName,
          isSelected: isOptionSelected(filter.companyName, filter.id, ENABLED_FILTERS.COMPANY_ID),
        }));
      }
    }
    return filterList;
  };

  const getCategoryListMetaData = (categoriesData) => {
    const categories = [];
    if (categoriesData) {
      Object.keys(categoriesData).forEach((categoryName, index) => {
        const subItemCount = Array.isArray(categoriesData[categoryName]) && categoriesData[categoryName].length;
        categories.push({
          label: FILTER_KEY_MAP[categoryName],
          id: index,
          subItemCount,
          categoryKey: categoryName,
        });
      });
    }
    return categories;
  };

  const getOptionList = useCallback((category) => categoryData && categoryData[category?.categoryKey], [categoryData]);

  const getSelectedFilters = useCallback(() => {
    const locationFilters = categoryData[ENABLED_FILTERS.LOCATION];
    const locationPayloadData = locationFilters
      ?.filter((location) => location?.isSelected)
      ?.map((location) => location.label);

    const companyFilters = categoryData[ENABLED_FILTERS.COMPANY_ID];
    const companyPayloadData = companyFilters
      ?.filter((company) => company?.isSelected)
      ?.map((company) => ({
        id: company?.id,
        companyName: company?.label,
      }));

    return {
      [ENABLED_FILTERS.LOCATION]: locationPayloadData?.length ? locationPayloadData : null,
      [ENABLED_FILTERS.COMPANY_ID]: companyPayloadData?.length ? companyPayloadData : null,
    };
  }, [categoryData]);

  useEffect(() => {
    setCategoryData({
      [ENABLED_FILTERS.LOCATION]: data && getFiltersList(data[ENABLED_FILTERS.LOCATION], ENABLED_FILTERS.LOCATION),
      [ENABLED_FILTERS.COMPANY_ID]:
        data && getFiltersList(data[ENABLED_FILTERS.COMPANY_ID], ENABLED_FILTERS.COMPANY_ID),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setCategoryListMetaData(getCategoryListMetaData(categoryData));

    if (categoryListMetaData && categoryListMetaData[0] && !selectedCategory) {
      const firstCategoryInTheList = categoryListMetaData[0];
      setSelectedCategory(firstCategoryInTheList);
    }

    setSelectedCategoryOptionList(getOptionList(selectedCategory));

    setSelectedFilters(getSelectedFilters());
  }, [categoryData, selectedCategory, categoryListMetaData, getOptionList, getSelectedFilters]);

  const handleClearAll = () => {
    const categories = { ...categoryData };
    Object.keys(categories).forEach((categoryKey) => {
      const categoryOptions = categories[categoryKey];
      const checkBoxClearedOptions = categoryOptions.map((option) => ({ ...option, isSelected: false }));
      categories[categoryKey] = checkBoxClearedOptions;
    });
    setCategoryData(categories);
  };

  const handleCheckbox = (checkAction, targetOptionLabel) => {
    const targetCategoryKey = selectedCategory?.categoryKey;
    const targetCategoryOptions = categoryData && categoryData[targetCategoryKey];
    const updatedCategoryOptions = targetCategoryOptions?.map((category) => {
      if (targetOptionLabel === category.label) {
        const updatedOption = { ...category, isSelected: checkAction };
        return updatedOption;
      }
      return category;
    });
    const updatedData = { ...categoryData };
    updatedData[targetCategoryKey] = updatedCategoryOptions;
    setCategoryData(updatedData);
  };

  const isNoFilterSelected = () => {
    const selectedLocationFiltersCount =
      selectedFilters && selectedFilters[ENABLED_FILTERS.LOCATION] && selectedFilters[ENABLED_FILTERS.LOCATION].length;
    const selectedCompanyFilters =
      selectedFilters &&
      selectedFilters[ENABLED_FILTERS.COMPANY_ID] &&
      selectedFilters[ENABLED_FILTERS.COMPANY_ID].length;

    if (!selectedLocationFiltersCount && !selectedCompanyFilters) {
      return true;
    }
    return false;
  };

  return (
    <Flex flexDir="column" w="full" h="408px">
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
          <div>Loading...</div>
        </Flex>
      ) : (
        <ContentWrapper
          categoryListMetaData={categoryListMetaData}
          handleCategorySelect={setSelectedCategory}
          selectedCategory={selectedCategory}
          handleCheckbox={handleCheckbox}
          selectedCategoryOptionList={selectedCategoryOptionList}
        />
      )}

      <Flex h="72px" bg="surface.F5F7F9" justifyContent="space-between" alignItems="center" px="16px">
        <Button variant="text" status="error" style={{ background: 'transparent' }} onClick={handleClearAll}>
          Clear all
        </Button>
        <Flex gap="12px">
          <Button variant="transparentOutline" style={{ width: '80px' }} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="filled"
            style={{ width: '80px' }}
            onClick={() => onSave(selectedFilters)}
            disabled={isNoFilterSelected()}
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CategoryMultiSelect;
