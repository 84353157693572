import type { MouseEvent } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Checkbox, Label } from '@mesh.ai/rabbit';
import { useDispatch } from 'react-redux';
import { SectionWrapper } from 'screens/admin/Reviews/ReviewProcessV2Intent/components/SectionWrapper';

import { SelectDashed } from 'uiToolkitV1/Select';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

import { updateEngagementSurveyCreation, useSelectEngagementReminders } from '../SurveyCreation.slice';
import type { Reminders } from '../types';

export const reminderOptions = [
  { label: '1 day', value: '1' },
  { label: '2 days', value: '2' },
  { label: '3 days', value: '3' },
  { label: '4 days', value: '4' },
  { label: '5 days', value: '5' },
  { label: '6 days', value: '6' },
  { label: '7 days', value: '7' },
  { label: '10 days', value: '10' },
  { label: '15 days', value: '15' },
  { label: '20 days', value: '20' },
];

const CustomOption = ({ data, isSelected, innerRef, innerProps }) => {
  // Prevent default behavior on mouse down to avoid triggering a blur that could close the menu
  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  return (
    <Flex ref={innerRef} alignItems="center" gap="8px" p="8px" onMouseDown={handleMouseDown} {...innerProps}>
      <Checkbox id={`reminder-${data.value}`} checked={isSelected} />
      <Label htmlFor={`reminder-${data.value}`}>{data.label}</Label>
    </Flex>
  );
};

export const SurveyReminderSection = () => {
  const { reminders } = useSelectEngagementReminders();
  const dispatch = useDispatch();

  const handleChange = (updatedSelectedOptions: Reminders) => {
    dispatch(updateEngagementSurveyCreation({ reminders: updatedSelectedOptions }));
  };

  return (
    <SectionWrapper title="Reminders" subTitle="Set reminders settings">
      <Flex gap="8px" alignItems="center">
        <TextBodyMedium>Send reminders</TextBodyMedium>
        <Box minW="200px">
          <SelectDashed
            placeholder="Select"
            value={reminders}
            options={reminderOptions}
            handleChange={handleChange}
            isMulti
            getOptionValue={undefined}
            getOptionLabel={undefined}
            selectRef={undefined}
            components={{ Option: CustomOption }}
            menuPlacement="top"
            styles={{ menu: (base: object) => ({ ...base, width: '200px' }) }}
          />
        </Box>
        <TextBodyMedium>before due date</TextBodyMedium>
      </Flex>
    </SectionWrapper>
  );
};
