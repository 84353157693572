import { L3_PATHS } from 'Deer/constants/routes';
import { matchPath, useLocation } from 'react-router-dom';

const useReviewCreationFormId = () => {
  const location = useLocation();
  const route = L3_PATHS.company.review.reviewCreation;

  const match =
    matchPath(location.pathname, {
      path: route,
      exact: true,
    }) ?? {};

  const { reviewId } = match.params ?? {};

  return reviewId;
};

export default useReviewCreationFormId;
