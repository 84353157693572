import { Box, Flex } from '@chakra-ui/react';

import { TextBodyMedium, TextLabel } from 'uiToolkitV1/Typography';

export const RestrictAccessContainer = ({ restrictAccess, children }) => {
  if (restrictAccess) {
    return (
      <Box cursor="initial" pointerEvents="none" tabIndex={-1} opacity={0.5}>
        {children}
      </Box>
    );
  }
  return children;
};

export const SectionWrapper = ({
  title,
  subTitle,
  rightComp = null,
  withBorder = false,
  restrictAccess = false,
  children,
}) => (
  <Box
    borderRadius="8px"
    bg="white"
    p={3}
    border={withBorder ? '1px solid' : undefined}
    borderColor={withBorder ? 'neutral.F5F7F9' : undefined}
  >
    <Flex
      borderBottom="1px solid"
      borderColor="border.CFD7DF"
      pt="2"
      pb={3}
      mb={3}
      justifyContent="space-between"
      pr={3}
    >
      <Flex flexDir="column">
        <TextBodyMedium bold>{title}</TextBodyMedium>
        <TextLabel color="text.g2">{subTitle}</TextLabel>
      </Flex>
      <RestrictAccessContainer restrictAccess={restrictAccess}>
        <Flex alignItems="center">{rightComp}</Flex>
      </RestrictAccessContainer>
    </Flex>

    <RestrictAccessContainer restrictAccess={restrictAccess}>
      <Flex flexDir="column" gap={4}>
        {children}
      </Flex>
    </RestrictAccessContainer>
  </Box>
);
