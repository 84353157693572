import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { REVIEW_TYPE_OPTIONS } from 'screens/admin/Reviews/ReviewProcessV2Intent/sections/NameAndReviewees';

import { REVIEW_PROVIDER_TYPES } from 'constants/ProjectConstants';

// import { REVIEW_PROVIDER_TYPES } from 'constants/ProjectConstants';

// export const REVIEWERS_PEER_POOLS = {
//   SENIORS: 'SENIORS',
//   JUNIORS: 'JUNIORS',
//   OUTSIDE_TEAM: 'OUTSIDE_TEAM',
//   PRIMARY_TEAM: 'PRIMARY_TEAM',
// };

export const REVIEW_FORM_STAGE = {
  DRAFT: 'DRAFT',
  IN_PROGRESS: 'IN_PROGRESS',
  PUBLISHED: 'PUBLISHED',
};

export const AUTO_UPDATE_STATUSES = {
  IS_SAVING: 'IS_SAVING',
  UPDATED: 'UPDATED',
  IS_ERROR: 'IS_ERROR',
};

export const REVIEW_PROVIDER_TYPE_LABELS_V1 = {
  [REVIEW_PROVIDER_TYPES.SELF]: 'Self',
  [REVIEW_PROVIDER_TYPES.MANAGER]: 'Manager',
  [REVIEW_PROVIDER_TYPES.PEER]: 'Peer',
  [REVIEW_PROVIDER_TYPES.UPWARD]: 'Upward',
  [REVIEW_PROVIDER_TYPES.DLM]: 'Dotted-line manager',
  [REVIEW_PROVIDER_TYPES.EXTERNAL]: 'External reviewer',
};

const initialState = {
  reviewCycleId: null,
  reviewStage: REVIEW_FORM_STAGE.DRAFT,
  feedbackFormId: null,
  // isDraft: true,
  // isPublished: false,

  // Fields
  reviewCycleName: '',
  reviewType: REVIEW_TYPE_OPTIONS[0].value,
  isAllEmployees: true,
  // all the employees of org
  totalEmployeeCount: 0,
  // the applicable employees for review
  selectedEmployeeCount: 0,

  triggerDaysCount: 30,

  // final checkboxes
  isSendLaunchComm: false,
  isLaunchConfirmationChecked: false,

  enabledProviderTypes: [],
  providerTypeToTemplateMap: {},

  reviewerSettings: {
    isAutoAcceptPeerNomination: false,
    isAutoAcceptDlmNomination: false,
    isAllowPeerNominationBySelf: false,
    minPeers: 0,
    maxPeers: 0,
  },

  // -------- for one time review ----------------
  reviewPeriodStart: null,
  reviewPeriodEnd: null,
  // reviewPeriodStart: '2025-03-27',
  // reviewPeriodEnd: '2025-04-16',
  timelines: {},
  reminders: {},

  // ------ for continuous(triggered) review --------
  reviewPeriodDetails: {},
  // reviewPeriodDetails: {
  //   startDateOffset: 1,
  //   endDateOffset: 30,
  // },
  providerTimelineDetails: {},

  // configure panels
  isFormsSettingsPanelOpen: false,
  isTimelineRemindersPanelOpen: false,
  isValidationPanelOpen: false,
  isCalibrationAndDeliverablesPanelOpen: false,

  isConfirmationModalOpen: false,

  autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED,
  calibrationAndDeliverables: {},

  // reviewerSettings: [

  //     feedbackFormId: null,
  //     enabledProviderTypes: [REVIEW_PROVIDER_TYPES.MANAGER, REVIEW_PROVIDER_TYPES.SELF],
  //     providerTypeToTemplateMap: [
  //       // {
  //       //   template: {
  //       //     id: 1000000000633,
  //       //     created: '2023-06-20T07:14:09.000+00:00',
  //       //     name: '123',
  //       //     formType: 'REVIEW',
  //       //     createdByEmployeeId: 15437,
  //       //     isArchived: false,
  //       //     questionCount: 0,
  //       //     templateSetting: {
  //       //       id: 1000000000524,
  //       //       templateId: 1000000000633,
  //       //       overallRatingDistribution: {
  //       //         PERFORMANCE: 50,
  //       //         POTENTIAL: 40,
  //       //         QNA: 10,
  //       //       },
  //       //       isEnableGoalLevelQuestion: false,
  //       //       carryForwardAgenda: false,
  //       //     },
  //       //     createdByEmployee: null,
  //       //     isSample: null,
  //       //     ongoingReviewCycleCount: 0,
  //       //     createdByEmployeeMiniProfile: null,
  //       //   },
  //       //   providerType: 'MANAGER',
  //       // },
  //     ],

  // ],

  // data for each step
  // review type and details
  // reviewTypeAndDetails: {},

  // cohorts
  // cohorts: [],
  // hasCustomSettings: false,
  // isRevieweesOverlappingEmployees: false,
  // isAllEmployees: true,
  // // all the employees of org
  // totalEmployeeCount: 0,
  // // the applicable employees for review
  // selectedEmployeeCount: 0,

  // reviewers
  // reviewers: [],

  // // elements
  // elements: [],

  // // form filling experience
  // formFillingSettings: [],

  // // calibration
  // calibrationScoreSettings: {},
  // gridDefinition: {},
  // ui only calibration grid metadata
  gridMetaData: {
    boxCount: 0,
    rowsCount: 0,
    columnsCount: 0,
  },

  // // timelines
  // timelines: [],

  // // share results
  // shareResults: [],

  // // validations
  // validations: {
  //   hasEmployeesWithMultipleViews: false,
  //   isManagerMissingAcknowledged: true,
  //   isGoalsMissingAcknowledged: true,
  //   isGradesMissingAcknowledged: true,
  // },
};

export const reviewCreationV1 = createSlice({
  name: 'reviewCreationV1',
  initialState,
  reducers: {
    resetReviewCreation: () => initialState,
    // setReviewCreationStep: (state, action) => {
    //   state.step = action.payload;
    // },

    setReviewCreationKey: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        if (key === 'reviewPeriodDetails' && action.payload[key] === null) {
          state.reviewPeriodDetails = {};
          return;
        }
        if (key === 'providerTimelineDetails' && action.payload[key] === null) {
          state.providerTimelineDetails = {};
          return;
        }
        state[key] = action.payload[key];
      });
      // state = { ...state, ...action.payload };
      // return state;
    },

    setReviewCreationReviewerSetting: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.reviewerSettings[key] = action.payload[key];
      });
      // state = { ...state, ...action.payload };
      // return state;
    },

    setReviewPeriodDetails: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.reviewPeriodDetails[key] = action.payload[key];
      });
      // state = { ...state, ...action.payload };
      // return state;
    },

    setReviewCreationTimelines: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.timelines[key] = action.payload[key];
      });
      // state = { ...state, ...action.payload };
      // return state;
    },

    setReviewDateOffset: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        // eslint-disable-next-line array-callback-return
        action.payload[key].map(({ offsetType, value }) => {
          state.providerTimelineDetails[key][offsetType] = value;
        });
      });
    },

    setReviewCreationCalibrationAndDeliverables: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state.calibrationAndDeliverables[key] = action.payload[key];
      });
    },

    setReviewCreationCalibrationQuestion: (state, action) => {
      if (!state.calibrationAndDeliverables.calibrationQuestion) {
        state.calibrationAndDeliverables.calibrationQuestion = {};
      }
      Object.keys(action.payload).forEach((key) => {
        state.calibrationAndDeliverables.calibrationQuestion[key] = action.payload[key];
      });
    },

    setReviewCreationOutcomeQuestion: (state, action) => {
      if (!state.calibrationAndDeliverables.outcomeQuestion) {
        state.calibrationAndDeliverables.outcomeQuestion = {};
      }
      Object.keys(action.payload).forEach((key) => {
        state.calibrationAndDeliverables.outcomeQuestion[key] = action.payload[key];
      });
    },

    // setReviewCycleId: (state, action) => {
    //   state.reviewCycleId = action.payload;
    // },

    // reviewCreationGoToNextStep: (state) => {
    //   const currentIndex = REVIEW_CYCLE_STEPS_ORDER.indexOf(state.step);
    //   if (currentIndex === REVIEW_CYCLE_STEPS_ORDER.length - 1) {
    //     return;
    //   }

    //   const nextStep = REVIEW_CYCLE_STEPS_ORDER[currentIndex + 1];
    //   state.step = nextStep;
    // },
    // reviewCreationGoToPrevStep: (state) => {
    //   const currentIndex = REVIEW_CYCLE_STEPS_ORDER.indexOf(state.step);
    //   if (currentIndex === 0) {
    //     return;
    //   }

    //   const previousStep = REVIEW_CYCLE_STEPS_ORDER[currentIndex - 1];
    //   state.step = previousStep;
    // },

    // setReviewName: (state, action) => {
    //   state.name = action.payload;
    // },

    // // review type and details
    // setReviewTypeAndDetails: (state, action) => {
    //   state.reviewTypeAndDetails = { ...state.reviewTypeAndDetails, ...action.payload };
    // },

    // setReviewMetaData: (state, action) => {
    //   const { currentStage, lastEnabledSection } = action.payload;
    //   state.currentStage = currentStage;
    //   state.isDraft = currentStage === REVIEW_FORM_STAGE.DRAFT;
    //   state.isPublished = currentStage === REVIEW_FORM_STAGE.PUBLISHED;
    //   // state.lastEnabledSection = lastEnabledSection;
    // },

    // cohorts

    // setIsRevieweesCustomViewsSettings: (state, action) => {
    //   state.hasCustomSettings = action.payload;
    // },

    // setIsRevieweesOverlappingEmployees: (state, action) => {
    //   state.isRevieweesOverlappingEmployees = action.payload;
    // },

    // setTotalEmployeeCount: (state, action) => {
    //   state.totalEmployeeCount = action.payload;
    // },

    // setSelectedEmployeeCount: (state, action) => {
    //   state.selectedEmployeeCount = action.payload;
    // },

    // reviewers
    //   setReviewers: (state, action) => {
    //     state.reviewers = action.payload;
    //   },

    //   setReviewerViewSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
    //     // const targetItem = state.reviewers.find(
    //     //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
    //     // );
    //     const targetItem = state.reviewers.find((r) => r.feedbackFormId === feedbackFormId);
    //     // if (!targetItem.length) {
    //     //   return;
    //     // }
    //     targetItem.reviewerSettings = {
    //       ...targetItem.reviewerSettings,
    //       ...settings,
    //     };
    //   },

    //   //   state.reviewers[index].reviewerSettings = {
    //   //     ...state.reviewers[index].reviewerSettings,
    //   //     ...settings
    //   //   };
    //   // },
    //   setReviewerSameSettingAsFeedbackFormId: (state, action) => {
    //     const { feedbackFormId, settingSameAsFormId } = action.payload;
    //     const index = state.reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
    //     if (index === -1) {
    //       return;
    //     }
    //     state.reviewers[index].settingSameAsFormId = settingSameAsFormId;
    //     // if (settingSameAsFormId === null) {
    //     //   return;
    //     // }
    //     // const sourceItem = state.reviewers.find((r) => r.cohort.id === settingSameAsFormId);
    //     // if (!sourceItem) {
    //     //   return;
    //     // }
    //     // // copy all settings from source item to target item
    //     // state.reviewers[index].reviewerSettings = sourceItem.reviewerSettings;
    //   },

    //   // elements
    //   setElements: (state, action) => {
    //     state.elements = action.payload;
    //   },

    //   setElementsTemplateSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;
    //     const { template, providerType } = settings;

    //     // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
    //     // const targetItem = state.elements.find(
    //     //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
    //     // );
    //     const targetItem = state.elements.find((r) => r.feedbackFormId === feedbackFormId);
    //     // if (!targetItem.length) {
    //     //   return;
    //     // }

    //     const matchingTemplateIndex = targetItem.elementSettings.providerTypeToTemplateMap.findIndex(
    //       (r) => r.providerType === providerType
    //     );
    //     targetItem.elementSettings.providerTypeToTemplateMap[matchingTemplateIndex].template = template;
    //   },
    //   setElementsScoreSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
    //     // const targetItem = state.elements.find(
    //     //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
    //     // );
    //     const targetItem = state.elements.find((r) => r.feedbackFormId === feedbackFormId);
    //     // if (!targetItem.length) {
    //     //   return;
    //     // }

    //     targetItem.elementSettings.elementScoreSettings = {
    //       ...targetItem.elementSettings.elementScoreSettings,
    //       ...settings,
    //     };
    //   },
    //   setElementsSameSettingAsFeedbackFormId: (state, action) => {
    //     const { feedbackFormId, settingSameAsFormId } = action.payload;
    //     const index = state.elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
    //     if (index === -1) {
    //       return;
    //     }
    //     state.elements[index].settingSameAsFormId = settingSameAsFormId;
    //   },

    //   // form filling experience
    //   setFormFillings: (state, action) => {
    //     state.formFillingSettings = action.payload;
    //   },
    //   setFormFillingsSameSettingAsFeedbackFormId: (state, action) => {
    //     const { feedbackFormId, settingSameAsFormId } = action.payload;
    //     const index = state.formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
    //     if (index === -1) {
    //       return;
    //     }
    //     state.formFillingSettings[index].settingSameAsFormId = settingSameAsFormId;
    //   },
    //   setSelfFormFillingScoreSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // update the target item plus any other view wise setting that copies this setting
    //     const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
    //     if (matchingTargetItems.length === 0) {
    //       return;
    //     }
    //     matchingTargetItems.forEach((settingItem) => {
    //       settingItem.formFillingScoreSettings.selfFormFillingScoreSettings = {
    //         ...settingItem.formFillingScoreSettings.selfFormFillingScoreSettings,
    //         ...settings,
    //       };
    //     });
    //   },
    //   setManagerFormFillingScoreSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // update the target item plus any other view wise setting that copies this setting
    //     const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
    //     if (matchingTargetItems.length === 0) {
    //       return;
    //     }
    //     matchingTargetItems.forEach((settingItem) => {
    //       settingItem.formFillingScoreSettings.managerFormFillingScoreSettings = {
    //         ...settingItem.formFillingScoreSettings.managerFormFillingScoreSettings,
    //         ...settings,
    //       };
    //     });
    //   },
    //   setPeerFormFillingScoreSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // update the target item plus any other view wise setting that copies this setting
    //     const matchingTargetItems = state.formFillingSettings.filter((r) => r.feedbackFormId === feedbackFormId);
    //     if (matchingTargetItems.length === 0) {
    //       return;
    //     }
    //     matchingTargetItems.forEach((settingItem) => {
    //       settingItem.formFillingScoreSettings.peerFormFillingScoreSettings = {
    //         ...settingItem.formFillingScoreSettings.peerFormFillingScoreSettings,
    //         ...settings,
    //       };
    //     });
    //   },

    //   // calibration
    //   setCalibrationScoreSettings: (state, action) => {
    //     let { answerOptions } = action.payload;
    //     answerOptions = answerOptions.map((item, index) => ({
    //       ...item,
    //       displayOrder: index + 1,
    //     }));

    //     state.calibrationScoreSettings = {
    //       ...state.calibrationScoreSettings,
    //       ...action.payload,
    //       answerOptions,
    //     };
    //   },
    setReviewCreationGridDefinition: (state, action) => {
      if (action.payload) {
        const rowsCount = Object.keys(action.payload).length;
        const columnsCount = Object.keys(action.payload['1']).length;
        const isGridDimensionsChanged =
          state.gridMetaData.rowsCount !== rowsCount || state.gridMetaData.columnsCount !== columnsCount;

        state.gridMetaData.rowsCount = rowsCount;
        state.gridMetaData.columnsCount = columnsCount;
        state.gridMetaData.boxCount = rowsCount * columnsCount;

        // don't overwrite in case of dimension change and there is already a grid definition for this dimension
        if (
          isGridDimensionsChanged &&
          !!state.calibrationAndDeliverables.gridDefinition[`${rowsCount}x${columnsCount}`]
        ) {
          return;
        }
        state.calibrationAndDeliverables.gridDefinition[`${rowsCount}x${columnsCount}`] = action.payload;
      }
    },

    //   // timelines
    //   setTimelines: (state, action) => {
    //     state.timelines = action.payload;
    //   },
    //   setTimelinesSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;
    //     const { responseProviderType, ...updateSettings } = settings;
    //     // TODO: v1 or later -> update the target item plus any other view wise setting that copies this setting
    //     // const targetItemIndex = state.timelines.findIndex(
    //     //   (r) => r.feedbackFormId === feedbackFormId || r.settingSameAsFormId === feedbackFormId
    //     // );
    //     const targetItemIndex = state.timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);

    //     const targetReviewProviderObjectIndex = state.timelines[targetItemIndex].reviewTimelineSettingList.findIndex(
    //       (r) => r.responseProviderType === responseProviderType
    //     );

    //     const targetReviewProviderObject =
    //       state.timelines[targetItemIndex].reviewTimelineSettingList[targetReviewProviderObjectIndex];

    //     state.timelines[targetItemIndex].reviewTimelineSettingList[targetReviewProviderObjectIndex] = {
    //       ...targetReviewProviderObject,
    //       ...updateSettings,
    //     };
    //   },
    //   setTimelinesSameSettingAsFeedbackFormId: (state, action) => {
    //     const { feedbackFormId, settingSameAsFormId } = action.payload;
    //     const index = state.timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
    //     if (index === -1) {
    //       return;
    //     }
    //     state.timelines[index].settingSameAsFormId = settingSameAsFormId;
    //   },

    //   // share results
    //   setShareResults: (state, action) => {
    //     state.shareResults = action.payload;
    //   },
    //   setShareResultsSameSettingAsFeedbackFormId: (state, action) => {
    //     const { feedbackFormId, settingSameAsFormId } = action.payload;
    //     const index = state.shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
    //     if (index === -1) {
    //       return;
    //     }
    //     state.shareResults[index].settingSameAsFormId = settingSameAsFormId;
    //   },
    //   setShareResultsSettings: (state, action) => {
    //     const { feedbackFormId, settings } = action.payload;

    //     // update the target item plus any other view wise setting that copies this setting
    //     const matchingTargetItems = state.shareResults.filter((r) => r.feedbackFormId === feedbackFormId);
    //     if (matchingTargetItems.length === 0) {
    //       return;
    //     }
    //     matchingTargetItems.forEach((settingItem) => {
    //       settingItem.shareResultsSettings = {
    //         ...settingItem.shareResultsSettings,
    //         ...settings,
    //       };
    //     });
    //   },

    //   // validations
    //   setValidationAcknowledgements: (state, action) => {
    //     const { key, value } = action.payload;
    //     state.validations[key] = value;
    //   },
  },
});

export const {
  resetReviewCreation,

  // setReviewCycleId,
  // setReviewName,

  // setReviewTypeAndDetails,
  // setReviewMetaData,
  setReviewCreationKey,
  setReviewCreationReviewerSetting,
  setReviewPeriodDetails,
  setReviewCreationTimelines,
  setReviewDateOffset,

  setReviewCreationCalibrationAndDeliverables,
  setReviewCreationCalibrationQuestion,
  setReviewCreationGridDefinition,
  setReviewCreationOutcomeQuestion,

  // setReviewees,
  // setIsRevieweesCustomViewsSettings,
  // setIsRevieweesOverlappingEmployees,
  // setIsAllEmployeesSelected,
  // setTotalEmployeeCount,
  // setSelectedEmployeeCount,

  // setReviewers,
  // setReviewerViewSettings,
  // setReviewerSameSettingAsFeedbackFormId,

  // setElements,
  // setElementsTemplateSettings,
  // setElementsScoreSettings,
  // setElementsSameSettingAsFeedbackFormId,

  // setFormFillings,
  // setFormFillingsSameSettingAsFeedbackFormId,
  // setSelfFormFillingScoreSettings,
  // setManagerFormFillingScoreSettings,
  // setPeerFormFillingScoreSettings,

  // setCalibrationScoreSettings,
  // setGridDefinition,

  // setTimelines,
  // setTimelinesSettings,
  // setTimelinesSameSettingAsFeedbackFormId,

  // setShareResults,
  // setShareResultsSameSettingAsFeedbackFormId,
  // setShareResultsSettings,

  // setValidationAcknowledgements,
} = reviewCreationV1.actions;

export default reviewCreationV1.reducer;

// selectors
export const useReviewCreationV1Data = () => useSelector((state) => state.reviewCreationV1);

export const useReviewCreationDerivedMetaData = () => {
  const { enabledProviderTypes } = useSelector((state) => state.reviewCreationV1);

  return {
    hasPeerForm: enabledProviderTypes.includes(REVIEW_PROVIDER_TYPES.PEER),
    hasDLMForm: enabledProviderTypes.includes(REVIEW_PROVIDER_TYPES.DLM),
  };
};

export const useSelectReviewCreationCalibrationAndDeliverables = () =>
  useSelector((state) => state.reviewCreationV1.calibrationAndDeliverables);

export const useSelectReviewCreationGridMetaData = () => useSelector((state) => state.reviewCreationV1.gridMetaData);

// selector for each step of review creation
// review type and details
// export const useReviewTypeAndDetails = () => useSelector((state) => state.reviewCreationV1.reviewTypeAndDetails);

// cohorts
// export const useReviewees = () => useSelector((state) => state.reviewCreationV1.cohorts);

// export const useIsRevieweesCustomViewsSettings = () =>
//   useSelector((state) => state.reviewCreation.hasCustomSettings);

// export const useIsReviewsOverlappingEmployees = () =>
//   useSelector((state) => state.reviewCreation.isRevieweesOverlappingEmployees);

// export const useIsAllEmployeesSelected = () => useSelector((state) => state.reviewCreation.isAllEmployees);

// export const useTotalEmployeeCount = () => useSelector((state) => state.reviewCreation.totalEmployeeCount);

// export const useSelectedEmployeeCount = () => useSelector((state) => state.reviewCreation.selectedEmployeeCount);

// reviewers
// export const useReviewers = () => useSelector((state) => state.reviewCreationV1.reviewers);
// export const useReviewersSameSetting = ({ feedbackFormId }) => {
//   const reviewers = useReviewers();
//   const index = reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return reviewers[index].settingSameAsFormId;
// };

// export const useReviewersSettings = ({ feedbackFormId }) => {
//   const reviewers = useReviewers();
//   const index = reviewers.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return reviewers[index].reviewerSettings;
// };

// elements

// export const useElements = () => useSelector((state) => state.reviewCreation.elements);

// export const useElementsSameSetting = ({ feedbackFormId }) => {
//   const elements = useElements();
//   const index = elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return elements[index].settingSameAsFormId;
// };

// export const useElementsSettings = ({ feedbackFormId }) => {
//   const elements = useElements();
//   const index = elements.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return elements[index].elementSettings;
// };

// export const useElementsTemplateSettings = ({ feedbackFormId }) => {
//   const elements = useElementsSettings({ feedbackFormId });

//   return elements.providerTypeToTemplateMap;
// };

// export const useElementsScoreSettings = ({ feedbackFormId }) => {
//   const elements = useElementsSettings({ feedbackFormId });

//   return elements?.elementScoreSettings ?? {};
// };

// // form filling experience
// export const useFormFillings = () => useSelector((state) => state.reviewCreation.formFillingSettings);

// export const useFormFillingsByFeedbackFormId = ({ feedbackFormId }) => {
//   const formFillingSettings = useFormFillings();
//   return formFillingSettings.find((r) => r.feedbackFormId === feedbackFormId);
// };

// export const useFormFillingsSameSetting = ({ feedbackFormId }) => {
//   const formFillingSettings = useFormFillings();
//   const index = formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return formFillingSettings[index].settingSameAsFormId;
// };

// export const useFormFillingsSettings = ({ feedbackFormId }) => {
//   const formFillingSettings = useFormFillings();
//   const index = formFillingSettings.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return formFillingSettings[index].formFillingScoreSettings;
// };

// export const useSelfFormFillingScoreSettings = ({ feedbackFormId }) => {
//   const settings = useFormFillingsSettings({ feedbackFormId });

//   return settings?.selfFormFillingScoreSettings ?? {};
// };

// export const useManagerFormFillingScoreSettings = ({ feedbackFormId }) => {
//   const settings = useFormFillingsSettings({ feedbackFormId });

//   return settings?.managerFormFillingScoreSettings ?? {};
// };

// export const usePeerFormFillingScoreSettings = ({ feedbackFormId }) => {
//   const settings = useFormFillingsSettings({ feedbackFormId });

//   return settings?.peerFormFillingScoreSettings ?? {};
// };

// // calibration

// export const useCalibrationScoreSettings = () => useSelector((state) => state.reviewCreation.calibrationScoreSettings);

// export const useGridDefinitionMetaData = () => useSelector((state) => state.reviewCreation.gridMetaData);

// export const useGridDefinition = () => {
//   const { rowsCount, columnsCount } = useGridDefinitionMetaData();

//   return useSelector((state) => state.reviewCreation.gridDefinition[`${rowsCount}x${columnsCount}`]);
// };

// // timelines
// export const useTimelines = () => useSelector((state) => state.reviewCreation.timelines);

// export const useTimelinesSameSetting = ({ feedbackFormId }) => {
//   const timelines = useTimelines();
//   const index = timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   return timelines[index].settingSameAsFormId;
// };

// export const useReviewTimelineSettingList = ({ feedbackFormId }) => {
//   const timelines = useTimelines();
//   const index = timelines.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }
//   const { reviewTimelineSettingList } = timelines[index];

//   return reviewTimelineSettingList;
// };

// export const useTimelinesProviderTypeSettings = ({ feedbackFormId, responseProviderType }) => {
//   const sourceTimelineSettings = useReviewTimelineSettingList({ feedbackFormId });
//   const matchingTimelineSettings = sourceTimelineSettings.find((r) => r.responseProviderType === responseProviderType);
//   return matchingTimelineSettings;
// };

// // share results
// export const useShareResults = () => useSelector((state) => state.reviewCreation.shareResults);

// export const useShareResultsSameSetting = ({ feedbackFormId }) => {
//   const shareResults = useShareResults();
//   const index = shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }

//   return shareResults[index].settingSameAsFormId;
// };

// export const useShareResultsSettings = ({ feedbackFormId }) => {
//   const shareResults = useShareResults();
//   const index = shareResults.findIndex((r) => r.feedbackFormId === feedbackFormId);
//   if (index === -1) {
//     return null;
//   }

//   return shareResults[index].shareResultsSettings;
// };

// // validations
// export const useValidationAcknowledgements = () => useSelector((state) => state.reviewCreation.validations);
