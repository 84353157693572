import React from 'react';

import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';

import { TextBodyMedium } from 'uiToolkitV1/Typography';

export const Checkbox = ({
  children: label = null,
  onChange = () => {},
  isChecked = false,
  colorScheme = '#104F95',
  borderColor = '#333333',
  borderHoverColor = '#125AA9',
  labelComp = null,
  ...restProps
}) => (
  <ChakraCheckbox
    isChecked={isChecked}
    onChange={onChange}
    {...restProps}
    sx={{
      'span.chakra-checkbox__control': {
        borderColor,
      },
      'span.chakra-checkbox__control[data-checked]': {
        background: `${colorScheme}`,
        borderColor: borderHoverColor,
      },
      'span.chakra-checkbox__control[data-checked]:hover': {
        // background: `${colorScheme}`,
        borderColor: borderHoverColor,
      },
      'span.chakra-checkbox__control[data-disabled]': {
        opacity: 0.5,
        color: 'white',
      },
    }}
  >
    {labelComp}
    {!labelComp && <TextBodyMedium ml="2px">{label}</TextBodyMedium>}
  </ChakraCheckbox>
);
