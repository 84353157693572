/* eslint-disable no-shadow */

/* eslint-disable arrow-body-style */
import { Box, Flex } from '@chakra-ui/react';

import CategoryWrapper from './CategoryWrapper';
import MultiSelectOptions from './MultiSelectOptions';

const ContentWrapper = ({
  categoryListMetaData,
  handleCategorySelect,
  selectedCategory,
  handleCheckbox,
  selectedCategoryOptionList,
}) => {
  return (
    <Flex flexDirection="row" bg="white" flex={1} padding="8px">
      <Box minW="170px">
        <CategoryWrapper
          categoryList={categoryListMetaData}
          handleCategorySelect={handleCategorySelect}
          selectedCategory={selectedCategory}
        />
      </Box>
      <Box width="1px" bg="neutral.EBEFF3" mx="8px" />
      <Box flexGrow={4}>
        <MultiSelectOptions
          options={selectedCategoryOptionList}
          handleCheckbox={handleCheckbox}
          selectedCategory={selectedCategory}
        />
      </Box>
    </Flex>
  );
};

export default ContentWrapper;
