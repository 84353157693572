import { Box, Flex, Grid } from '@chakra-ui/react';

import { TextBodyMedium } from 'uiToolkitV1/Typography';

export const LabelFieldWrapper = ({ label, labelComp = null, children, ...props }) => (
  <Grid gridTemplateColumns="2fr 3fr 3fr" gridGap={6} {...props}>
    <Box>
      <Flex py={3}>
        <TextBodyMedium color="text.g2">
          {label || labelComp}
          {label ? ':' : ''}
        </TextBodyMedium>
      </Flex>
    </Box>
    {children}
  </Grid>
);
