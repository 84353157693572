import { SHOW_SNACKBAR, HIDE_SNACKBAR } from './actionTypes';

// vertical and horizontal are related to the position of the snackbar
export function showSnackbarAction({ severity = 'success', message = '', vertical = 'bottom', horizontal = 'center' }) {
  const data = {
    severity,
    message,
    vertical,
    horizontal,
  };

  return {
    type: SHOW_SNACKBAR,
    data,
  };
}

export function hideSnackbarAction() {
  return {
    type: HIDE_SNACKBAR,
  };
}
