import { serverUrl } from 'constants/endpoints';

export const deerEndpoints = {
  employeeAccesses: (employeeId: string | number) => `${serverUrl}/service/custom/auth/employee/${employeeId}/accesses`,
  validFeaturesForViewId: (viewId: string) => `${serverUrl}/service/custom/auth/cohort/${viewId}/validFeatures`,
  visierSyncStatus: `${serverUrl}/service/custom/auth/visier/tenant/syncInfo`,
  analytics: {
    metrics: `${serverUrl}/analytics/metrics`,
    explanations: `${serverUrl}/analytics/metrics/explanations`,
    reviewsExplanations: `${serverUrl}/analytics/metrics/explanations/review`,
    reviewsExplanationsSankey: `${serverUrl}/analytics/metrics/explanations/review/sankey`,
    authToken: `${serverUrl}/service/custom/auth/visier`,
  },
  featureManagement: `${serverUrl}/service/custom/auth/organization/features`,
};
