/* eslint-disable react/no-unescaped-entities */
import { useEffect } from 'react';

import { Box } from '@chakra-ui/react';
import { useParseUploadEmployeeListCsv } from 'components/Views/Hooks/dataHooks';

import { TextBodyMedium } from 'uiToolkitV1/Typography';

const UploadedFileEmailValidation = ({ uploadedFile, disableUploadButton }) => {
  const { isLoading, data, isSuccess, isError } = useParseUploadEmployeeListCsv({
    fileObject: uploadedFile,
  });

  useEffect(() => {
    const { identifiedEmailsResponse } = data || {};
    if ((Array.isArray(identifiedEmailsResponse) && identifiedEmailsResponse.length === 0) || isError) {
      disableUploadButton(true);
    } else {
      disableUploadButton(false);
    }
  }, [data, disableUploadButton, isError]);

  const { identifiedEmailsResponse, nonIdentifiedEmails } = data || {};
  return (
    <Box>
      {isLoading && <TextBodyMedium>Uploading employees...</TextBodyMedium>}
      {data && isSuccess && Array.isArray(nonIdentifiedEmails) && nonIdentifiedEmails.length > 0 && (
        <TextBodyMedium color="text.g3" mt={4}>
          We couldn't find accounts for {nonIdentifiedEmails.length} out of{' '}
          {nonIdentifiedEmails.length + identifiedEmailsResponse.length} email id
          {nonIdentifiedEmails.length > 1 ? 's' : ''}!
        </TextBodyMedium>
      )}
      {isError && <TextBodyMedium>Upload failed</TextBodyMedium>}
    </Box>
  );
};

export default UploadedFileEmailValidation;
