import React from 'react';

import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputRightElement,
  forwardRef,
  InputLeftElement,
} from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Cross } from 'assets/assetsV1/iconComponents';

interface Props {
  /* 
    isReadOnly is there to show text with borders and without interaction. 
    relying on isDisabled prop to disable interaction.
    marking input as disabled as well
  */
  isReadOnly?: boolean;
  isDashed?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
  rightComponent?: React.ReactNode;
  leftComponent?: React.ReactNode;
  isError?: boolean;
}

type InputProps = Props & ChakraInputProps;

const StyledInput = styled(ChakraInput)`
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  border-bottom: 1px solid ${(props) => (props.isError ? '#c62c02' : '#999999')};
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-radius: 4px 4px 0px 0px;
  background: transparent;
  padding: ${({ padding }) => padding || '10px 12px'};
  padding-left: 0px;
  ${({ isDashed }) => isDashed && 'border-bottom-style: dashed;'}
  &:hover {
    border-bottom: 1px solid ${(props) => (props.isError ? '#c62c02' : '#333333')};
    ${(props) => props.$isReadOnly && 'border-bottom: none;'};
  }
  &:focus {
    /* background: white; */
    border-bottom: 1px solid ${(props) => (props.isError ? '#c62c02' : '#1565bc')};
    /* box-shadow: 0px 2px 4px rgba(0, 142, 240, 0.1); */
    box-shadow: none;
    ${(props) => props.$isReadOnly && 'border-bottom: none;'};
  }
  &[aria-invalid='true'] {
    border-bottom: 1px solid #c62c02;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    box-shadow: none;
    background: transparent;
    &:focus {
      /* background: white;
      box-shadow: 0px 2px 4px rgba(207, 102, 121, 0.1); */
      box-shadow: none;
    }
  }
  font-size: 14px;
  /* isReadOnly takes precedence over disabled. */
  ${(props) =>
    props.$isReadOnly &&
    `
    opacity: 1 !important; 
    cursor: auto !important;
    border-bottom: none;
    `};
`;

const InputV2 = forwardRef<InputProps, 'input'>(
  (
    {
      isClearable,
      rightComponent = null,
      onClear = () => {},
      isReadOnly = false,
      isError = false,
      isDisabled,
      leftComponent,
      ...props
    },
    ref
  ) => (
    <InputGroup>
      {leftComponent && <InputLeftElement w="22px">{leftComponent}</InputLeftElement>}
      <StyledInput
        ref={ref}
        _placeholder={{ color: '#666666' }}
        $isReadOnly={isReadOnly}
        isDisabled={isReadOnly || isDisabled}
        isError={isError}
        paddingLeft={leftComponent ? '28px !important' : '0px'}
        {...props}
      />
      ;{isClearable && <InputRightElement cursor="pointer" onClick={onClear} children={<Cross color="#333333" />} />}
      {rightComponent && !isClearable && <InputRightElement>{rightComponent}</InputRightElement>}
    </InputGroup>
  )
);

export { InputV2 };
