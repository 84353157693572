import { Box, createStandaloneToast, Flex } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';
import { themeColors } from 'themes/clients/baseTheme';
import { colors } from 'themes/tokens';

import { CheckOutlined, Cross, NeedAttention } from 'assets/assetsV1/iconComponents';

import { isMobileView } from 'constants/isMobileView';

import { TextBodyMedium } from 'uiToolkitV1/Typography';

const STATUS_COLORS = {
  success: {
    textIconColor: themeColors.functional['44812F'],
    bgColor: themeColors.feedback.DBF2D3,
    buttonColor: colors['#44812F'],
  },
  error: {
    textIconColor: themeColors.functional.C62C02,
    bgColor: themeColors.feedback.FFE1D9,
    buttonColor: colors['#C62C02'],
  },
  info: {
    textIconColor: themeColors.functional['333333'],
    bgColor: '#FFF',
    buttonColor: colors['#333333'],
  },
  warning: {
    textIconColor: themeColors.functional['333333'],
    bgColor: '#FFF',
    buttonColor: colors['#333333'],
  },
};

const STATUS_ICONS = {
  success: CheckOutlined,
  error: NeedAttention,
  info: CheckOutlined,
  warning: NeedAttention,
};

export const useToast = () => {
  const theme = useTheme();
  // using chakra toast somehow weirdly resets the extended chakra theme, which are overridden in baseTheme.
  // that's why overriding the fonts here again.
  const toast = createStandaloneToast({
    theme: {
      ...theme,
      fonts: theme.fonts,
    },
  });
  return ({
    title = '',
    description = '',
    status = 'success',
    duration = 5000,
    isClosable = true,
    rightComp = null,
    hideLeftIcon = false,
    customStartIcon = null,
    ...rest
  }) => {
    const { textIconColor, bgColor, buttonColor } = STATUS_COLORS[status];
    const Icon = customStartIcon ?? STATUS_ICONS[status];
    toast({
      position: 'bottom-left',
      render: ({ onClose }) => (
        <Flex
          p={3}
          bg={bgColor}
          display="flex"
          justifyContent="space-between"
          borderRadius="4px"
          boxShadow={theme.shadows.large}
          minH="60px"
          alignItems="center"
          maxW={isMobileView() ? '80%' : 'none'}
        >
          <Flex flexGrow={1} alignItems="center">
            {!hideLeftIcon && <Icon boxSize="36px" color={textIconColor} />}
            <TextBodyMedium ml={2} color={textIconColor}>
              {title}
            </TextBodyMedium>
          </Flex>
          <Flex>
            {rightComp}
            {isClosable && (
              <Box display="flex" alignItems="center" onClick={onClose} cursor="pointer" p={2} h="40px">
                <Cross color={buttonColor} m={1} boxSize="20px" />
              </Box>
            )}
          </Flex>
        </Flex>
      ),
      duration,
      isClosable,
      status,
      description,
      ...rest,
    });
  };
};
