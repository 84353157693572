import {
  userEmailStateApi,
  userEmailRegisterApi,
  validateEmailApi,
  updateEmployeeApi,
  signupUserApi,
  signinUserApi,
  profilePhotoUploadApi,
  getEmployeeApi,
  resetPasswordApi,
  userLoginEventApi,
  forgotPasswordApi,
  forgotPasswordConfirmationApi,
  getLoginOptionsApi,
  getAuth0LoginUrlApi,
  getAuth0LogoutUrlApi,
  resetAuth0PasswordApi,
  auth0AuthenticateApi,
  msTeamsConnectUserApi,
  msTeamsDisconnectUserApi,
} from 'api/auth';
import { SignUpPayload, UserState } from 'models/auth';
import Employee from 'models/employee';

import { updateEmployeeFields, LOGIN_TYPES } from 'constants/ProjectConstants';
import { pickFromObject } from 'constants/helper';

export const userEmailState = (email) => userEmailStateApi(email).then((res) => UserState.getObject(res.entity));

export const userEmailRegister = (email) => userEmailRegisterApi(email).then((res) => UserState.getObject(res.entity));

export const validateEmail = ({ email, temporaryPassword, referralCode, timezoneOffset }) =>
  validateEmailApi({ email, temporaryPassword, referralCode, timezoneOffset }).then(
    (res) => new Employee(null, res.entity.employee)
  );

export const getEmployee = (employeeId) => getEmployeeApi(employeeId).then((res) => new Employee(null, res.entity));

export const updateEmployee = (employee, uuid) =>
  updateEmployeeApi(pickFromObject(employee, updateEmployeeFields), uuid).then((res) => new Employee(null, res.entity));

export const signupUser = (employeeObj, password, newPassword) =>
  signupUserApi(
    new SignUpPayload(
      employeeObj.email,
      password,
      newPassword,
      pickFromObject(employeeObj, updateEmployeeFields),
      employeeObj.id
    )
  ).then((res) => new Employee(null, res.entity.employee));

export const signinUser = ({ email, password }) =>
  signinUserApi({ email, password }).then((res) => {
    const employee = new Employee(null, {
      ...res.entity.employee,
      authToken: res.entity.authToken,
    });
    return { employee, authToken: res.entity.authToken, authenticated: true };
  });

export const profilePhotoUpload = ({ attachment }, employeeId) => {
  const mediaType = 'IMAGE';
  return profilePhotoUploadApi({ attachment, mediaType }, employeeId).then((res) => new Employee(null, res.entity));
};

export const resetPassword = ({ email, password, newPassword }) =>
  resetPasswordApi({ email, password, newPassword }).then((res) => res);

export const resetAuth0Password = () => resetAuth0PasswordApi().then((res) => res);

export const userLoginEvent = ({ employeeId, userLoginEvent, userAction }) =>
  userLoginEventApi({ employeeId, userLoginEvent, userAction }).then((res) => res);

export const forgotPassword = ({ email }) => forgotPasswordApi({ email }).then((res) => res);

export const forgotPasswordConfirmation = ({ email, verificationCode, password }) =>
  forgotPasswordConfirmationApi({ email, verificationCode, password }).then((res) => res);

export const getLoginOptions = () =>
  getLoginOptionsApi().then((res) => {
    const data = res.entity;
    const result = {};
    data.forEach((loginTypeRes) => {
      switch (loginTypeRes.loginType) {
        case LOGIN_TYPES.mesh:
          result.mesh = loginTypeRes;
          break;
        case LOGIN_TYPES.saml:
          result.saml = loginTypeRes;
          break;
        case LOGIN_TYPES.google:
          result.google = loginTypeRes;
          break;
        default:
          break;
      }
    });
    return result;
  });

export const getAuth0LoginUrl = ({ email, invitationId, organization = null }) =>
  getAuth0LoginUrlApi({ email, invitationId, organization }).then((res) => res.entity);

export const getAuth0LogoutUrl = (orgName) => getAuth0LogoutUrlApi(orgName).then((res) => res.entity);

export const auth0Authenticate = (code) => auth0AuthenticateApi(code).then((res) => res.entity);

export const msTeamsConnectUser = (userObjectId) => msTeamsConnectUserApi(userObjectId).then((res) => res.entity);

export const msTeamsDisconnectUser = () => msTeamsDisconnectUserApi().then((res) => res.entity);
