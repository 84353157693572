/**
 *  the idea here is, if flag is Active from UI only then the backend flag will be entertained.
 *  otherwise backend flag won't control anything.
 *
 *  Also, we can keep some other key to mark if the flag is retired. While retiring a flag, it
 *  is the responsibility of developer to remove all un-reachable code and files from the project.
 *
 *  A feature flag utility is present which works on following logic ( true means new functionality hidden under flag should be visible)
 *  - if flag is not present in following object, return false always.
 *  - else if flag is Retired, it will always return true ( this is for cases where impossible deadlines are forced on devs and there is no time for actually retiring the flag).
 *  - else if it is active return true
 *  - else return false.
 *
 *  PS: Devs please ask for extra time when marking a flag as retired.
 * */
export const FEATURE_FLAGS = {
  LEVEL_UP: {
    key: 'LEVEL_UP',
    isActive: false,
    isRetired: true,
  },
  LEVEL_UP_PAGE: {
    key: 'LEVEL_UP_PAGE',
    isActive: false,
    isRetired: true,
  },
  MESH_RECOMMENDATION: {
    key: 'MESH_RECOMMENDATION',
    isActive: true,
    isRetired: false,
  },
  INITIATIVE_V2: {
    key: 'INITIATIVE_V2',
    isActive: true,
    isRetired: false,
  },
  RABBIT_GOALS_TASKS: {
    key: 'RABBIT_GOALS_TASKS',
    isActive: true,
    isRetired: false,
  },
  OKR_VIEW: {
    key: 'OKR_VIEW',
    isActive: true,
    isRetired: false,
  },
  OKR_CUSTOM_NAME_ENABLED: {
    key: 'OKR_CUSTOM_NAME_ENABLED',
    isActive: true,
    isRetired: false,
  },
  ONE_ON_ONE_V2: {
    key: 'ONE_ON_ONE_V2',
    isActive: true,
    isRetired: false,
  },

  ENABLE_PERSONA_INSIGHTS: {
    key: 'ENABLE_PERSONA_INSIGHTS',
    isActive: true,
    isRetired: false,
  },
  ENABLE_AI_REVIEW_COMMENTS: {
    key: 'ENABLE_AI_REVIEW_COMMENTS',
    isActive: true,
    isRetired: false,
  },
  ENABLE_AI_REVIEW_MANAGER_SUMMARY: {
    key: 'ENABLE_AI_REVIEW_MANAGER_SUMMARY',
    isActive: true,
    isRetired: false,
  },
  ENABLE_VIEWS: {
    key: 'ENABLE_VIEWS',
    isActive: true,
    isRetired: false,
  },
  ONE_ON_ONE_V2_EVENT_AGENDA: {
    key: 'ONE_ON_ONE_V2_EVENT_AGENDA',
    isActive: true,
    isRetired: false,
  },
  ACCESS_CONTROL: {
    key: 'ACCESS_CONTROL',
    // isActive: false,
    isActive: true,
    isRetired: false,
  },
  GOAL_INSIGHT_GRAPH: {
    key: 'GOAL_INSIGHT_GRAPH',
    isActive: true,
    isRetired: false,
  },
  GOAL_GEN_AI: {
    key: 'GOAL_GEN_AI',
    isActive: true,
    isRetired: false,
  },
  MERGE_INTEGRATION: {
    key: 'MERGE_INTEGRATION',
    isActive: true,
    isRetired: false,
  },
  ONE_ON_ONE_TRANSCRIPTION: {
    key: 'ONE_ON_ONE_TRANSCRIPTION',
    isActive: true,
    isRetired: false,
  },
  ENABLE_ACCESS_CONTROL: {
    key: 'ENABLE_ACCESS_CONTROL',
    isActive: true,
    isRetired: false,
  },
  ENABLE_GOAL_CONFIG_TEMPLATES: {
    key: 'ENABLE_GOAL_CONFIG_TEMPLATES',
    isActive: true,
    isRetired: false,
  },
  ENABLE_PARTIAL_PUBLISH: {
    key: 'ENABLE_PARTIAL_PUBLISH',
    isActive: true,
    isRetired: false,
  },
  ENABLE_REVIEW_ANALYTICS: {
    key: 'ENABLE_REVIEW_ANALYTICS',
    isActive: true,
    isRetired: false,
  },
  ONE_ON_ONE_V2_ML: {
    key: 'ONE_ON_ONE_V2_ML',
    isActive: true,
    isRetired: false,
  },
  VISIER_ANALYTICS: {
    key: 'VISIER_ANALYTICS',
    isActive: true,
    isRetired: false,
  },
  REVIEW_SANKEY_GRAPH: {
    key: 'REVIEW_SANKEY_GRAPH',
    isActive: true,
    isRetired: false,
  },
  CF_GEN_AI: {
    key: 'CF_GEN_AI',
    isActive: true,
    isRetired: false,
  },
  NEW_NAV: {
    key: 'NEW_NAV',
    isActive: true,
    isRetired: false,
  },
  MESH_RECOMMENDS_V1: {
    key: 'MESH_RECOMMENDS_V1',
    isActive: true,
    isRetired: false,
  },
  ENABLE_BULK_NOTIFY: {
    key: 'ENABLE_BULK_NOTIFY',
    isActive: true,
    isRetired: false,
  },
  CF_SCORING_SIDE_PANEL: {
    key: 'CF_SCORING_SIDE_PANEL',
    isActive: true,
    isRetired: false,
  },
  DENY_PUBLIC_CF_VISIBILITY: {
    key: 'DENY_PUBLIC_CF_VISIBILITY',
    isActive: true,
    isRetired: false,
  },
  DEER_MODULE_SETTINGS: {
    key: 'DEER_MODULE_SETTINGS',
    isActive: true,
    isRetired: false,
  },
  RELEASE_NEW_NAV_STATIC_DATA_FEATURES: {
    key: 'release-new-nav-static-data-features',
    isActive: true,
    isRetired: false,
  },

  DEER_COLLAPSIBLE_CARDS: {
    key: 'release-show-collapsible-card',
    isActive: true,
    isRetired: false,
  },
  RELEASE_SANDBOX_STATIC_DATA_FEATURES: {
    key: 'release-sandbox-new-nav-static-data-features',
    isActive: true,
    isRetired: false,
  },
  // we will retire this flag after the release
  CONFIGURE_HOMEPAGE_NUDGES: {
    key: 'configure-homepage-nudges',
    isActive: true,
    isRetired: false,
  },
  CONFIGURE_HOMEPAGE_NUDGES_CAMEL_CASE: {
    key: 'CONFIGURE_HOMEPAGE_NUDGES',
    isActive: true,
    isRetired: false,
  },
  NEW_NAV_PREVIEW_FEATURE: {
    key: 'NEW_NAV_PREVIEW_FEATURE',
    isActive: true,
    isRetired: false,
  },
  RELEASE_DEER_SWITCHER: {
    key: 'release-deer-switcher',
    isActive: true,
    isRetired: false,
  },
  RELEASE_INTENT_LANDING_PAGES: {
    key: 'release-intent-landing-pages',
    isActive: true,
    isRetired: false,
  },
  RELEASE_VISIER_REPORTS: {
    key: 'release-visier-reports',
    isActive: true,
    isRetired: false,
  },
  RELEASE_BULK_UPLOAD_INTENT: {
    key: 'release-bulk-upload-intent',
    isActive: true,
    isRetired: false,
  },
  RELEASE_REVIEWS_OVERVIEW_BOX_GRID: {
    key: 'release-reviews-overview-box-grid',
    isActive: true,
    isRetired: false,
  },
  RELEASE_CROSS_MODULAR_INSIGHTS: {
    key: 'release-cross-modular-insights',
    isActive: true,
    isRetired: false,
  },
  RELEASE_BULK_CHECK_IN: {
    key: 'RELEASE_BULK_CHECK_IN',
    isActive: true,
    isRetired: false,
  },
  OVERVIEW_EXPLANATIONS: {
    key: 'OVERVIEW_EXPLANATIONS',
    isActive: true,
    isRetired: false,
  },
  OVERVIEW_REVIEWS_EXPLANATIONS: {
    key: 'release-overview-reviews-explanation',
    isActive: true,
    isRetired: false,
  },
  LIFECYCLE_INSIGHTS: {
    key: 'release-lifecycle-insights',
    isActive: true,
    isRetired: false,
  },
  RELEASE_OUTCOME: {
    key: 'RELEASE_OUTCOME',
    isActive: true,
    isRetired: false,
  },
  REVIEW_ACTION_PLAN: {
    key: 'release-review-plan-of-action',
    isActive: true,
    isRetired: false,
  },
  EMPLOYEE_PERSONA_REVIEW_REPORT: {
    key: 'release-review-report-employee-persona',
    isActive: true,
    isRetired: false,
  },
  DEMO_AI_CALIBRATION: {
    key: 'demo-ai-calibration',
    isActive: true,
    isRetired: false,
  },
  RELEASE_SETTINGS_PAGE_REVAMP: {
    key: 'release-settings-page-revamp',
    isActive: true,
    isRetired: false,
  },
  DEMO_PREDICTIVE_SCORE_OFF_CYCLE: {
    key: 'demo-predictive-score-offcycle',
    isActive: true,
    isRetired: false,
  },
  RELEASE_FEEDBACK_INCLUSION: {
    key: 'release-feedback-inclusion',
    isActive: true,
    isRetired: false,
  },
  DEMO_REVIEW_CREATION_TRIGGERED_REVIEWS: {
    key: 'demo-review-creation-triggered-reviews',
    isActive: true,
    isRetired: false,
  },
  ENABLE_COMPANY_SETTINGS_INTENT: {
    key: 'ENABLE_COMPANY_SETTINGS_INTENT',
    isActive: true,
    isRetired: false,
  },
  ENABLE_STATIC_DATA_CALIBRATION_METRIC_CARD: {
    key: 'ENABLE_STATIC_DATA_CALIBRATION_METRIC_CARD',
    isActive: true,
    isRetired: false,
  },
  RELEASE_REVIEW_MOBILE_FEEDBACK_TYPE: {
    key: 'release-review-mobile-feedback-type',
    isActive: true,
    isRetired: false,
  },
  ENABLE_CALIBRATION_STATUS_COLUMN: {
    key: 'enable-calibration-status-column',
    isActive: true,
    isRetired: false,
  },
  ENABLE_SETTINGS_REVAMP_EMPLOYEE_ROSTER: {
    key: 'enable-settings-revamp-employee-roster',
    isActive: true,
    isRetired: false,
  },
  CUSTOM_RENAME_LOCATION_TO_SUBSIDIARY: {
    key: 'custom-rename-location-to-subsidiary',
    isActive: true,
    isRetired: false,
  },
  ENABLE_REVIEW_VIEW_BASED_FILTER: {
    key: 'enable-review-view-based-filter',
    isActive: true,
    isRetired: false,
  },
  ENABLE_DOCUSIGN_REVIEW_ACKNOWLEDGE: {
    key: 'enable-docusign-review-acknowledge',
    isActive: true,
    isRetired: false,
  },
  HIDE_DEMO_ONE_ON_ONE_SECTIONS: {
    key: 'hide-demo-one-on-one-sections',
    isActive: true,
    isRetired: false,
  },
  ENABLE_REVIEW_INTENT_FOR_CUSTOM_ADMIN: {
    key: 'enable-review-intent-for-custom-admin',
    isActive: true,
    isRetired: false,
  },
  DISABLE_INITIATIVES_MOBILE_SITE: {
    key: 'disable-initiatives-for-m-site',
    isActive: true,
    isRetired: false,
  },
  ENABLE_COMPANY_SUPPORT: {
    key: 'enable-company-support',
    isActive: true,
    isRetired: false,
  },
  ENABLE_CALIBRATION_PRIVATE_COMMENTS: {
    key: 'enable-calibration-private-comments',
    isActive: true,
    isRetired: false,
  },
};
