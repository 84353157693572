// Libraries
import React from 'react';

// import { useTheme } from '@emotion/react';
import { Flex, InputGroup, useTheme } from '@chakra-ui/react';
import { ClickAwayListener } from '@material-ui/core';
import { components } from 'react-select';

// Icons
import { ArrowDown, Check } from 'assets/assetsV1/iconComponents';

// Typography
import { TextBodyMedium, TextLabel } from 'uiToolkitV1/Typography';

// Styles
import { StyledFormControl, StyledFormHelperText, StyledFormLabel, StyledReactSelect } from './Select.styles';

const reactSelectComponents = {
  MultiValueContainer: ({ selectProps, data }) => {
    const values = selectProps.value;
    if (values) {
      return values[values.length - 1].id === data.id ? data.label : `${data.label}, `;
    }
    return '';
  },
  DropdownIndicator: () => <ArrowDown boxSize="18px" />,
  IndicatorSeparator: () => null,
  Option: ({ ...props }) => {
    const { children, isSelected } = props ?? {};
    return (
      <components.Option {...props}>
        <Flex alignItems="center">
          <TextBodyMedium color={isSelected ? 'button.blue.focus' : 'text.g1'}>{children}</TextBodyMedium>
          {isSelected && <Check ml="auto" color="button.blue.focus" />}
        </Flex>
      </components.Option>
    );
  },
};

export const SELECT_VARIANTS = {
  default: 'default',
  outlined: 'outlined',
};

const Select = ({
  type = 'text',
  name,
  label,
  placeholder,
  helperText,
  withBackground = false,
  withBorderBottom = false,
  value,
  options,
  handleChange,
  customClassName = '',
  isMulti = false,
  isDisabled,
  error,
  className = `randomId${Math.floor(Math.random() * (100 - 0 + 1) + 0)}`,
  getOptionValue = (option) => option.value,
  getOptionLabel = (option) => option.label,
  formControlProps = {},
  selectProps = {},
  variant = SELECT_VARIANTS.default,
  isSearchable = true,
  minWidth = '250px',
  isClearable = false,
  menuPortalTarget = null,
  menuPortalStyles = {},
  ...props
}) => {
  const theme = useTheme();
  const ref = React.useRef();
  const [isMultiSelectDropdownOpen, setIsMultiSelectDropdownOpen] = React.useState(false);
  const handleClickOutside = (event) => {
    setIsMultiSelectDropdownOpen(false);
  };

  let selectVariant = null;
  switch (variant) {
    case SELECT_VARIANTS.default:
    case SELECT_VARIANTS.outlined:
      selectVariant = variant;
      break;
    default:
      console.error(`Error: Invalid variant  : ${variant} . Pick one from [${Object.keys(SELECT_VARIANTS)}]`);
      selectVariant = SELECT_VARIANTS.default;
  }

  const otherProps = {};
  if (isMulti) {
    otherProps.menuIsOpen = isMultiSelectDropdownOpen;
  }

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <StyledFormControl
        isDisabled={isDisabled}
        className={className}
        isInvalid={!!error}
        withBackground={withBackground}
        withBorderBottom={withBorderBottom}
        variant={selectVariant}
        {...formControlProps}
        onClick={(e) => {
          if (!isMulti) return e;
          // if clicked on options, return
          const select = document.querySelector(`.${className}`);
          const selectOptionsList = select.querySelector('.select__menu');
          if (selectOptionsList && selectOptionsList.contains(e.target)) {
            return;
          }

          setIsMultiSelectDropdownOpen((val) => !val);
        }}
        ref={ref}
      >
        {label && (
          <StyledFormLabel htmlFor={name}>
            <TextBodyMedium color={error ? theme.colors.functional.C62C02 : theme.colors.text.g1}>
              {error || label}
            </TextBodyMedium>
          </StyledFormLabel>
        )}

        <InputGroup style={{ caretColor: 'transparent' }}>
          <StyledReactSelect
            options={options}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            className={`select ${customClassName}`}
            classNamePrefix="select"
            isDisabled={isDisabled}
            isMulti={isMulti}
            isClearable={isClearable}
            hideSelectedOptions={false}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            isSearchable={isSearchable}
            minWidth={minWidth}
            components={{ ...reactSelectComponents, ...(props.components ?? {}) }}
            menuPlacement="auto"
            menuPortalTarget={menuPortalTarget}
            styles={{ menuPortal: (base) => ({ ...base, ...menuPortalStyles }) }}
            {...otherProps}
            {...selectProps}
          />
        </InputGroup>

        <StyledFormHelperText>
          <TextLabel>{helperText}</TextLabel>
        </StyledFormHelperText>
      </StyledFormControl>
    </ClickAwayListener>
  );
};

export { Select };
