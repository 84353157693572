export const registerUser = (user_id) => {
  try {
    if (window.Moengage) {
      window.Moengage.add_unique_user_id(user_id);
      window.Moengage.add_email(user_id);
    }
  } catch (err) {
    console.log('Some error occured with push notifications' || err);
  }
};

export const destroySession = (user_id) => {
  try {
    if (window.Moengage) {
      window.Moengage.destroy_session().then((res) => {
        console.log('Moengage Session destroyed');
      });
    }
  } catch (err) {
    console.log('Some error occured with push notifications' || err);
  }
};
