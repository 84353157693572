import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { addWeeks } from 'date-fns';
import { useSelector, shallowEqual } from 'react-redux';

import { reminderOptions } from './components/SurveyReminderSection';
import type { EmployeePrivacy, EngagementFrequencyState, OneTimeState, Reminders } from './types';

const DEFAULT_ONE_TIME_STATE: OneTimeState = {
  type: 'ONE_TIME',
  date: addWeeks(new Date(), 1),
  openFor: 7,
};

// const DEFAULT_RECURRING_STATE: RecurringState = {
//   type: 'RECURRING',
//   date: null,
//   frequency: 'weekly',
//   openFor: 1,
// };

// const DEFAULT_ONBOARDING_STATE: OnboardingState = {
//   daysAfterJoining: 7,
//   openFor: 1,
// };

type InitialState = {
  surveyName: string;
  frequency: EngagementFrequencyState;
  employeePrivacy: EmployeePrivacy;
  reminders: Reminders;
};

const initialState: InitialState = {
  surveyName: '',
  frequency: DEFAULT_ONE_TIME_STATE,
  employeePrivacy: 'SEMI_ANONYMOUS',
  reminders: [reminderOptions[0], reminderOptions[6]], // 1 day before and 1 week before
};

export const engagementSurveyCreationSlice = createSlice({
  name: 'engagementSurveyCreation',
  initialState,
  reducers: {
    initializeEngagementSurveyCreation: (_, action: PayloadAction<Partial<InitialState>>) => ({
      ...initialState,
      ...action.payload,
    }),
    updateEngagementSurveyCreation: (state, action: PayloadAction<Partial<InitialState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { initializeEngagementSurveyCreation, updateEngagementSurveyCreation } =
  engagementSurveyCreationSlice.actions;
export default engagementSurveyCreationSlice.reducer;

// to get the full state
export const useSelectEngagementSurveyCreation = () =>
  useSelector((state: { engagementSurveyCreation: InitialState }) => state.engagementSurveyCreation);

// for survey name section
export const useSelectEngagementSurveyNameAndFrequency = (): {
  surveyName: string;
  frequency: EngagementFrequencyState;
} =>
  useSelector(
    (state: { engagementSurveyCreation: InitialState }) => ({
      surveyName: state.engagementSurveyCreation.surveyName,
      frequency: state.engagementSurveyCreation.frequency,
    }),
    shallowEqual
  );

// for survey type section
export const useSelectEngagementEmployeePrivacy = (): { employeePrivacy: EmployeePrivacy } =>
  useSelector(
    (state: { engagementSurveyCreation: InitialState }) => ({
      employeePrivacy: state.engagementSurveyCreation.employeePrivacy,
    }),
    shallowEqual
  );

// for survey reminders section
export const useSelectEngagementReminders = (): { reminders: Reminders } =>
  useSelector(
    (state: { engagementSurveyCreation: InitialState }) => ({
      reminders: state.engagementSurveyCreation.reminders,
    }),
    shallowEqual
  );
