import { useEffect, useState } from 'react';

import { useQuery } from 'components/hooks/useQuery';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { selectEnabledFeatureFlags } from 'selectors/enabledFeatureFlags';

import { MODULE_KEYS1 } from 'constants/ProjectConstants';
import { matchModuleFromRoute } from 'constants/helper';
import { routes } from 'constants/routes';

export const isTeamPageRoute = ({ pathname, path }) => {
  const match = matchPath(pathname, {
    path,
    exact: true,
  });
  return !!match;
};

export const useSidebarNavMatchRoute = () => {
  const [module, setModule] = useState(undefined);
  const enabledFeatures = useSelector(selectEnabledFeatureFlags);

  const params = useParams();
  const queryParams = useQuery();
  const location = useLocation();

  useEffect(() => {
    let matchedModule = matchModuleFromRoute(location.pathname);
    // special condition because goal and initiative team page share same url
    // but there are different queryParams isGoalsPage and isInitiativesPage respectively

    if (
      isTeamPageRoute({ pathname: location.pathname, path: routes.managers.team }) &&
      [MODULE_KEYS1.GOAL, MODULE_KEYS1.INITIATIVE].includes(matchedModule)
    ) {
      // workaround till BE fixes team object in header autocomplete search api
      // if (!(queryParams.isInitiativesPage || queryParams.isGoalsPage)) {
      //   matchedModule = null;
      // }

      if (queryParams?.isGoalsPage === 'true') {
        matchedModule = MODULE_KEYS1.GOAL;
      }

      if (queryParams?.isInitiativesPage === 'true') {
        matchedModule = MODULE_KEYS1.INITIATIVE;
      }
    }

    if (matchedModule === MODULE_KEYS1.MODULE_SETTINGS && Object.values(MODULE_KEYS1).includes(queryParams?.module)) {
      matchedModule = queryParams.module;
    }

    // ****** special hardcoded condition to support old insights page
    if (queryParams?.ver === 'v0') {
      matchedModule = MODULE_KEYS1.OLD_INSIGHTS_PAGE;
    }

    if (location?.pathname?.includes('initiative')) {
      matchedModule = MODULE_KEYS1.INITIATIVE;
    }
    setModule(matchedModule);
    // enabledFeatureFlags is added in dependency array
    // because of a race condition in execution of this useEffect and enabled Feature array being stored in redux store
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, queryParams?.isGoalsPage, queryParams?.isInitiativesPage, enabledFeatures]);

  return { params, queryParams, module, pathname: location.pathname };
};
