import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { loggedInUsersAccessesKey } from 'Deer/hooks/useLoggedInUsersAccessesQuery';
import {
  deleteView,
  editView,
  getBaseCohortsApi,
  getEmployeesApi,
  getFilteredEmployeesCount,
  getManagersListApi,
  getOrganizationFunctionsApi,
  getPerformanceGridDataApi,
  getViewById,
  getViews,
  getViewsKeysApi,
  parseUploadEmployeeListCsv,
  saveView,
} from 'api/views';
import { useParsedCohortQueryParam } from 'components/hooks/useParsedCohortQueryParam';
import { useSidebarNavMatchRoute } from 'components/hooks/useSidebarNavMatchRoute';
import { useToast } from 'components/hooks/useToast';
import { useDispatch } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import {
  VIEWS_ACTIONS,
  resetView,
  resetViewsPanel,
  setAppRefreshCount,
  setIsSaveViewModalOpen,
  setSaveViewErrorMessage,
  useCheckedBaseCohort,
  useCheckedEmployees,
  useSelectedView,
  useView,
  useViewActions,
  useViewsPanelState,
  useViewsQuery,
} from 'reducers/viewsSlice';
import { checkNewNavEnabled } from 'utils/helpers/featureFlags';

import { COHORT_CREATION_PAGE_TYPE, MODULE_KEYS1, TOAST_STATUSES, VIEWS_FILTER_KEYS } from 'constants/ProjectConstants';
import { getEncodedCohortQueryParam } from 'constants/helper';
import { REACT_QUERY_KEYS } from 'constants/reactQueryKeys';
import { routes } from 'constants/routes';

const formatKeys = (data) => {
  const { keys } = data;
  return keys?.map(({ key, displayName }) => ({
    id: key,
    displayName,
  }));
};

const useCheckNonSidebarModuleMatch = ({ module }) => {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: routes.settings.review.reviewCycle,
    exact: true,
  });
  if (match) {
    return MODULE_KEYS1.REVIEW;
  }

  const matchEngagementSurvey = matchPath(location.pathname, {
    path: routes.settings.engagement.pulseKickstart,
    exact: true,
  });
  if (matchEngagementSurvey) {
    return MODULE_KEYS1.ENGAGEMENT;
  }

  return module;
};

export const useCohortKeys = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;
  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.filters, baseCohort.uuid, pageType],
    () => getViewsKeysApi({ pageType, baseCohortId: baseCohort.uuid }).then((res) => formatKeys(res.entity)),
    { enabled: !!pageType, retry: false }
  );
};

export const useManagers = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.managers, baseCohort.uuid, pageType],
    () => getManagersListApi({ baseCohortId: baseCohort.uuid, pageType }).then((res) => res.entity),
    { enabled: !!baseCohort.uuid && !!pageType }
  );
};

const getGrades = (functions) => {
  const allGrades = [];
  functions.forEach((func) => {
    if (func?.grades) {
      allGrades.push(...func.grades.map((g) => g.grade));
    }
    if (func?.childFunctions) {
      allGrades.push(...getGrades(func?.childFunctions));
    }
  });
  return allGrades;
};

const getUniqueGrades = (grades) => {
  const visited = new Map();
  const uniqueGrades = [];
  grades.forEach((grade) => {
    if (!visited.get(grade.uuid)) {
      visited.set(grade.uuid, true);
      uniqueGrades.push(grade);
    }
  });
  return uniqueGrades;
};

export const useGrades = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.grade, baseCohort.uuid, pageType],
    () =>
      getOrganizationFunctionsApi({ baseCohortId: baseCohort.uuid, pageType }).then((res) =>
        getUniqueGrades(getGrades(res.entity.childFunctions))
      ),
    { enabled: !!baseCohort.uuid && !!pageType }
  );
};

const getRoles = (functions) => {
  const allRoles = [];
  functions.forEach((func) => {
    if (func?.grades) {
      func.grades.forEach((grade) => {
        if (grade?.roles) {
          allRoles.push(...grade.roles.map((r) => r.role));
        }
      });
    }
    if (func?.childFunctions) {
      allRoles.push(...getRoles(func?.childFunctions));
    }
  });
  return allRoles;
};

const getUniqueRoles = (roles) => {
  const visited = new Map();
  const uniqueRoles = [];
  roles.forEach((role) => {
    if (!visited.get(role.uuid)) {
      visited.set(role.uuid, true);
      uniqueRoles.push(role);
    }
  });
  return uniqueRoles;
};

export const useRoles = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.role, baseCohort.uuid, pageType],
    () =>
      getOrganizationFunctionsApi({ baseCohortId: baseCohort.uuid, pageType }).then((res) =>
        getUniqueRoles(getRoles(res.entity.childFunctions))
      ),
    { enabled: !!baseCohort.uuid && !!pageType }
  );
};

const getFunctionsWithHeirarchy = (functions, ancestors) => {
  const allFunctions = [];
  if (!functions) return allFunctions;
  functions.forEach((func) => {
    const functionCopy = { ...func.function };
    functionCopy.hierarchy = [...ancestors, functionCopy.uuid];
    const subFunctions = getFunctionsWithHeirarchy(func.childFunctions, functionCopy.hierarchy);
    functionCopy.subFunctionCount = subFunctions.length;
    allFunctions.push(...subFunctions, functionCopy);
  });
  return allFunctions;
};

export const useFunctions = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.function, baseCohort.uuid, pageType],
    () =>
      getOrganizationFunctionsApi({ baseCohortId: baseCohort.uuid, pageType }).then((res) =>
        getFunctionsWithHeirarchy(res.entity.childFunctions, [])
      ),
    { enabled: !!baseCohort.uuid && !!pageType }
  );
};

export const useLocations = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.location, pageType, baseCohort.uuid],
    () =>
      getViewsKeysApi({ pageType, baseCohortId: baseCohort.uuid }).then(
        (res) => res.entity.content[VIEWS_FILTER_KEYS.LOCATION]
      ),
    { enabled: !!pageType && !!baseCohort.uuid, retry: false }
  );
};

export const useGenders = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.gender, pageType, baseCohort.uuid],
    () =>
      getViewsKeysApi({ pageType, baseCohortId: baseCohort.uuid }).then(
        (res) => res.entity.content[VIEWS_FILTER_KEYS.GENDER]
      ),
    { enabled: !!pageType && !!baseCohort.uuid, retry: false }
  );
};

export const useEthnicity = () => {
  const baseCohort = useCheckedBaseCohort();
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.ethnicity, pageType, baseCohort.uuid],
    () =>
      getViewsKeysApi({ pageType, baseCohortId: baseCohort.uuid }).then(
        (res) => res.entity.content[VIEWS_FILTER_KEYS.ETHNICITY]
      ),
    { enabled: !!pageType && !!baseCohort.uuid, retry: false }
  );
};

export const useBaseCohorts = () => {
  let { module } = useSidebarNavMatchRoute();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  return useQuery(
    [REACT_QUERY_KEYS.views.baseCohort, pageType],
    () => getBaseCohortsApi({ pageType }).then((res) => res.entity.baseCohort),
    { enabled: !!pageType, retry: false }
  );
};

export const usePerformanceGridData = ({ cohort, source, enabled }) =>
  useQuery(
    [REACT_QUERY_KEYS.views.performanceGrid],
    () => getPerformanceGridDataApi({ cohort, source }).then((res) => res.entity?.data),
    {
      enabled,
    }
  );

export const useEmployees = ({ cohortId }) => {
  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });

  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  return useQuery(
    [REACT_QUERY_KEYS.views.employees, module],
    () =>
      getEmployeesApi({
        moduleType: module,
        cohortId,
      }).then((res) => res.entity),
    { enabled: !!module && cohortId !== null && cohortId !== undefined }
  );
};

export const applyFilters = ({ history, location, view, module, dispatch, queryParamsCohort }) => {
  if (
    ![
      // routes.performanceReview.audit,
      routes.performanceReview.manage,
      routes.performanceReview.insights,
      routes.goals.audit,
      routes.goals.list,
      routes.goals.card,
      routes.goals.tree,
      routes.goals.insights,
      routes.personaInsights.index,
      routes.oneOnOne.audit,
      routes.oneOnOne.insights,
    ].includes(location.pathname)
  ) {
    if (location.pathname.includes('settings')) {
      return;
    }
  }

  let { pathname } = location;

  /** Special Handling for develop MyTeam page */
  if (module === MODULE_KEYS1.DEVELOP && view.baseCohortId === -2) {
    const search = getEncodedCohortQueryParam(view, history.location, false);
    history.push({
      pathname: routes.levelUp.myTeamInsights,
      search,
    });
    return;
  }
  /** END Special Handling for develop MyTeam page */

  // redirect the url to manage if view is applied because audit/preview doesnt support views
  if (
    module === MODULE_KEYS1.REVIEW &&
    ![routes.performanceReview.manage, routes.performanceReview.insights].includes(pathname)
  ) {
    pathname = routes.performanceReview.manage;
  } else if (module === MODULE_KEYS1.ENGAGEMENT && ![routes.engagement.manage].includes(pathname)) {
    pathname = routes.engagement.manage;
  } else if (module === MODULE_KEYS1.DEVELOP && ![routes.analytics.levelUp].includes(pathname)) {
    pathname = routes.analytics.levelUp;
  } else if (module === MODULE_KEYS1.CONTINUOUS_FEEDBACK && ![routes.analytics.continuousFeedback].includes(pathname)) {
    pathname = routes.analytics.continuousFeedback;
  }

  const historyObj = {
    search: getEncodedCohortQueryParam(view, history.location, true),
    pathname,
  };
  history.push(historyObj);
  if (queryParamsCohort?.id === view.id) {
    dispatch(setAppRefreshCount());
  }
};

export const useSaveView = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { onSuccess } = useViewsPanelState();

  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const { baseCohort, name, query, cohortEmployees, visibilityType, isViewStatic } = useView();
  const history = useHistory();
  const location = useLocation();
  const queryParamsCohort = useParsedCohortQueryParam();

  // force the type to be static if Employee Name selected
  const viewType = cohortEmployees?.length > 0 ? 1 : isViewStatic;

  return useMutation({
    mutationFn: (isApply) =>
      saveView(
        {
          baseCohort,
          name: isApply ? '' : name,
          query,
          visibilityType,
          cohortEmployees,
          isStatic: viewType,
        },
        module,
        isApply
      ),
    onSuccess: (data, isApply) => {
      if (typeof onSuccess === 'function') {
        onSuccess(data.entity);
      }
      const view = { id: data.entity.id, name: data.entity.name, type: data.entity.type };
      if (isApply) {
        /**
         * Sending baseCohortId only for temporary views
         */
        const baseCohortId = data.entity.baseCohort.id;
        view.baseCohortId = baseCohortId;
      }
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.views]);
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.sidebarViewCohorts, module]).then(() => {
        applyFilters({ history, location, view, module, dispatch, queryParamsCohort });
      });
      queryClient.invalidateQueries([loggedInUsersAccessesKey]);

      dispatch(setSaveViewErrorMessage(null));
      dispatch(setIsSaveViewModalOpen(false));
      dispatch(resetViewsPanel());
      dispatch(resetView());
    },
    onError: (error) => {
      if (error?.message) {
        dispatch(setSaveViewErrorMessage(error.message));
      }
      dispatch(setIsSaveViewModalOpen(false));
    },
  });
};

export const useEditView = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  let { module } = useSidebarNavMatchRoute();
  const { onSuccess = () => {} } = useViewsPanelState();
  const viewAction = useViewActions();

  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const pageType = viewAction === VIEWS_ACTIONS.EDIT_CUSTOM_ACCESS ? 'STATUS' : COHORT_CREATION_PAGE_TYPE[module];

  const { id, baseCohort, name, query, cohortEmployees, visibilityType, isViewStatic } = useView();
  const history = useHistory();
  const location = useLocation();
  const queryParamsCohort = useParsedCohortQueryParam();
  const toast = useToast();
  // force the type to be static if Employee Name selected
  const viewType = cohortEmployees?.length > 0 ? 1 : isViewStatic;

  return useMutation({
    mutationFn: () =>
      editView(
        id,
        {
          baseCohort,
          name,
          query,
          visibilityType,
          cohortEmployees,
          isStatic: viewType,
        },
        module
      ),
    onSuccess: (data) => {
      const view = { id: data.entity.id, name: data.entity.name, type: data.entity.type };
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.views, pageType, id]);
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.sidebarViewCohorts, module]).then(() => {
        applyFilters({ history, location, view, module, dispatch, queryParamsCohort });
      });
      dispatch(setIsSaveViewModalOpen(false));
      dispatch(resetViewsPanel());
      dispatch(resetView());
      onSuccess();
    },
    onError: (error) => {
      toast({
        title: error?.message ?? 'Sorry, an error occurred!',
        status: TOAST_STATUSES.ERROR,
      });
      dispatch(setIsSaveViewModalOpen(false));
    },
  });
};

export const useDeleteView = () => {
  const queryClient = useQueryClient();
  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ viewId }) =>
      deleteView({
        viewId,
      }),
    onSuccess: () => {
      dispatch(setIsSaveViewModalOpen(false));
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.views, module]);
      queryClient.invalidateQueries([REACT_QUERY_KEYS.views.sidebarViewCohorts, module]);

      // this will not be applicable always, but we can just do it anyway.
      // this is for review creation view selection part
      queryClient.invalidateQueries([REACT_QUERY_KEYS.reviewCreation.views]);
    },
  });
};

export const useViews = () => {
  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });
  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  return useQuery(
    [REACT_QUERY_KEYS.views.views, module],
    () => getViews({ moduleType: module }).then((res) => res.entity.cohortList),
    { enabled: !!module }
  );
};

export const useParseUploadEmployeeListCsv = ({ fileObject }) =>
  useQuery({
    queryKey: [REACT_QUERY_KEYS.views.csvEmployees],
    retry: false,
    queryFn: () =>
      parseUploadEmployeeListCsv({
        fileObject,
      }).then((res) => res.entity),
  });

export const useViewById = () => {
  const selectedView = useSelectedView();
  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });

  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  return useQuery(
    [REACT_QUERY_KEYS.views.views, module, selectedView?.id],
    () => getViewById({ id: selectedView?.id, moduleType: module }).then((res) => res.entity),
    { enabled: !!selectedView?.id && !!module }
  );
};

export const useIsNoFilterSelected = () => {
  const query = useViewsQuery();
  const selectedEmployees = useCheckedEmployees();
  return !Object.keys(query || {}).length && !selectedEmployees?.length;
};

export const useFilteredEmployeeCount = () => {
  let { module } = useSidebarNavMatchRoute();
  // special condition, till we are using new views with old pages
  module = useCheckNonSidebarModuleMatch({ module });

  module = checkNewNavEnabled() ? MODULE_KEYS1.GOAL : module;

  const { baseCohort, query, cohortEmployees, visibilityType, isViewStatic } = useView();

  // force the type to be static if Employee Name selected
  const viewType = cohortEmployees?.length > 0 ? 1 : isViewStatic;

  return useQuery(
    [REACT_QUERY_KEYS.views.filteredEmployeesCount, module, baseCohort, query, cohortEmployees],
    () =>
      getFilteredEmployeesCount({
        moduleType: module,
        view: {
          baseCohort,
          name: '',
          query,
          visibilityType,
          cohortEmployees,
          isStatic: viewType,
        },
        module,
      }).then((res) => res.entity),
    { enabled: !!module }
  );
};
