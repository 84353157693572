import { L2_VALUES, L3_PATHS } from 'Deer/constants/routes';
import { format, getMinutes, getHours, formatDistanceToNow, formatDistanceStrict, parse } from 'date-fns';
import isEqual from 'date-fns/isEqual';
import { AutoComputeGoalRatingSettings } from 'models/admin/rating';
import HashTag from 'models/hashtag';
import queryString from 'query-string';
import { matchPath } from 'react-router-dom';
import { selectLoggedInEmployeeId } from 'selectors/loggedInEmployee';
import store from 'store';
import { checkNewNavEnabled } from 'utils/helpers/featureFlags';

import {
  PULSE_KICKSTART_STEPS,
  REVIEW_CYCLE_STEPS,
  lastMonth,
  lastQuarter,
  lastTwoMonths,
  lastTwoQuarters,
  thisMonth,
  thisQuarter,
  thisYear,
  SEARCH_TAG_TYPES,
  TEAM_TYPE,
  GOAL_MEASUREMENT_TYPES,
  ADD_TEXT,
  TASK_TYPES_2,
  TASK_STATUS,
  TASK_DETAILED_STATUS,
  TRANSACTION_SUMMARY_TYPES,
  ORG_ID,
  FEEDBACK_FORM_TYPE,
  ANALYTICS_FILTERS_KEYS,
  ANALYTICS_EMAIL_SUBJECTS,
  PROD_APP_DOMAIN,
  PROD_LOGIN_DOMAIN,
  SETTINGS_ROUTE_FOR_HEADER,
  MODULE_KEYS1,
  SIDE_NAV_MATCH_ROUTES,
  past12Months,
  past6Months,
  CREATE_OBJECTIVE_PRIVILEGES,
} from 'constants/ProjectConstants';
import { endpoints } from 'constants/endpoints';
import theme from 'constants/theme';

import { GOAL_MEASUREMENT_CURRENCIES } from './goalMeasurementCurrencies';
import { MODULE_SETTINGS_TABS_ID } from './moduleSettingsConstants';
import { routes } from './routes';
import { routesForTracking } from './routesForTracking';

export const getDateFromEpoch = (epoch) => {
  const date = new Date(epoch);
  return date;
};

export function camelCase(str) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '');
}

const checkIfIsADateObject = (date) => {
  if (!date && date.constructor.name !== 'Date') throw Error('Please provide a date object');
};

/**
 *
 *
 * @param {Date} date
 * @returns
 */
export const getEpochFromDate = (date) => {
  checkIfIsADateObject(date);
  return date.getTime();
};

export const getHumanReadableDate = (date) => {
  checkIfIsADateObject(date);
  return formatDistanceToNow(date, { addSuffix: true });
};

export const getShortHumanReadableDate = (date) => {
  checkIfIsADateObject(date);
  return formatDistanceStrict(date, new Date());
};

/**
 *
 * @param {Date} date
 * Returns in this format: 02/11/2014
 */
export const convertDateToString1 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'MM/dd/yyyy');
};

/**
 *
 * @param {Date} date
 * Returns in this format: 07 Aug
 */
export const convertDateToDayAndMonth = (date) => {
  const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const d = new Date(date);
  const dateDtr = `${d.getDate()} ${monthNames[d.getMonth()]}`;
  return dateDtr;
};

/**
 *
 * @param {Date} date
 * Returns in this format: 12 Dec 2014
 */
export const convertDateToString2 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'dd MMM yyyy');
};

/**
 *
 * @param {Date} date
 * Returns in this format: DD/MM/YYYY
 */
export const convertDateToString3 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'dd/MM/yyyy');
};

/**
 *
 * @param {Date} date
 * Returns in this format: 12 Dec
 */
export const convertDateToString4 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'dd MMM');
};

/**
 *
 * @param {Date} date
 * Returns in this format: 12 Dec
 */
export const convertDateToString4Reverse = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'MMM dd');
};

/**
 *
 * @param {Date} date
 * Returns in this format: 12 Dec 2014 10:15 am
 */
export const convertDateToString5 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'dd MMM yyyy hh:mm a');
};

/**
 *
 * @param {Date} date
 * Returns in this format: 2014-12-21
 */
export const convertDateToString6 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'yyyy-MM-dd');
};

/**
 *
 * @param {Date} date
 * @param {Boolean} showAMPM
 * Returns in this format: 4 PM or 4 or 4:30
 */
export const convertDateToString7 = (date, showAMPM = false) => {
  checkIfIsADateObject(date);
  let formatString = 'h';

  formatString = [10, 11, 12, 22, 23, 24].includes(getHours(date)) ? `${formatString}h` : formatString;
  formatString = getMinutes(date) > 0 ? `${formatString}:mm` : formatString;
  formatString = showAMPM ? `${formatString}aaa` : formatString;
  return format(date, formatString);
};

/**
 *
 * @param {Date} date
 * @param {Boolean} showAMPM
 * Returns in this format:  04:30, 04:00
 */
export const convertDateToString8 = (date, showAMPM = false) => {
  checkIfIsADateObject(date);

  let formatString = 'hh:mm';
  formatString = showAMPM ? `${formatString} a` : formatString;
  return format(date, formatString);
};

/**
 *
 * @param {Date} date
 * Returns in this format: July 2021
 */
export const convertDateToString9 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'MMMM yyyy');
};

/**
 *
 * @param {Date} date
 * Returns in this format: Mon, Nov 17
 */
export const convertDateToString10 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'EEEE, MMM d');
};

/**
 *
 * @param {Date} date
 * Returns in this format: Dec 5, 2022
 */

export const convertDateToString11 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'MMM dd, yyyy');
};

/**
 *
 * @param {Date} date
 * Returns in this format: Jan 01
 */

export const convertDateToString12 = (date) => {
  checkIfIsADateObject(date);
  return format(date, 'MMM dd');
};

export const setEndOfTheDayTime = (date) => {
  date.setHours(23, 59, 59, 0);
};

export const setStartOfTheDayTime = (date) => {
  date.setHours(0, 0, 1, 0);
};

/**
 *
 * @param {String} yy/MM/yyyy
 * Returns {Date}
 */
export const convertString3ToDate = (dateStr) => parse(dateStr, 'dd/MM/yyyy', new Date());

/**
 *
 * @param {String} yyyy-MM-dd
 * Returns {Date}
 */
export const convertString6ToDate = (dateStr) => parse(dateStr, 'yyyy-MM-dd', new Date());

export const getColorForDriveLevel = (level) => {
  const graphColors = theme.palette.graphs;
  level = level.toLowerCase();
  switch (level) {
    case 'high':
      return graphColors.green;
    case 'low':
      return graphColors.red;
    case 'medium':
      return graphColors.mustard;
    default:
      return graphColors.green;
  }
};

export const getColorFromDeliverableStatus = (status) => {
  const graphColors = theme.palette.graphs;

  switch (status) {
    case 'ONTRACK':
      return graphColors.mustard;
    case 'PENDING':
      return graphColors.red;
    case 'COMPLETED':
      return graphColors.green;
    default:
      return graphColors.red;
  }
};

export const getPercentileColor = (value) => {
  const colors = theme.palette.graphs;
  if (value >= 75) {
    return colors.green;
  }
  if (value >= 50) {
    return theme.palette.yellow.main;
  }
  if (value >= 25) {
    return colors.mustard;
  }
  return colors.red;
};

export const getMeshScoreColor = (value) => {
  const colors = theme.palette.graphs;
  if (value >= 75) {
    return colors.green;
  }
  if (value >= 50) {
    return theme.palette.lightOrange.main;
  }
  if (value >= 25) {
    return colors.mustard;
  }
  return colors.red;
};

export const getDevelopmentLevelColor = (level) => {
  const graphColors = theme.palette.graphs;

  switch (level) {
    case 'BEGINNER':
      return graphColors.brickRed;
    case 'INTERMEDIATE':
      return graphColors.mustard2;
    case 'ADVANCED':
      return graphColors.darkGreen;
    default:
      return graphColors.yellow;
  }
};

export const getImpactColor = (impact) => {
  const graphColors = theme.palette.graphs;

  switch (impact) {
    case 'HIGH':
      return graphColors.red;
    case 'MEDIUM':
      return graphColors.mustard;
    case 'LOW':
      return graphColors.yellow;
    default:
      return graphColors.green;
  }
};
/* Growth routes */

export function goToGrowthTabularPage(history, jobFunctionId) {
  const route = routes.settings.growth.jobFunctionTable.replace(':jobFunctionId', jobFunctionId);
  history.push(route);
}

export function goToOneOnOneLandingPage(history) {
  history.push(routes.oneOnOne.index);
}

export function goToLevelUpPage(history) {
  history.push(routes.levelUp.index);
}

export function goToContinuousFeedback(history) {
  history.push(routes.continuousFeedback.index);
}

export function goToOneOnOneInstancePage(history, instanceId, referrer) {
  const route = routes.oneOnOne.instance.replace(':instanceId', instanceId);
  history.push({
    pathname: route,
    search: referrer ? `?ref=${referrer}` : '',
  });
}

export function getOneOnOneInstancePageRoute(instanceId) {
  const route = routes.oneOnOne.instance.replace(':instanceId', instanceId);
  return route;
}

export function goToOneOnOneTimelinePage(history, instanceId, referrer) {
  const route = routes.oneOnOne.timeline.replace(':instanceId', instanceId);
  history.push({
    pathname: route,
    search: referrer ? `?ref=${referrer}` : '',
  });
}

export function goToReview(history) {
  history.push(routes.performanceReview.audit);
}

export function goToHome(history) {
  history.push(routes.home);
}

export function goToProdAppDomain() {
  window.location.href = `https://${PROD_APP_DOMAIN}`;
}

// ************* ye change hoga
export function goToProdLoginDomain({ queryParams = {} }) {
  let url = `https://${PROD_LOGIN_DOMAIN}/login?`;
  Object.keys(queryParams).forEach((key) => {
    url = `${url}${key}=${queryParams[key]}&`;
  });

  window.location.href = url;
}

export function goToDevEnvLoginPage({ queryParams = {} }) {
  let url = `${window.location.origin + routes.login}?`;
  // "+" in emails was not getting decoded as it's coming as "%20" from BE which is " "
  // https://github.com/sindresorhus/query-string/issues/305
  Object.keys(queryParams).forEach((key) => {
    url = `${url}${key}=${encodeURIComponent(queryParams[key])}&`;
  });

  window.location.replace(url);
}

export function goToTeamSummary(history) {
  history.push(routes.teamSummary);
}

export function goToMeshIn(history) {
  history.push(routes.meshin);
}

export function goToMeshUp(history) {
  history.push(routes.meshup);
}

export function goToLogin(history) {
  history.push(routes.login);
}

export function goToRoute(history, route) {
  history.push(route);
}

export function goToManagers(history, path) {
  history.push(routes.managers[path]);
}

export function goToSettings(history) {
  history.push(routes.settings.index);
}

export function goToProfilePage({ history, employeeId }) {
  const route = getProfileRoute(employeeId);
  goToRoute(history, route);
}

export function goToEngagement(history) {
  history.push(routes.engagement.index);
}

export function goToPersonaInsights(history) {
  history.push(routes.personaInsights.index);
}

export function goToEmbeddedAnalytics(analysisId, history) {
  const url = routes.embeddedAnalytics.index.replace(':id', analysisId);
  history.push(url);
}
/**
 *
 *
 * @export
 * @param {*} {history, searchValue}
 * @param {SEARCH_TAG_TYPES} {searchType}
 */
export function goToSearchDetailPage({ history, searchType, typeId, label }) {
  const route = `/search?filterType=${searchType}&filterId=${typeId}&label=${label}`;

  // route = encodeURI(route);
  // window.location.href= route;
  history.push(route);
}

export const goToEmailRegister = (history) => {
  history.push(routes.emailRegister);
};

export const goToEmailVerification = (history) => {
  history.push(routes.validateEmail);
};

export const goToForgotPassword = (history) => {
  history.push(routes.forgotPassword);
};

export const goToForgotPasswordConfirmation = (history) => {
  history.push(routes.forgotPasswordConfirmation);
};

export const goToSelectDepartment = (history) => {
  history.push(routes.selectDepartment);
};

export const goToSelectTeam = (history) => {
  history.push(routes.selectTeam);
};

export const goToInvite = (history) => {
  history.push(routes.invite);
};

export const goToProfileSetup = (history) => {
  history.push(routes.settings.myProfile);
};

export const goToTeamSettings = (history, teamId) => {
  history.push(routes.settings.team.replace(':teamId', teamId));
};

export function getTaskDetailsRoute({
  location,
  ids = [],
  useCurrentRoute = true,
  employee = null,
  type,
  scrollToId = '',
}) {
  const queryParams = queryString.parse(useCurrentRoute ? location.search : '');
  queryParams.showTaskDetails = true;
  queryParams.ids = ids.join(',');
  if (type) {
    queryParams.type = type;
  }
  if (scrollToId) queryParams.scrollToId = scrollToId;

  if (employee) queryParams.empId = employee.id;
  const searchQuery = queryString.stringify(queryParams);
  return `${useCurrentRoute ? location.pathname : routes.home}?${searchQuery}`;
}

export function getCompetencySidePanelRoute({
  location,
  useCurrentRoute = true,

  // query params
  employeeId,
  competencyId,
  levelUpCompetencyId = null,
  levelUpId,
  scrollToId,
  checkInInstanceId,
  checkInParentId,
  isOneOnOne,
}) {
  const queryParams = queryString.parse(useCurrentRoute ? location.search : '');

  if (employeeId) queryParams.empId = employeeId;
  if (competencyId) queryParams.competencyId = competencyId;
  if (levelUpCompetencyId) queryParams.levelUpCompetencyId = levelUpCompetencyId;
  if (levelUpId) queryParams.levelUpId = levelUpId;
  if (scrollToId) queryParams.scrollTo = scrollToId;
  if (checkInInstanceId) queryParams.checkInInstanceId = checkInInstanceId;
  if (checkInParentId) queryParams.checkInParentId = checkInParentId;
  if (isOneOnOne) queryParams.isOneOnOne = isOneOnOne;
  const searchQuery = queryString.stringify(queryParams);
  return `${useCurrentRoute ? location.pathname : routes.home}?${searchQuery}`;
}

export function getRouteAfterRemovingTaskDetailsAndAppendingObjectiveDetails({ location, objectivePId }) {
  const queryParams = queryString.parse(location.search);
  queryParams.showTaskDetails = null;
  queryParams.ids = null;
  queryParams.type = null;
  queryParams.empId = null;
  queryParams.objectivePId = objectivePId;
  const searchQuery = queryString.stringify(queryParams, { skipNull: true });

  return `${location.pathname}?${searchQuery}`;
}

export function getRouteAfterRemovingObjectiveDetailsAndAppendingTaskDetails({
  location,
  ids = [],
  useCurrentRoute = true,
  employee = null,
  type,
}) {
  const queryParams = queryString.parse(location.search);

  queryParams.objectiveId = null;
  queryParams.objectiveTimeBoundId = null;
  queryParams.timeBoundId = null;
  queryParams.objectivePId = null;

  queryParams.showTaskDetails = true;
  queryParams.ids = ids.join(',');
  if (type) {
    queryParams.type = type;
  }
  if (employee) queryParams.empId = employee.id;

  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getRouteAfterRemovingTaskDetails({ location, taskIds = [] }) {
  const queryParams = queryString.parse(location.search);
  queryParams.showTaskDetails = null;
  queryParams.ids = null;
  queryParams.type = null;
  queryParams.empId = null;
  queryParams.objectivePId = null;
  queryParams.competencyId = null;
  queryParams.isCompetencyPanelOpen = null;
  /* remove panelStack query param */
  queryParams.panelStack = null;
  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getRouteAfterRemovingTaskAndObjectiveDetails({ location, taskIds = [] }) {
  const queryParams = queryString.parse(location.search);
  queryParams.showTaskDetails = null;
  queryParams.ids = null;
  queryParams.type = null;
  queryParams.empId = null;
  queryParams.objectiveId = null;
  queryParams.objectiveTimeBoundId = null;
  queryParams.timeBoundId = null;
  queryParams.objectivePId = null;
  /* remove panelStack query param */
  queryParams.panelStack = null;
  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getRouteAfterRemovingCompetencySidePanel({ location, taskIds = [] }) {
  const queryParams = queryString.parse(location.search);
  queryParams.empId = null;
  queryParams.competencyId = null;
  queryParams.levelUpCompetencyId = null;
  queryParams.levelUpId = null;
  queryParams.scrollTo = null;
  queryParams.checkInInstanceId = null;
  queryParams.checkInParentId = null;
  queryParams.isOneOnOne = null;
  queryParams.scrollToId = null;
  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getObjectiveDetailsRoute({
  location,
  objectiveId,
  objectiveTimeBoundId,
  timeBoundId,
  objectivePId,
  useCurrentRoute = true,
  scrollToId = '',
}) {
  const queryParams = queryString.parse(useCurrentRoute ? location.search : '');

  if (objectivePId) queryParams.objectivePId = objectivePId;
  else queryParams.objectivePId = undefined;

  if (objectiveId) queryParams.objectiveId = objectiveId;
  else queryParams.objectiveId = undefined;

  if (objectiveTimeBoundId) queryParams.objectiveTimeBoundId = objectiveTimeBoundId;
  else queryParams.objectiveTimeBoundId = undefined;

  if (timeBoundId) queryParams.timeBoundId = timeBoundId;
  else queryParams.timeBoundId = undefined;
  if (scrollToId) queryParams.scrollToId = scrollToId;
  else queryParams.scrollToId = undefined;

  const searchQuery = queryString.stringify(queryParams);
  return `${useCurrentRoute ? location.pathname : routes.home}?${searchQuery}`;
}

export function getRouteAfterRemovingObjectiveDetails({ location }) {
  const queryParams = queryString.parse(location.search);

  queryParams.objectiveId = null;
  queryParams.objectiveTimeBoundId = null;
  queryParams.timeBoundId = null;

  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getRouteAfterRemovingObjectiveDetailsAndKRDetails({ location }) {
  const queryParams = queryString.parse(location.search);

  queryParams.objectiveId = null;
  queryParams.objectiveTimeBoundId = null;
  queryParams.timeBoundId = null;
  queryParams.objectivePId = null;

  queryParams.showTaskDetails = null;
  queryParams.ids = null;
  queryParams.type = null;
  queryParams.empId = null;

  /* remove panelStack query param */
  queryParams.panelStack = null;

  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getQuestionDetailsRoute({
  location,
  action = 'edit',
  source = 'review',
  questionId,
  useCurrentRoute = true,
}) {
  const queryParams = queryString.parse(useCurrentRoute ? location.search : '');
  queryParams.showQuestionDetails = true;
  queryParams.questionId = questionId;
  queryParams.action = action;
  queryParams.source = source;
  const searchQuery = queryString.stringify(queryParams);
  return `${useCurrentRoute ? location.pathname : routes.home}?${searchQuery}`;
}

export function getRouteAfterRemovingQuestionDetails({ location }) {
  const queryParams = queryString.parse(location.search);
  queryParams.showQuestionDetails = null;
  queryParams.questionId = null;
  queryParams.action = null;
  queryParams.source = null;
  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getNominatePeersRoute({ location, reviewId, useCurrentRoute = true, empId }) {
  const queryParams = queryString.parse(useCurrentRoute ? location.search : '');
  queryParams.showNominatePeers = true;
  queryParams.reviewId = reviewId;
  queryParams.empId = empId;
  const searchQuery = queryString.stringify(queryParams);
  return `${useCurrentRoute ? location.pathname : routes.home}?${searchQuery}`;
}

export function getRouteAfterRemovingNominatePeers({ location }) {
  const queryParams = queryString.parse(location.search);
  queryParams.showNominatePeers = null;
  queryParams.reviewId = null;
  queryParams.empId = null;

  const searchQuery = queryString.stringify(queryParams, { skipNull: true });
  return `${location.pathname}?${searchQuery}`;
}

export function getStrengthDetailRoute(employeeId, strengthId) {
  let route = routes.strengthDetail;
  route = route.replace(':employeeId', employeeId);
  return route.replace(':strengthId', strengthId);
}

export function getDriverDetailRoute(employeeId, driverId) {
  let route = routes.driverDetail;
  route = route.replace(':employeeId', employeeId);
  return route.replace(':driverId', driverId);
}

export function getDeliverableDetailRoute(employeeId, deliverableId) {
  let route = routes.deliverableDetail;
  route = route.replace(':employeeId', employeeId);
  return route.replace(':deliverableId', deliverableId);
}

export function getMeshStoreRoute(employeeId, summaryType) {
  let route = routes.meshStore;
  route = route.replace(':employeeId', employeeId);
  return route.replace(':summaryType', summaryType);
}

export function getProfileRoute(employeeId) {
  return routes.profile.replace(':employeeId', employeeId);
}

export function getFeedDetailRoute(feedId) {
  return routes.feedDetail.replace(':feedId', feedId);
}

export function getSurveyDetailRoute(surveyId) {
  return routes.surveyDetail.replace(':surveyId', surveyId);
}

export function goToStrengthDetailPage({ employeeId = 1, entityId, history }) {
  const route = getStrengthDetailRoute(employeeId, entityId);
  history.push(route);
}

export function goToGoalDetailPage({ employeeId = 1, entityId, history }) {
  const route = getDeliverableDetailRoute(employeeId, entityId);
  history.push(route);
}

export function getReviewCycleRoute(formId, step) {
  let route = routes.settings.review.reviewCycle;
  route = route.replace(':step', step || REVIEW_CYCLE_STEPS[0]);
  return route.replace(':formId', formId);
}

export function getPulseKickstartRoute(formId, step) {
  let route = routes.settings.engagement.pulseKickstart;
  route = route.replace(':step', step || PULSE_KICKSTART_STEPS[0]);
  return route.replace(':formId', formId);
}

export function getPulseSurveyRoute(surveyId) {
  const route = routes.engagement.surveyQuestionnaire;
  return route.replace(':surveyId', surveyId);
}

export function getSubmittedPulseSurveyRoute(surveyId) {
  const route = checkNewNavEnabled() ? L3_PATHS['my-data'].engagement.submittedSurvey : routes.engagement.pastSurvey;
  return route.replace(':surveyId', surveyId);
}

export function goToReviewCycle({ history, entityId, step }) {
  const route = getReviewCycleRoute(entityId, step);
  history.push(route);
}

export function goToPulseKickstart({ history, entityId, step }) {
  const route = getPulseKickstartRoute(entityId, step);
  history.push(route);
}

export const getModuleSettingsRoute = ({ module = '', activeTabId = '' }) =>
  routes.moduleSettings + (module ? `?module=${module}` : '') + (activeTabId ? `&activeTabId=${activeTabId}` : '');

export const getDeerModuleSettingsRoute = ({ module = '', activeTabId = '' }) =>
  L3_PATHS.company[module].settings + (activeTabId ? `?activeTabId=${activeTabId}` : '');

export function goToModuleSettingsPage({ history, module = '', activeTabId = '', replaceTopHistoryItem = false }) {
  const settingsPageUrl = getModuleSettingsRoute({ module, activeTabId });
  if (replaceTopHistoryItem) {
    history.replace(settingsPageUrl);
  } else {
    history.push(settingsPageUrl);
  }
}

export function goToDeerModuleSettingsPage({ history, module = '', activeTabId = '', replaceTopHistoryItem = false }) {
  const settingsPageUrl = getDeerModuleSettingsRoute({ module, activeTabId });
  if (replaceTopHistoryItem) {
    history.replace(settingsPageUrl);
  } else {
    history.push(settingsPageUrl);
  }
}

export function goToPulseSettingPage(history) {
  if (checkNewNavEnabled()) {
    goToDeerModuleSettingsPage({
      history,
      module: L2_VALUES.engagement,
      activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][3].queryParamId,
    });
    return;
  }
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.ENGAGEMENT,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][3].queryParamId,
  });
}

export function goToReviewProcess(history) {
  if (checkNewNavEnabled()) {
    goToDeerModuleSettingsPage({
      history,
      module: L2_VALUES.review,
      activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][2].queryParamId,
    });
    return;
  }
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.REVIEW,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][2].queryParamId,
  });
}

export function goToOneOnOnePreferencesPage(history) {
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.ONE_ON_ONE,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ONE_ON_ONE][2].queryParamId,
  });
}

export function getReviewTemplateListRoute() {
  return getModuleSettingsRoute({
    module: MODULE_KEYS1.REVIEW,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][1].queryParamId,
  });
}

export function getOneOnOneTemplateListRoute() {
  return getModuleSettingsRoute({
    module: MODULE_KEYS1.ONE_ON_ONE,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ONE_ON_ONE][1].queryParamId,
  });
}

export function goToReviewTemplateListRoute(history) {
  if (checkNewNavEnabled()) {
    goToDeerModuleSettingsPage({
      history,
      module: L2_VALUES.review,
      activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][1].queryParamId,
    });
    return;
  }
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.REVIEW,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][1].queryParamId,
  });
}

export function getEngagementTemplateListRoute() {
  if (checkNewNavEnabled()) {
    return getDeerModuleSettingsRoute({
      module: L2_VALUES.engagement,
      activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][2].queryParamId,
    });
  }
  return getModuleSettingsRoute({
    module: MODULE_KEYS1.ENGAGEMENT,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][2].queryParamId,
  });
}

export const getReviewSettingsTemplateRoute = () => {
  if (checkNewNavEnabled()) {
    return `${L3_PATHS.company.review.settings}?activeTabId=${
      MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][1].queryParamId
    }`;
  }

  return getModuleSettingsRoute({
    module: MODULE_KEYS1.REVIEW,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.REVIEW][1].queryParamId,
  });
};

export function goToEngagementTemplateListRoute(history) {
  if (checkNewNavEnabled()) {
    goToDeerModuleSettingsPage({
      history,
      module: L2_VALUES.engagement,
      activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][2].queryParamId,
    });
    return;
  }
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.ENGAGEMENT,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ENGAGEMENT][2].queryParamId,
  });
}

export function goToOneOnOneTemplateListRoute(history) {
  goToModuleSettingsPage({
    history,
    module: MODULE_KEYS1.ONE_ON_ONE,
    activeTabId: MODULE_SETTINGS_TABS_ID[MODULE_KEYS1.ONE_ON_ONE][1].queryParamId,
  });
}

export function getDiscoverDetailRoute(loggedInEmployeeId, employeeId) {
  return routes.discoverDetail.replace(':employeeId', employeeId).replace(':loggedInEmployeeId', loggedInEmployeeId);
}

export function getTeamPageRoute({ teamId, teamName }) {
  const route = routes.managers.team.replace(':teamId', teamId);
  return `${route}?teamName=${teamName}`;
}

export function getTeamPageForGoalsRoute({ teamId, teamName = '', teamType = 'POD' }) {
  const encodedTeamName = encodeURIComponent(teamName);
  const encodedTeamType = encodeURIComponent(teamType);
  const route = routes.managers.team.replace(':teamId', teamId);
  return `${route}?isGoalsPage=true&teamName=${encodedTeamName}${
    encodedTeamType ? `&teamType=${encodedTeamType}` : ''
  }`;
}

export function getTeamPageForInitiativesRoute({ teamId, teamName = '', teamType = 'POD' }) {
  const encodedTeamName = encodeURIComponent(teamName);
  const encodedTeamType = encodeURIComponent(teamType);
  const route = routes.managers.team.replace(':teamId', teamId);
  return `${route}?isInitiativesPage=true&teamName=${encodedTeamName}${
    encodedTeamType ? `&teamType=${encodedTeamType}` : ''
  }`;
}

export function getInitiativesRoute({ teamId, teamName = '', activeTabId = 'All' }) {
  // const encodedTeamName = encodeURIComponent(teamName);
  // const encodedTeamType = encodeURIComponent(activeTabId);
  // const route = routes.initiative.audit;
  // return `${route}?${encodedTeamName}&activeTableId=${activeTabId}`;

  const encodedTeamName = encodeURIComponent(teamName);
  const encodedActiveTabId = encodeURIComponent(activeTabId);
  const route = routes.initiative.audit;
  return `${route}?teamId=${teamId}&teamName=${encodedTeamName}&activeTableId=${encodedActiveTabId}`;
}

// export const getInsightModuleRouteForOldVersion = ({ route }) => {
//   return `${route}?ver=v0`;
// };

export function getTagDetailRoute({ tagId, tagName, tagType }) {
  let route = routes.tagDetail.replace(':tagId', tagId);
  route += `?tagName=${tagName}&tagType=${tagType || 'MESH_TASK'}`;
  return route;
}

export function getReviewFormRoute(reviewId, employeeId) {
  return routes.performanceReview.forms.replace(':reviewId', reviewId).replace(':employeeId', employeeId);
}

export function getReviewResultRoute(reviewId, employeeId) {
  return routes.performanceReview.result.replace(':reviewId', reviewId).replace(':employeeId', employeeId);
}

const data = [
  {
    selected: true,
    searches: [{ label: 'indra', value: 'indra', tagType: 'EMPLOYEE_NAME', id: 1 }],
    type: '',
    displayName: '',
    deletable: false,
  },
  {
    selected: true,
    searches: [{ label: 'indu', value: 'indu', tagType: 'EMPLOYEE_NAME', id: 2 }],
    type: 'DESIGNATION',
    displayName: 'Designation',
    deletable: true,
  },
];

export function getQueryParams(searchString) {
  return queryString.parse(searchString);
}

function convertSearchFiltersToUrl(searchFilters) {
  const resultParams = {};
  searchFilters.map((filter) => {
    const { displayName, searches } = filter;
    const joinedParams = searches.map((searchedFor) => searchedFor.label).join(',');
    if (displayName) {
      resultParams[displayName] = joinedParams;
    } else {
      resultParams.noTag = joinedParams;
    }
  });
  const esc = encodeURIComponent;
  const query = Object.keys(resultParams)
    .map((k) => `${esc(k)}=${esc(resultParams[k])}`)
    .join('&');
  return query;
}
// Search Result For: Name-Jasmin, Objective-Business Development, Strength-Negotiation, Relationship-Mentor
export function getFilterStringFromQueryParams(queryParams) {
  const result = [];
  Object.keys(queryParams).forEach((key) => {
    const paramsValue = queryParams[key];
    if (key === 'noTag') key = 'Default = ';
    else key += ' = ';
    const data = key + paramsValue;
    result.push(data);
  });
  return `Search Result For:  ${result.join('; ')}`;
}

export function getSearchResultsRoute(searchFilters) {
  const params = convertSearchFiltersToUrl(searchFilters);
  return `${routes.searchResults}?${params}`;
}

export function getColorFromKey(colorKey) {
  switch (colorKey) {
    case 'primary':
    case 'blue':
      return theme.palette.primary.main;
    case 'secondary':
      return theme.palette.secondary.main;
    case 'textPrimary':
      return theme.palette.text.primary;
    case 'textSecondary':
      return theme.palette.text.secondary;
    case 'textSecondaryLight':
      return theme.palette.text.secondaryLight;
    case 'textLabel':
      return theme.palette.text.label;
    case 'textHeading':
      return theme.palette.text.heading;
    case 'divider':
      return theme.palette.divider;
    case 'green':
      return theme.palette.icons.green;
    case 'darkGreen':
      return theme.palette.green.dark;
    case 'purple':
      return theme.palette.purple.main;
    case 'purpleChart':
      return theme.palette.purpleChart;
    case 'red':
      return theme.palette.icons.red;
    case 'amber':
    case 'orange':
      return theme.palette.graphs.lightOrange;
    case 'mustard':
      return theme.palette.secondary.main;
    case 'yellow':
      return theme.palette.yellow.main;
    case 'lightBlue':
      return theme.palette.graphs.lightBlue;
    case 'grey':
      return theme.palette.graphs.lightGrey;
    case 'lightGrey':
      return theme.palette.background.lightGrey3;
    case 'white':
      return theme.palette.text.white;
    case '':
    case null:
    case 'default':
      return theme.palette.text.primary;
    default:
      return colorKey; // return the hashcode
  }
}

export function getRabbitColorFromKey(colorKey) {
  switch (colorKey) {
    case 'primary':
    case 'blue':
      return '#E6F1FD';
    case 'red':
      return '#FAD4D4';
    case 'amber':
    case 'orange':
      return '#FFE7C5';
    case 'secondary':
      return theme.palette.secondary.main;
    case 'textPrimary':
      return theme.palette.text.primary;
    case 'textSecondary':
      return theme.palette.text.secondary;
    case 'textSecondaryLight':
      return theme.palette.text.secondaryLight;
    case 'textLabel':
      return theme.palette.text.label;
    case 'textHeading':
      return theme.palette.text.heading;
    case 'divider':
      return theme.palette.divider;
    case 'green':
      return '#DBF2D3';
    case 'darkGreen':
      return '#BFE8C6';
    case 'purple':
      return theme.palette.purple.main;
    case 'mustard':
      return theme.palette.secondary.main;
    case 'yellow':
      return theme.palette.yellow.main;
    case 'lightBlue':
      return theme.palette.graphs.lightBlue;
    case 'grey':
      return '#EAEAEA';
    case 'lightGrey':
      return '#F2F2F2';
    case 'white':
      return theme.palette.text.white;
    case '':
    case null:
    case 'default':
      return theme.palette.text.primary;
    default:
      return colorKey; // return the hashcode
  }
}

export function getRabbitKeyFromColor(color) {
  switch (color) {
    case '#E6F1FD':
      return 'blue';
    case '#FAD4D4':
      return 'red';
    case '#FFE7C5':
      return 'orange';
    case '#DBF2D3':
      return 'green';
    case '#BFE8C6':
      return 'darkGreen';
    case '#7074D5':
      return 'purple';
    case '#FFC200':
      return 'mustard';
    case '#F7D708':
      return 'yellow';
    case '#BDE9EC':
      return 'lightBlue';
    case '#EAEAEA':
      return 'grey';
    case '#F2F2F2':
      return 'lightGrey';

    default:
      return color; // return the hashcode
  }
}

export const getColorFromKeyForAnalytics = (colorKey) => {
  switch (colorKey) {
    case 'red':
      return theme.palette.red.light;
    default:
      return getColorFromKey(colorKey);
  }
};

export function getTextColorFromKey(key) {
  if (theme.palette.text[key]) return theme.palette.text[key];
  console.error('Color Key Not Defined!');
}

export function getColorCodeFromBackendColorCode(beColorCode) {
  // this is currently specific to checkins only
  switch (beColorCode) {
    case 'GREEN':
    case 'DARK_GREEN':
      return theme.palette.green.main;
    case 'GREY':
      return theme.palette.grey.main;
    case 'RED':
    case 'DARK_RED':
      return theme.palette.red.main;
    case 'BLUE':
      return theme.palette.primary.main;
    case 'ORANGE':
      return theme.palette.orange.main;
    case 'LIGHT_GREY':
      return theme.palette.grey.light;
    case 'DARK_GREY':
      return theme.palette.grey.dark;
    default:
      break;
  }
  return theme.palette.primary.main;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

// Makes everything else as smallcase and then makes the first
// character uppercase
export const capitalize = (string = '') => {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// only make the first letter uppercase
export function capitalizeShort(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function generateKey(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const pickFromObject = (obj, keys) => Object.assign({}, ...keys.map((k) => (k in obj ? { [k]: obj[k] } : {})));

export const getRouteByLoginEvent = (lastUserEvent, defaultRoute) => {
  switch (lastUserEvent) {
    case 'EMAIL_VERIFIED':
    case 'EMAIL_REGISTERED':
      return routes.home;
    case 'DEPARTMENT_REGISTERED':
      return routes.home;
    case 'TEAM_REGISTERED':
      return routes.home;
    case 'TEAM_INVITED':
      return routes.home;
    default:
      return defaultRoute;
  }
};

// Any date is nullable
export function areTwoDatesEqual(date1, date2) {
  if (!date1 && !date2) return true;
  if ((date1 && !date2) || (date2 && !date1)) return false;
  if (date1.getTime() !== date2.getTime()) return false;
  return true;
}

export function isLoggedInEmployeeFromOurTeam(loggedInEmployeeId) {
  const teamIds = {
    sakshi: '253',
    rahul: '263',
    gc: '273',
    saurabh: '283',
    gourav: '284',
    sobti: '303',
    pranav: '313',
    arun: '343',
    abhishek: '1005',
    sahil: '561',
  };
  const teamEmployeeIds = Object.values(teamIds);
  const index = teamEmployeeIds.findIndex((teamEmployeeId) => teamEmployeeId === loggedInEmployeeId);
  return index !== -1;
}

// export function getStatusColorFromDueDate (dueDate, defaultColor = 'textSecondary') {
//   if (!this.detailedStatus) return 'textSecondary';
//   switch (this.detailedStatus) {
//     case 'DELAYED':
//       return 'red';
//     default:
//       return defaultColor;
//   }
// };

export const checkIfSingleFilterApplied = (filters, type, value) => checkFilter(filters, type, value, true);

export const checkIfFilterIsPresent = (filters, type, value) => checkFilter(filters, type, value, false);

const checkFilter = (filters, type, value, checkSingle) =>
  filters &&
  filters.filter(
    (f) =>
      f.type === type && (!checkSingle || filters.filter((f2) => f2.type === type).length === 1) && f.value === value
  ).length > 0;

export function getTeamMatch(path) {
  const match = matchPath(path, {
    path: routes.managers.team,
    exact: true,
  });
  return match;
}

export function getTagPageMatch(path) {
  const match = matchPath(path, {
    path: routes.tagDetail,
    exact: true,
  });
  return match;
}

export function getProfileMatch(path) {
  const match = matchPath(path, {
    path: routes.profile,
    exact: true,
  });
  return match;
}

export function getTeamSettingsMatch(path) {
  const match = matchPath(path, {
    path: routes.teamSettings,
    exact: true,
  });
  return match;
}

export function getFeedDetailMatch(path) {
  const match = matchPath(path, {
    path: routes.feedDetail,
    exact: true,
  });
  return match;
}

export function isTaskDetailMatch(queryString) {
  const { showTaskDetails, taskIds: taskIdsString = '' } = getQueryParams(queryString);
  const taskIds = taskIdsString.split(',');
  const areTaskDetailRouteAndTaskIdsPresent =
    showTaskDetails === 'true' && Array.isArray(taskIds) && taskIds.length > 0;
  return areTaskDetailRouteAndTaskIdsPresent;
}

const isModuleSettingsPage = ({ currentPath, queryParams, module }) =>
  matchPath(currentPath, {
    path: routes.moduleSettings,
    exact: true,
  }) && queryParams?.module === module;

export function getMatchesForCurrentRoute(path, location = {}) {
  const { search = '' } = location;
  const queryParams = queryString.parse(search);

  const isHomeMatch = matchPath(path, {
    path: routes.home,
    exact: true,
  });
  const isMeshUpMatch = matchPath(path, {
    path: routes.meshup,
    exact: true,
  });
  const isMeshInMatch = matchPath(path, {
    path: routes.meshin,
    exact: true,
  });

  const isTeamMatch = Boolean(getTeamMatch(path));

  const isLifecycleMatch = matchPath(path, {
    path: '/managers/lifecycle/hire',
    exact: true,
  });

  const isTeamSummaryMatch = matchPath(path, {
    path: routes.teamSummary,
    exact: true,
  });
  // const isAdminMatch = matchPath(path, {
  //   path: routes.settings.index
  // });

  const isOldAnalyticsPath = () => {
    if (queryParams.ver === 'v0') {
      return true;
    }
    return false;
  };

  const isAnalyticsV0Match = Object.values(routes.analytics)
    .map((route) =>
      matchPath(path, {
        path: route,
        exact: false,
      })
    )
    .reduce((res, cur) => (res || cur) && isOldAnalyticsPath(), false);

  const isReviewMatch =
    isModuleSettingsPage({ currentPath: path, queryParams, module: MODULE_KEYS1.REVIEW }) ||
    SETTINGS_ROUTE_FOR_HEADER.review
      .map((route) =>
        matchPath(path, {
          path: route,
          exact: false,
        })
      )
      .reduce((res, cur) => res || cur, false);

  const isEngagementMatch =
    isModuleSettingsPage({ currentPath: path, queryParams, module: MODULE_KEYS1.ENGAGEMENT }) ||
    SETTINGS_ROUTE_FOR_HEADER.engagement
      .map((route) =>
        matchPath(path, {
          path: route,
          exact: false,
        })
      )
      .reduce((res, cur) => res || cur, false);

  const isAnalyticsMatch = matchPath(path, {
    path: routes.analytics.index,
    exact: false,
  });

  const isLevelUpMatch = SETTINGS_ROUTE_FOR_HEADER.levelUp
    .map((route) =>
      matchPath(path, {
        path: route,
        exact: false,
      })
    )
    .reduce((res, cur) => res || cur, false);

  const isFeedbackMatch = SETTINGS_ROUTE_FOR_HEADER.continuousFeedback
    .map((route) =>
      matchPath(path, {
        path: route,
        exact: false,
      })
    )
    .reduce((res, cur) => res || cur, false);

  const isUserMatch =
    matchPath(path, {
      path: routes.settings.index,
      exact: false,
    }) ||
    matchPath(path, {
      path: routes.profile,
      exact: false,
    }) ||
    matchPath(path, {
      path: routes.teamSummary,
      exact: false,
    });

  const isOneOnOneMatch =
    isModuleSettingsPage({ currentPath: path, queryParams, module: MODULE_KEYS1.ONE_ON_ONE }) ||
    SETTINGS_ROUTE_FOR_HEADER.oneOnOne
      .map((route) =>
        matchPath(path, {
          path: route,
          exact: false,
        })
      )
      .reduce((res, cur) => res || cur, false);

  const isGoalsSettingsMatch =
    isModuleSettingsPage({ currentPath: path, queryParams, module: MODULE_KEYS1.GOAL }) ||
    matchPath(path, {
      path: SETTINGS_ROUTE_FOR_HEADER.goals[0],
      exact: false,
    });

  const isInitiativesSettingsMatch =
    isModuleSettingsPage({ currentPath: path, queryParams, module: MODULE_KEYS1.INITIATIVE }) ||
    matchPath(path, {
      path: SETTINGS_ROUTE_FOR_HEADER.initiatives[0],
      exact: false,
    });

  const isPersonaInsightsMatch = SETTINGS_ROUTE_FOR_HEADER.personaInsights
    .map((route) =>
      matchPath(path, {
        path: route,
        exact: false,
      })
    )
    .reduce((res, cur) => res || cur, false);

  const isEmbeddedAnalyticsMatch = SETTINGS_ROUTE_FOR_HEADER.embeddedAnalytics
    .map((route) =>
      matchPath(path, {
        path: route,
        exact: false,
      })
    )
    .reduce((res, cur) => res || !!cur, false);

  return {
    isHomeMatch,
    isMeshUpMatch,
    isMeshInMatch,
    isTeamMatch,
    isLifecycleMatch,
    isTeamSummaryMatch,
    isReviewMatch,
    isEngagementMatch,
    isAnalyticsMatch,
    isUserMatch,
    isOneOnOneMatch,
    isLevelUpMatch,
    isGoalsSettingsMatch,
    isInitiativesSettingsMatch,
    isAnalyticsV0Match,
    isFeedbackMatch,
    isPersonaInsightsMatch,
    isEmbeddedAnalyticsMatch,
  };
}

export function getCapitalizedTeams(teams = []) {
  teams.forEach((team) => (team.name = capitalizeShort(team.name)));
  return teams;
}

export function addAllToTeams(teams = []) {
  const all = new HashTag();
  all.fromProps({ displayName: 'All', name: 'All', id: -1 });
  return [all, ...teams];
}

export function addTasksToTeams(teams = []) {
  const tasks = new HashTag();
  tasks.fromProps({ displayName: 'All Tasks', name: 'All Tasks', id: -1 });
  return [tasks, ...teams];
}

export function addGoalsToTeams(teams = []) {
  const goals = new HashTag();
  goals.fromProps({ displayName: 'Goals', name: 'Goals', id: -4 });
  return [
    ...teams.filter((team) => team.teamType !== TEAM_TYPE.personal),
    goals,
    ...teams.filter((team) => team.teamType === TEAM_TYPE.personal),
  ];
}

export function addCheckInsToTeams(teams = []) {
  const checkIns = new HashTag();
  checkIns.fromProps({ displayName: 'Check-ins', name: 'Check-ins', id: -5 });
  return [
    ...teams.filter((team) => team.teamType !== TEAM_TYPE.personal),
    checkIns,
    ...teams.filter((team) => team.teamType === TEAM_TYPE.personal),
  ];
}

export const removeAllFromTeams = (teams = []) => teams.filter((team) => team.id !== -1);

export const removeGoalsFromTeams = (teams = []) => teams.filter((team) => team.id !== -4);

export const removeCheckInsFromTeams = (teams = []) => teams.filter((team) => team.id !== -5);

export const removeEmployeeTeamsExceptPersonal = (teams) =>
  teams.filter((team) => !team.teamType || team.teamType === TEAM_TYPE.personal);
/**
 * @param teams - employee teams
 * @returns list of teams after updating the preferred and personal teams
 * - remove the existing preferred/personal teams from the list (if any)
 * - add the preferred(-2) and personal(-3) teams to the list
 */
export const updatePreferredAndPersonal = (teams) => {
  const res = teams.filter((t) => t.teamType !== TEAM_TYPE.preferred && t.teamType !== TEAM_TYPE.personal);
  const preferred = new HashTag();
  const personal = new HashTag();
  preferred.fromProps({
    displayName: 'Preferred',
    name: 'Preferred',
    id: '-2',
  });
  personal.fromProps({ displayName: 'Personal', name: 'Personal', id: '-3' });
  return [...res, preferred, personal];
};

/**
 * @param teams - employee teams
 * @param tagType - ORGTAG/TEAM
 * @param tagId - {id} of {tagType}
 * @returns the team object of the current page team
 */
export const getCurrentTeamId = ({ task, tagId, tagType }) => {
  const taskTeamId = task.teamId;
  if (tagType === SEARCH_TAG_TYPES.team) {
    return taskTeamId || tagId;
  } // if it's a tag page, then we want to select the id of selected employee's first team if not taskTeamId is present
  if (tagType === SEARCH_TAG_TYPES.tag) {
    return taskTeamId || (task.to && task.to.teams) ? task.to.teams[0].id : null;
  }
};

/**
 * @param teams - employee teams
 * @param tagType - ORGTAG/TEAM
 * @param tagId - {id} of {tagType}
 * @returns the team object of the current page team (if its the 'team' page and is not preferred/personal since that wont be added to the team options)
 */
export const getCurrentTeamIfNotPrefferedOrPersonal = (teams, tagId, tagType) => {
  if (tagType === SEARCH_TAG_TYPES.team && tagId) {
    return teams.filter(
      (t) =>
        t.id.toString() === tagId.toString() && t.teamType !== TEAM_TYPE.preferred && t.teamType !== TEAM_TYPE.personal
    )[0];
  }
  return null;
};

/**
 * @param task - current task
 * @param teamCurrent (nullable) - current team object (if its the 'team' page)
 * @returns the list of relevant team options
 * [currentTeam, taskTeam, preferred(-2), personal(-3)]
 */
export const addPreferredAndPersonalToTaskTeam = (task, teamCurrent) => {
  let res = [];
  const preferred = new HashTag();
  const personal = new HashTag();
  const taskTeam = new HashTag();
  preferred.fromProps({
    displayName: 'Preferred',
    name: 'Preferred',
    id: '-2',
  });
  personal.fromProps({ displayName: 'Personal', name: 'Personal', id: '-3' });
  taskTeam.fromProps({ displayName: '', name: task.teamName, id: task.teamId });
  if (taskTeam.id && taskTeam.name) {
    res = [taskTeam, preferred, personal];
  } else {
    res = [preferred, personal];
  }
  // add current team in the list (team page)
  if (teamCurrent) {
    res = [teamCurrent, ...res];
  }
  return res.filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id));
};

/**
 * @param list - list of days (to be sorted in order)
 * @returns the sorted list of the days in the format (Mon, Tue etc.)
 */
export const daysSorter = (list) => {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return list
    .sort((a, b) => daysOfWeek.indexOf(a.toProperCase()) - daysOfWeek.indexOf(b.toProperCase()))
    .map((a) => a.substr(0, 3).toProperCase());
};

export const isPersonalTeamExists = (teams) => teams.filter((team) => team.teamType === TEAM_TYPE.personal).length > 0;

export const getMeasurementSymbol = (measurement) => {
  try {
    return GOAL_MEASUREMENT_TYPES.filter((m) => m.value === measurement)[0].label;
  } catch (e) {
    return measurement;
  }
};

export const addSymbolToMeasurementValue = (measurement, number) => {
  if (!measurement || !number) {
    return '-';
  }
  const symbol = getMeasurementSymbol(measurement);
  switch (measurement) {
    case 'PERCENTAGE':
      return number + symbol;
    default:
      return symbol + new Intl.NumberFormat().format(number);
  }
};

export const indianFormattedNumber = (number) => new Intl.NumberFormat('en-IN').format(number);
export const foreignFormattedNumber = (number) => new Intl.NumberFormat('en-US').format(number);

export const isNumberAndNonNull = (value) => value !== null && !Number.isNaN(value) && value !== 0;

export const isNumber = (value) => Number(value).toString() === value;

export const isNumeric = (value) => !Number.isNaN(value - parseFloat(value));

export const getAddText = (type, selectedTeam, tagType, tagId) => {
  if (type === TASK_TYPES_2.goal) {
    return ADD_TEXT.goal;
  }
  if (selectedTeam && (selectedTeam.teamType === TEAM_TYPE.personal || selectedTeam.name === 'Personal')) {
    return ADD_TEXT.todo;
  }

  return ADD_TEXT.task;
};

export const removePendingAndEmployeeTeamsFromOrg = (orgTeams, employeeTeams, teamInvitations) =>
  orgTeams.filter(
    (t) =>
      employeeTeams.filter((et) => et.id === t.id).length === 0 &&
      teamInvitations.filter((i) => i.team.id === t.id).length === 0
  );

export const generateStatusOptions = (task) => {
  switch (task.getTaskStatus()) {
    // if on-going: disable delayed
    case TASK_STATUS.ongoing.value:
      return [
        TASK_STATUS.ongoing,
        { ...TASK_STATUS.delayed, isDisabled: true },
        { ...TASK_STATUS.unplanned, isDisabled: !!task.isRecurrent() },
        TASK_STATUS.completed,
      ];
      break;
    // if delayed: disable ongoing
    case TASK_STATUS.delayed.value:
      return [
        { ...TASK_STATUS.ongoing, isDisabled: true },
        TASK_STATUS.delayed,
        { ...TASK_STATUS.unplanned, isDisabled: !!task.isRecurrent() },
        TASK_STATUS.completed,
      ];
      break;
    // if unplanned: disable delayed and on-going
    case TASK_STATUS.unplanned.value:
      return [
        { ...TASK_STATUS.ongoing, isDisabled: true },
        { ...TASK_STATUS.delayed, isDisabled: true },
        TASK_STATUS.unplanned,
        TASK_STATUS.completed,
      ];
    // if duedate is present and completed with delay : disable ongoing and unplalnned
    // if duedate is present and completed on time : disable delayed and unplalnned
    // if duedate is not present : disable ongoing and delayed
    case TASK_STATUS.completed.value:
      if (task.dueDate) {
        if (task.detailedStatus === TASK_DETAILED_STATUS.completedWithDelay) {
          return [
            { ...TASK_STATUS.ongoing, isDisabled: true },
            TASK_STATUS.delayed,
            { ...TASK_STATUS.unplanned, isDisabled: true },
            TASK_STATUS.completed,
          ];
        }
        return [
          TASK_STATUS.ongoing,
          { ...TASK_STATUS.delayed, isDisabled: true },
          { ...TASK_STATUS.unplanned, isDisabled: true },
          TASK_STATUS.completed,
        ];
      }
      return [
        { ...TASK_STATUS.ongoing, isDisabled: true },
        { ...TASK_STATUS.delayed, isDisabled: true },
        TASK_STATUS.unplanned,
        TASK_STATUS.completed,
      ];
  }
  return [];
};

export function isSentryEnabled() {
  return process.env.PUBLIC_APP_ENABLE_SENTRY === 'true';
}

export function isProductionServer() {
  return process.env.NODE_ENV === 'production' && process.env.PUBLIC_APP_SERVER === 'now';
}

export const getGoalProgressColor = (status) => {
  if (status === 'DELAYED') {
    return 'red';
  }
  if (status === 'ONGOING') {
    return 'orange';
  }
  if (status && status.indexOf('COMPLETED') > -1) {
    return 'green';
  }
};

/* return true if the client is using a mobile device. */
export const isMobileDevice = (() => {
  const UA = navigator.userAgent;
  const isMobile =
    /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);

  return () => isMobile;
})();

export const mobileUpMediaQuery = (theme) => theme.breakpoints.up('sm');
export const mobileDownMediaQuery = (theme) => theme.breakpoints.down('xs');
export const mediumDesktopUpMediaQuery = (theme) => theme.breakpoints.up('lg1');

export const createTrophyObject = (summaryType, imageUrl, count) => {
  const trophy = new HashTag();
  trophy.fromJSON({
    name: summaryType.toProperCase(),
    displayName: summaryType.toProperCase(),
    imageUrl,
  });
  trophy.stats = { score: count };
  trophy.summaryType = TRANSACTION_SUMMARY_TYPES[summaryType];
  return trophy;
};

// used for handle click outside, if date picker is clicked
// we don't want to close the dropdown

// THIS DOESN'T WORK AS THE CALENDER IN REMOVED FROM DOM BEFORE CONTROL REACHES HERE.
// TO BE FIXED
export function isDatePickerClicked(event) {
  try {
    // this is a hack, we are just selecting element based on role = dialog
    // there can be other dialogs as well
    const dialog = document.querySelector("div[role='dialog']");
    const isDatePickerClicked = dialog && dialog.contains(event.target);
    return isDatePickerClicked;
  } catch (err) {
    return false;
  }
}

// in case a disappearing element was clicked, it could go away from the DOM till the control reaches here
// this is not perfect solution but, if the clicked item is not in DOM anymore,
// then most likely user interacted with some dropdown or calendar, so this does not count as valid case for clickaway listener
export function isTransientElementClicked(event) {
  return !document.contains(event.target);
}

export function isObjectiveModalClicked(event) {
  try {
    // this is a hack, we are just selecting element based on role = dialog
    // there can be other dialogs as well
    const dialog = document.querySelector("div[role='objective-dialog']");
    const isObjectiveModalClicked = dialog && dialog.contains(event.target);
    return isObjectiveModalClicked;
  } catch (err) {
    return false;
  }
}
export function isRealignModalClicked(event) {
  try {
    // this is a hack, we are just selecting element based on role = dialog
    // there can be other dialogs as well
    const dialog = document.querySelector("div[role='realign-modal']");
    const isRealignModalClicked = dialog && dialog.contains(event.target);
    return isRealignModalClicked;
  } catch (err) {
    console.error('Error getting realign modal from DOM : ', err);
    return false;
  }
}

export const isNull = (x) => x === null;
export const isUndefined = (x) => x === undefined;

export const notNullOrUndefined = (x) => !isNull(x) && !isUndefined(x);

export const isSpecificOrgUser = ({ orgId, org }) => orgId && org && ORG_ID[org].indexOf(Number(orgId)) > -1;

export const removeNull = (obj) =>
  Object.entries(obj)
    .filter(([_, v]) => v !== null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const getCurrencyDetails = (currencyCode = null) => {
  let currencyKey = 'INR'; // for default case - rupee
  if (currencyCode) {
    currencyKey = currencyCode;
  }
  return GOAL_MEASUREMENT_CURRENCIES[currencyKey];
};

export const getOrgGoalMeasurementTypes = (currencyCode = null) => {
  const currencyDetails = getCurrencyDetails(currencyCode);
  // For backward compatibility: In measurement type, the selected type should be 'CURRENCY'
  return [{ ...currencyDetails, code: 'CURRENCY' }, ...GOAL_MEASUREMENT_TYPES.slice(1)];
};

export const downloadOrOpenFile = (path) => {
  const link = document.createElement('a');
  link.href = path;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFeedbackFormRoute = ({
  formType,
  feedbackId,
  show = false,
  filling = false,
  list = false,
  newForm = false,
  typeMapped = false,
  defaultTab = 0, // In case we want to change the Nav from List View in FeedbackListSiddepanel
  empId,
  promptGenderKey = null,
  nudgeId = null,
}) => {
  // typeMapping was needed only for Showing Provided and Shared. ENUM received from ws are only two -> [PROVIDED, REQUESTED]
  // We needed 5 types over here -> FEEDBACK_FORM_TYPE
  const mappedFormType = () => {
    if (typeMapped) {
      return formType;
    }
    return getMappedFeedbackFormType({ formType, show, filling, list, newForm });
  };
  const params = () => {
    if (feedbackId)
      return `?formType=${mappedFormType()}&feedbackId=${feedbackId}&showFeedback=true${
        formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
      }`;
    if (empId) {
      return `?formType=${mappedFormType()}&showFeedback=true${
        formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
      }${empId ? `&empId=${empId}` : ''}`;
    }
    return `?formType=${mappedFormType()}&showFeedback=true${
      formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
    }`;
  };

  let paramsString = params();
  if (promptGenderKey) {
    paramsString = `${paramsString}&promptGenderKey=${promptGenderKey}&nudgeId=${nudgeId}`;
  }

  return paramsString;
};

export const pushFeedbackParams = ({
  history,
  formType,
  feedbackId,
  show = false,
  filling = false,
  list = false,
  newForm = false,
  typeMapped = false,
  defaultTab = 0, // In case we want to change the Nav from List View in FeedbackListSiddepanel
  empId,
  promptGenderKey = null,
  nudgeId = null,
  feedbackType = null,
  competencyId,
  isSharePublicPraise = false,
  source,
  selectedFeedbackView,
}) => {
  // typeMapping was needed only for Showing Provided and Shared. ENUM received from ws are only two -> [PROVIDED, REQUESTED]
  // We needed 5 types over here -> FEEDBACK_FORM_TYPE
  const mappedFormType = () => {
    if (typeMapped) {
      return formType;
    }
    return getMappedFeedbackFormType({ formType, show, filling, list, newForm });
  };
  const params = () => {
    if (feedbackId)
      return `?formType=${mappedFormType()}&feedbackId=${feedbackId}&showFeedback=true${
        formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
      }${source ? `&source=${source}` : ''}${selectedFeedbackView ? `&feedbackView=${selectedFeedbackView}` : ''}`;
    if (empId) {
      return `?formType=${mappedFormType()}&showFeedback=true${
        formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
      }${empId ? `&empId=${empId}` : ''}${feedbackType ? `&feedbackType=${feedbackType}` : ''}${
        competencyId ? `&competencyId=${competencyId}` : ''
      }${source ? `&source=${source}` : ''}${selectedFeedbackView ? `&feedbackView=${selectedFeedbackView}` : ''}`;
    }
    return `?formType=${mappedFormType()}&showFeedback=true${
      formType === FEEDBACK_FORM_TYPE.LIST_FEEDBACKS ? `&tab=${defaultTab}` : ''
    }${feedbackType ? `&feedbackType=${feedbackType}` : ''}${source ? `&source=${source}` : ''}${
      selectedFeedbackView ? `&feedbackView=${selectedFeedbackView}` : ''
    }`;
  };

  let paramsString = params();
  if (promptGenderKey) {
    paramsString = `${paramsString}&promptGenderKey=${promptGenderKey}&nudgeId=${nudgeId}`;
  }

  if (isSharePublicPraise) {
    paramsString = `${paramsString}&isSharePublicPraise=${isSharePublicPraise}`;
  }

  history.push({
    search: paramsString,
    state: [{ from: history.location }],
  });
};

export const pushBackFeedbackParams = (history) => {
  if (history.location.state && history.location.state?.length) {
    history.push({
      pathname: history.location.state[0].from.pathname,
      search: history.location.state[0].from.search,
    });
  } else {
    history.push({
      pathname: history.location.pathname,
    });
  }
};

export const getMappedFeedbackFormType = ({
  formType,
  show = false,
  filling = false,
  list = false,
  newForm = false,
}) => {
  if (show) {
    if (formType === FEEDBACK_FORM_TYPE.PROVIDED) return FEEDBACK_FORM_TYPE.SHOW_PROVIDED;
    if (formType === FEEDBACK_FORM_TYPE.REQUESTED) return FEEDBACK_FORM_TYPE.SHOW_REQUESTED;
    if (formType === FEEDBACK_FORM_TYPE.SHOW_REQUESTED) return FEEDBACK_FORM_TYPE.SHOW_REQUESTED;
    if (formType === FEEDBACK_FORM_TYPE.SHOW_RECEIVED) return FEEDBACK_FORM_TYPE.SHOW_RECEIVED;
    if (formType === FEEDBACK_FORM_TYPE.SHOW_PROVIDED) return FEEDBACK_FORM_TYPE.SHOW_PROVIDED;
  }
  if (filling) {
    return FEEDBACK_FORM_TYPE.PROVIDING_REQUESTED;
  }
  if (list) return FEEDBACK_FORM_TYPE.LIST_FEEDBACKS;
  if (newForm) {
    return formType;
  }
  return null;
};

/**
 * @param filters - [{filterDetailId, value, operator, type}]
 * @param filtersMeta - [{id, uiType, displayName, values}]
 * returns formated filters object {[displayName]: [values]}
 */
export const formatPluginFiltersToObject = (filters, filtersMeta) => {
  const res = {};
  for (const filter of filters) {
    const selected = filtersMeta.filter((f) => f.id === filter.filterDetailId)[0];
    let value = [];
    switch (selected.uiType) {
      case 'DATE':
      case 'AMOUNT':
        const existing = res[selected.displayName];
        if (filter.operator === '>=') {
          value = [filter.value, existing ? existing[1] : null];
        } else {
          value = [existing ? existing[0] : null, filter.value];
        }
        break;
      case 'COMMA_SEPARATED_VALUES':
        value = filter.value
          .split(',')
          .map((v) => v.trim())
          .map((v) => v.replace(/'/g, ''));
        break;
      default:
        value = filter.value.split(',').map((v) => v.trim());
        break;
    }
    res[selected.displayName] = value;
  }
  return res;
};

/**
 * @param filters object {[displayName]: [values]}
 * @param filtersMeta - [{id, uiType, displayName, values}]
 * returns formated filters array [{filterDetailId, value, operator, type}]
 */
export const formatPluginFiltersToArray = (filters, filtersMeta) => {
  const res = [];
  for (const [displayName, values] of Object.entries(filters)) {
    if (!values.length) {
      continue;
    }
    const selected = filtersMeta.filter((f) => f.displayName === displayName)[0];
    const filterDetailId = selected.id;
    switch (selected.uiType) {
      case 'DATE':
      case 'AMOUNT':
        if (values[0]) {
          res.push({
            filterDetailId,
            value: values[0],
            operator: '>=',
          });
        }
        if (values[1]) {
          res.push({
            filterDetailId,
            value: values[1],
            operator: '<=',
          });
        }
        break;
      case 'COMMA_SEPARATED_VALUES':
        res.push({
          filterDetailId,
          value: values.map((v) => `'${v}'`).join(', '),
          operator: '=',
        });
        break;
      default:
        res.push({
          filterDetailId,
          value: values.join(', '),
          operator: '=',
        });
        break;
    }
  }
  return res;
};

/* checkin helper functions */

export const getDownloadFileLink = (checkInId, isRecurringType = false) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (checkInId) {
    return `${endpoints.checkIn.generateCalendar}?taskId=${checkInId}&timezone=${timezone}&isRecurringType=${isRecurringType}`;
  }
  return '';
};

export const getMultiSelectOptionsText = (min, max, numberOfOptions) => {
  if (min === 0 && max === numberOfOptions) {
    return 'Select any number of options';
  }
  if (min !== 0 && max === numberOfOptions) {
    return `Select minimum ${min} options`;
  }
  if (min === 0 && max !== numberOfOptions) {
    return `Select upto ${max} options`;
  }
  if (min === max) {
    return `Select ${max} options`;
  }
  return `Select minimum ${min} and maximum ${max} options`;
};

// tracking helpers

export const findMatchObj = (pathname) => {
  const routesObjArray = Object.values(routesForTracking);

  for (let i = 0; i < routesObjArray.length; i++) {
    const { path, pageName } = routesObjArray[i];

    const match = matchPath(pathname, {
      path,
      exact: true,
    });

    if (match) {
      return { match, pageName };
    }
  }
  return null;
};

export const matchModuleFromRoute = (pathname) => {
  for (const moduleObj of SIDE_NAV_MATCH_ROUTES) {
    for (const path of moduleObj.routes) {
      const match = matchPath(pathname, {
        path,
        exact: true,
      });

      if (match) {
        return moduleObj.module;
      }
    }
  }
  return null;
};

export const getMatchPathIndexFromRoutesArray = ({ pathname, routesArray }) => {
  const matchingIndex = routesArray.findIndex((route) => {
    const match = matchPath(pathname, {
      path: route,
      exact: true,
    });
    return !!match;
  });
  return matchingIndex;
};

export const getPageName = (location) => {
  const { pathname } = location;

  const matchedRouteObj = findMatchObj(pathname);

  return matchedRouteObj?.pageName || '';
};

export function isPdfType(url) {
  return url.slice(url.length - 3).toLowerCase() === 'pdf';
}

export function getFileNameFromUrl(url) {
  const splitUrl = url.split('/');
  return splitUrl[splitUrl.length - 1];
}

export function truncateString(str, maxLength = 20) {
  if (typeof str === 'string' && str.length > maxLength) {
    return `${str.substr(0, maxLength)}...`;
  }
  return str;
}

export const getFiltersFromLS = (res, filterKey) => {
  try {
    const filtersInLS = JSON.parse(window.localStorage.getItem(filterKey));
    if (filtersInLS) {
      let cohortFromLS;
      let groupByFromLS;
      let sourceFromLS;
      let splitByFromLS;

      if (res.cohort) {
        cohortFromLS = res.cohorts.find((co) => co.id === filtersInLS[ANALYTICS_FILTERS_KEYS.cohortId]);
      }
      if (res.groupByKeys) {
        groupByFromLS = res.groupByKeys.find(
          (group) => group.keyName === filtersInLS[ANALYTICS_FILTERS_KEYS.groupByKey]
        );
      }

      if (res.sources) {
        sourceFromLS = res.sources.find((s) => s.uuid === filtersInLS[ANALYTICS_FILTERS_KEYS.sourceUuid]);
      }

      if (res.splitByKeys) {
        splitByFromLS = res.splitByKeys.find(
          (splitBy) => splitBy.keyName === filtersInLS[ANALYTICS_FILTERS_KEYS.splitByKey]
        );
      }
      const timeBoundPeriodsFromLS = filtersInLS[ANALYTICS_FILTERS_KEYS.timeBounds];

      const reviewViewTypeFromLS = filtersInLS[ANALYTICS_FILTERS_KEYS.reviewViewType];
      const dataInLS = {
        cohortFromLS,
        groupByFromLS,
        timeBoundPeriodsFromLS,
        sourceFromLS,
        reviewViewTypeFromLS,
        splitByFromLS,
      };
      return dataInLS;
    }
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const setFiltersToLS = (lsFilterKey, objectKey, objectValue) => {
  try {
    const existingData = JSON.parse(window.localStorage.getItem(lsFilterKey)) ?? {};
    const modifiedData = JSON.stringify({ ...existingData, [objectKey]: objectValue });
    window.localStorage.setItem(lsFilterKey, modifiedData);
  } catch (e) {
    console.error('Error in localstorage: ', e);
  }
};

export const compareDateRangeWithPredefinedDateRanges = (
  dateRange,
  predefinedDateRanges = [
    lastMonth,
    lastQuarter,
    lastTwoMonths,
    lastTwoQuarters,
    thisMonth,
    thisQuarter,
    thisYear,
    past6Months,
    past12Months,
  ]
) => {
  const { startDate, endDate } = dateRange;
  let userSelectedDateRange = null;
  userSelectedDateRange = predefinedDateRanges.find(
    (predefinedDate) => isEqual(predefinedDate.startDate, startDate) && isEqual(predefinedDate.endDate, endDate)
  );
  // if  date doesn't match with any of the predefinedDateRanges
  userSelectedDateRange = userSelectedDateRange ?? dateRange;
  return userSelectedDateRange;
};

export const compareDateRangeWithPredefinedDateRangesCopy = (
  dateRange,
  predefinedDateRanges = [
    lastMonth,
    lastQuarter,
    lastTwoMonths,
    lastTwoQuarters,
    thisMonth,
    thisQuarter,
    thisYear,
    past6Months,
    past12Months,
  ]
) => {
  const { startDate, endDate } = dateRange;
  let userSelectedDateRange = null;
  userSelectedDateRange = predefinedDateRanges.find(
    (predefinedDate) => isEqual(predefinedDate.startDate, startDate) && isEqual(predefinedDate.endDate, endDate)
  );
  // if  date doesn't match with any of the predefinedDateRanges
  userSelectedDateRange = userSelectedDateRange
    ? {
        startDate: userSelectedDateRange?.startDate?.valueOf(),
        endDate: userSelectedDateRange?.endDate?.valueOf(),
        label: userSelectedDateRange?.label,
      }
    : dateRange;
  return userSelectedDateRange;
};

export const getDateRangeFromLS = (filterKey) => {
  try {
    const { dateRange } = JSON.parse(window.localStorage.getItem(filterKey)) ?? {};
    if (dateRange) {
      return compareDateRangeWithPredefinedDateRanges({
        startDate: new Date(dateRange.startTS),
        endDate: new Date(dateRange.endTS),
      });
    }
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const getDateRangeFromLSForEng = (filterKey) => {
  const isDateObj = (date) => {
    // quickest fix I've done in my life!! need a better approach
    if (date instanceof Date && !Number.isNaN(date)) {
      return true;
    }
    return false;
  };
  try {
    const { dateRange } = JSON.parse(window.localStorage.getItem(filterKey));
    if (dateRange) {
      const comparedDataRange = compareDateRangeWithPredefinedDateRanges({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
      return {
        startDate: !isDateObj(comparedDataRange.startDate)
          ? comparedDataRange.startDate
          : comparedDataRange.startDate?.valueOf(),
        endDate: !isDateObj(comparedDataRange.endDate)
          ? comparedDataRange.endDate
          : comparedDataRange.endDate?.valueOf(),
      };
    }
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const getDateRangeFromLSBasedOnEndDateStartDate = (filterKey) => {
  try {
    const { dateRange } = JSON.parse(window.localStorage.getItem(filterKey)) ?? {};
    if (dateRange) {
      return compareDateRangeWithPredefinedDateRangesCopy({
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });
    }
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const getTimeBoundPeriodFromLS = (filterKey) => {
  try {
    const { timeBounds } = JSON.parse(window.localStorage.getItem(filterKey));
    return timeBounds || [];
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const isTimeBoundPeriodValid = (list = [], timeBoundPeriodToTest) =>
  list.some((timeBoundPeriod) => timeBoundPeriod.timelineTag === timeBoundPeriodToTest?.timelineTag);

export const createNewCohort = (allCohorts, payload) => {
  let newCohortList = allCohorts;
  const matchedCohort = allCohorts.filter((cohort) => cohort.name === payload.cohort.name);

  if (matchedCohort.length > 0) {
    newCohortList = allCohorts.map((cohort) => (cohort.name === payload.cohort.name ? payload.cohort : cohort));
  } else {
    newCohortList = [...allCohorts, payload.cohort];
  }

  newCohortList = newCohortList.sort((objA, objB) => {
    if (String(objA.name).toLowerCase() === 'last used') return -1;

    if (String(objB.name).toLowerCase() === 'last used') return 1;

    return 0;
  });
  return newCohortList;
};
export const roundOffToNearestMultiple = (num, multiple) =>
  // this rounds off num to nearest multiple of "multiple"
  // eg num = 8 , mul = 5, ans = 10
  // both the numbers should be whole numbers
  // no decimals allowed
  Math.ceil(num / multiple) * multiple;

export const generateTicksIncrementMultiple = (maxNumber) => {
  if (maxNumber >= 0 && maxNumber <= 25) {
    return 5;
  }
  if (maxNumber > 25 && maxNumber <= 50) {
    return 10;
  }
  if (maxNumber > 50 && maxNumber <= 75) {
    return 15;
  }
  if (maxNumber > 75 && maxNumber <= 100) {
    return 20;
  }
  if (maxNumber > 100) {
    return 25;
  }
};

export const makeTicksArray = (maxNumber, multiple) => {
  const nearestMultiple = roundOffToNearestMultiple(maxNumber, multiple);
  const ticksArray = [];
  for (let i = 0; i <= nearestMultiple / multiple; ++i) {
    ticksArray.push(i * multiple);
  }
  return ticksArray;
};

// Swaps two elements in an array
export const swapElementsInArray = (arr, i, j) => {
  const t = arr[i];
  arr[i] = arr[j];
  arr[j] = t;
  return arr;
};

export const insertDisplayOrderInArray = (arr) =>
  arr.map((item, idx) => ({
    ...item,
    displayOrder: idx + 1,
  }));

export const sendAnalyticsEmail = (email, tableType, body = '') => {
  if (email && tableType) {
    const mailSubject = encodeURIComponent(ANALYTICS_EMAIL_SUBJECTS[tableType].emailSubject);
    const mailBody = encodeURIComponent(body);
    const mail = document.createElement('a');
    mail.href = `mailto:${email}?subject=${mailSubject}&body=${mailBody}`;
    mail.target = '_blank';
    mail.click();
  }
};

// Generates Default Rating Options for Rating Settings in ReviewCycle
export const generateDefaultRatingOptions = (feedbackFormId) =>
  [20, 40, 60, 80, 100].map((item, idx) =>
    AutoComputeGoalRatingSettings.fromJSON({ feedbackFormId, upperLimit: item, rating: idx + 1 })
  );

// following method checks for if given properties values is same for two objects.
//  this only compares shallowly
export const haveSamePropValues = (obj1, obj2, propertiesList) =>
  propertiesList.filter((property) => obj1[property] !== obj2[property]).length === 0;

export const noOp = () => {};

export const dummyAPI = ({
  waitInMS = 1000,
  failureChance = 50,
  successReturnObj = 'resolved',
  failureReturnObj = 'rejected',
}) =>
  new Promise((resolve, reject) =>
    setTimeout(() => {
      if (Math.random() * 100 >= failureChance) resolve(successReturnObj);
      else reject(failureReturnObj);
    }, waitInMS)
  );

// Inserts an element in Array at a given position.
export const insertAfterGivenPosition = (array, position, element) => {
  if (!Number.isNaN(position)) {
    array.splice(position + 1, 0, element);
    return array;
  }
  return [...array, element];
};

export const getSelectedReviewCycleFromLS = (filterKey) => {
  try {
    const reviewCycle = JSON.parse(localStorage.getItem(filterKey));
    return reviewCycle;
  } catch (e) {
    console.error('Error in reading LS: ', e);
  }
};

export const makeCommaSeparatedString = (arr, useOxfordComma) => {
  const listStart = arr.slice(0, -1).join(', ');
  const listEnd = arr.slice(-1);
  const conjunction = arr.length <= 1 ? '' : useOxfordComma && arr.length > 2 ? ', and ' : ' and ';

  return [listStart, listEnd].join(conjunction);
};

// using this because only this could be an actually unique combination to correctly identify time periods uniquely
export const getTimePeriodIdString = (timeBound) =>
  timeBound ? timeBound.timelineTag + timeBound.startTS + timeBound.endTS : '';

export function hyperlinkUrlsInText(text) {
  const urlRegex = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;

  return text.replace(urlRegex, (url) => {
    console.log(url);
    return `<a target="_blank" href="${url}">${url}</a>`;
  });
}

/**
 *
 * @param {Object} obj - e.g. { a: 1, b: 2, c: 3 }
 * @returns {Object} - e.g. { 1: a, 2: b, 3: c }
 * Flips the obj w.r.t. to its key & value pairs, i.e. keys becomes values & values become keys.
 */
export const objectFlip = (obj) => {
  const flippedObj = {};
  Object.keys(obj).forEach((key) => {
    flippedObj[obj[key]] = key;
  });
  return flippedObj;
};

export function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

// eg1: snake_case -> Snake Case
// eg2: SNAKE_CASE -> Snake Case
export const snakeCaseToTitleCase = (string = '') => {
  string = string || '';
  return string
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
};

/** Rabbit Route Helpers */
export const getEncodedCohortQueryParam = (cohort, location, keepOtherParams = false, additionalQueryParams = {}) => {
  let queryParams = getQueryParams(location.search);
  queryParams = { ...queryParams, ...additionalQueryParams };
  let cohortQueryParams = {};

  if (cohort) {
    cohortQueryParams.id = cohort.id;
    cohortQueryParams.name = cohort.name;
    cohortQueryParams.type = cohort.type;

    if (cohort.baseCohortId) {
      cohortQueryParams.baseCohortId = cohort.baseCohortId;
    }
  }
  cohortQueryParams = { ...cohortQueryParams, ...additionalQueryParams };

  return queryString.stringify(
    { ...(keepOtherParams ? queryParams : {}), cohort: JSON.stringify(cohortQueryParams) },
    { strict: false }
  );
};

export const goToPage = (history, pathname, searchString) => {
  history.push({
    pathname,
    search: searchString,
  });
};

export function isNotNull(object) {
  return object !== null && object !== undefined;
}

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const findAndReplaceString = (str, replacedStr, pattern = 'key result') => {
  if (str.includes(pattern)) {
    const repacedStr = str.replaceAll(pattern, replacedStr);
    return repacedStr;
  }
  return str;
};

export const startsWithVowel = (word) => /[aeiou]/i.test(word[0]);

export const isMobileView = () => window.innerWidth < 800;
export const showOnlyTableForMobileView = window.innerWidth <= 600;

/* access control helpers */
export const getCreatableObjectiveLevelList = (accessPrivilegesList = []) => {
  const objectiveLevels = [];
  if (accessPrivilegesList.includes(CREATE_OBJECTIVE_PRIVILEGES.CREATE_OBJECTIVE_COMPANY)) {
    objectiveLevels.push('COMPANY');
  }

  if (accessPrivilegesList.includes(CREATE_OBJECTIVE_PRIVILEGES.CREATE_OBJECTIVE_DEPARTMENT)) {
    objectiveLevels.push('DEPARTMENT');
  }

  if (accessPrivilegesList.includes(CREATE_OBJECTIVE_PRIVILEGES.CREATE_OBJECTIVE_TEAM)) {
    objectiveLevels.push('TEAM');
  }

  if (accessPrivilegesList.includes(CREATE_OBJECTIVE_PRIVILEGES.CREATE_OBJECTIVE_INDIVIDUAL)) {
    objectiveLevels.push('INDIVIDUAL');
  }

  return objectiveLevels;
};
export const getBooleanValue = (val) => {
  const num = +val;
  return !Number.isNaN(num) ? !!num : !!String(val).toLowerCase().replace(!!0, '');
};

export const convertToDateTimeString = (date) => (date ? format(new Date(date), "MMM dd, yyyy 'at' hh:mm a") : '');

export const getParsedPanelStack = ({ location }) => {
  const queryParams = queryString.parse(location.search);

  const { panelStack } = queryParams;

  let parsedPanelStack = [];
  try {
    if (panelStack) {
      parsedPanelStack = JSON.parse(panelStack);
    }
  } catch (err) {
    console.error('failed to parse panelStack query param. using empty []');
  }

  return parsedPanelStack;
};

export const getPanelStackRouteUntilIndex = ({ location, index }) => {
  const queryParams = queryString.parse(location.search);

  const { panelStack: previousPanelStack, ...restQueryParams } = queryParams;

  let parsedPreviousPanelStack = [];
  try {
    if (previousPanelStack) {
      parsedPreviousPanelStack = JSON.parse(previousPanelStack);
    }
  } catch (err) {
    console.error('failed to parse panelStack query param. using empty []');
  }
  const parsedPreviousStackUntilIndex = parsedPreviousPanelStack.slice(0, index + 1);

  return queryString.stringify(
    {
      ...restQueryParams,
      panelStack: JSON.stringify(parsedPreviousStackUntilIndex),
    },
    { strict: false }
  );
};

export const getEncodedPanelStack = ({
  panelStack = [],
  id = null,
  type = 'GOAL',
  objectivePId = null,
  otherQueryParams = {},
}) => {
  const panelStackObj = {
    drawerType: objectivePId ? 'OBJECTIVE' : type,
    params: objectivePId
      ? {
          objectivePId,
        }
      : {
          id,
          type,
          showTaskDetails: true,
        },
  };

  return queryString.stringify(
    {
      ...otherQueryParams,
      panelStack: JSON.stringify([...panelStack, panelStackObj]),
    },
    { strict: false, skipNull: true }
  );
};

export const stringifyObj = (obj) =>
  queryString.stringify(
    {
      ...obj,
    },
    {
      skipNull: true,
      strict: false,
    }
  );

export const getSearchStringFromLatestPanelStack = ({ location }) => {
  const panelStack = getParsedPanelStack({ location });
  const otherQueryParams = getQueryParams(location.search);
  const latestPanelStackObject = panelStack.slice(-1)?.[0];

  const panelStackArrayExceptLastObj = panelStack.slice(0, panelStack.length - 1);

  const { drawerType, params } = latestPanelStackObject;

  const okrQueryParamObj = {
    objectivePId: null,
    ids: null,
    showTaskDetails: null,
    type: null,
    empId: null,
  };

  if (drawerType === 'OBJECTIVE') {
    okrQueryParamObj.objectivePId = params?.objectivePId;
  } else if (drawerType === 'COMPETENCY') {
    okrQueryParamObj.empId = otherQueryParams?.empId;
    okrQueryParamObj.isCompetencyPanelOpen = 'true';
  } else {
    okrQueryParamObj.ids = params?.id;
    okrQueryParamObj.showTaskDetails = true;
    okrQueryParamObj.type = params?.type;
  }

  const searchString = stringifyObj({
    ...otherQueryParams,
    ...okrQueryParamObj,
    panelStack:
      Array.isArray(panelStackArrayExceptLastObj) && panelStackArrayExceptLastObj.length > 0
        ? JSON.stringify(panelStackArrayExceptLastObj)
        : null,
  });

  return searchString;
};

const meshUserDataPersistenceKeyPrefix = 'mesh_up_'; // meshUserPreferences
export const getPersistenceKey = ({ moduleName, viewId, tabId, uniqueId, uniqueId2 = '', delimiter = '|' }) => {
  const userId = selectLoggedInEmployeeId(store.getState());

  const prefixedUserId = meshUserDataPersistenceKeyPrefix + userId;

  /* 
    The key format: "userId|moduleName|viewId|tabId|uniqueId"
  */
  return [prefixedUserId, moduleName, viewId ?? null, tabId ?? null, uniqueId, uniqueId2].join(delimiter);
};

/* Have added the helper to remove userPersistedData. It might not be needed as all user data is being cleared on logout. */
export const removeUserPersistedData = ({ userId }) => {
  const prefixedUserId = meshUserDataPersistenceKeyPrefix + userId;

  try {
    const allLocalStorageKeys = Object.keys(window.localStorage);

    const allUserPersistedData = allLocalStorageKeys.filter((key) => key.startsWith(prefixedUserId));

    allUserPersistedData.forEach((key) => window.localStorage.removeItem(key));
  } catch (err) {
    console.error('Failed to clear persisted user preferences');
  }
};

/* ag-grid verification helpers */

export const isPersistedFilterModelValid = ({ persistedFilterModel, colDefs }) =>
  /* persisted filter model is an object with key as columnId, and value as filterModels.
    Assumption: 
    - the filterType will always be multi as AgGridWrapper always inserts sorting filter. this will result in first
    - if a persisted filter is not valid (compatible) , it won't be applied.

    Cases: 
    1. if a filter type is X and we try to apply filter type Y.
    2. if a filter type is Set and we don't get applied value in data anymore.
    3. custom type filters.

    Verification:
    1. verified 1 by changing persisted filter for a column from text to number.
    2. Results in empty data being displayed. ( which seems correct )
    3. will need custom validation. ( skipping for now )
  */

  /* one thing that needs to implemented is invalidation of persisted filters. For now returning true always */

  true;

export const wait = (ms = 1000) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getTimezoneOffsetString = (timeZone = 'UTC', date = new Date()) => {
  let res = '';
  try {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    let offsetInMilliseconds = tzDate.getTime() - utcDate.getTime();
    const isOffsetNegative = offsetInMilliseconds < 0;
    offsetInMilliseconds = Math.abs(offsetInMilliseconds);
    const offsetInHours = Math.floor(offsetInMilliseconds / (60 * 60 * 1000));
    const offsetInMinutes = (offsetInMilliseconds % (60 * 60 * 1000)) / (60 * 1000);
    const offsetInMinutesStr = offsetInMinutes < 10 ? `0${offsetInMinutes}` : offsetInMinutes;
    const offsetInHoursStr = offsetInHours < 10 ? `0${offsetInHours}` : offsetInHours;
    res = `UTC ${isOffsetNegative ? '-' : '+'}${offsetInHoursStr}:${offsetInMinutesStr}`;
  } catch (error) {
    res = '';
  }

  return res;
};
/**
 * roundOffToPlaces(1.256, 2) => 1.26
 * roundOffToPlaces(1.254, 2) => 1.25
 * roundOffToPlaces(10.9559, 3) => 1.956
 * @param {Number} num
 * @param {Number} digitsAfterDecimal
 * @returns {Number}
 */
export const roundOffToPlaces = (num, digitsAfterDecimal) => Number(Number(num).toFixed(digitsAfterDecimal));
