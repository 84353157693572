// import './wdyr';
import React, { lazy, Suspense } from 'react';

import { Box } from '@chakra-ui/react';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { Toaster } from '@mesh.ai/rabbit';
import * as microsoftTeams from '@microsoft/teams-js';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ChakraThemeProvider } from 'ChakraThemeProvider';
import 'focus-visible/dist/focus-visible';
import meshHistory from 'meshHistory';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import store from 'store';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { checkEnableCompanySupport } from 'utils/helpers/featureFlags';
// By default, React Query Devtools are only included in bundles when process.env.NODE_ENV === 'development', so you don't need to worry about excluding them during a production build.
import { retry } from 'utils/retry';

import CSSReset from 'assets/chakraCSSReset/CSSReset';
import LogoGifWithoutLabel from 'assets/gifs/logoWithoutLabel.gif';
import TestLoading from 'assets/gifs/testLoading.gif';

import { isSentryEnabled, isProductionServer } from 'constants/helper';
import { initMixpanel } from 'constants/mixpanel';
import { routes } from 'constants/routes';
import theme from 'constants/theme';

import './index.css';

const App = lazy(() => retry(() => import('./App')));
const Login = lazy(() => retry(() => import('./screens/Login')));
const LoginNetChexCallback = lazy(() => retry(() => import('./screens/LoginNetChex/LoginNetChexCallback')));
const LoginNetChex = lazy(() => retry(() => import('./screens/LoginNetChex')));

const StaticReviewReportESignSuccess = lazy(() =>
  retry(() => import('./screens/performanceReview/ResultPage/StaticReviewReportESignSuccess'))
);

const root = ReactDOM.createRoot(document.getElementById('root'));

initMixpanel();

function getEnvironmentName() {
  return isProductionServer() ? 'production' : 'development';
}

microsoftTeams.initialize();

const SENTRY_IGNORE_ERROR_STATUS_CODES = [401, 403, 410];
const SENTRY_IGNORE_ERROR_TYPES = ['NotAuthorizedException'];
const SENTRY_IGNORE_ERROR_NAMES = ['[SEV2]: Failed to fetch'];

// React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (isSentryEnabled())
  Sentry.init({
    dsn: process.env.PUBLIC_APP_SENTRY_DNS,
    environment: getEnvironmentName(),
    integrations: [
      // Enable tracing after allowing CORS - https://docs.sentry.io/platforms/javascript/tracing/trace-propagation/dealing-with-cors-issues/
      // Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration/
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.replayCanvasIntegration(),
    ],
    release: `mesh-ui-${process.env.PUBLIC_APP_VERSION}`,
    maxBreadcrumbs: 10,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', process.env.PUBLIC_APP_SERVER_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      'ResizeObserver loop limit exceeded',
      // Known browser errors thrown by sentry, that don't effect anything
      '*ResizeObserver loop limit exceeded*',
      '*Failed to fetch*',
      '*NetworkError when attempting to fetch resource*',
      // User Errors
      '*Password did not conform with policy*',
      '*Password did not confirm with policy*',
      '*Incorrect username or password*',
      '*Invalid file name*',
      '*Password did not conform with policy*',
      '*authentication failed, please re-login*',
      '*Missing required parameter PASSWORD*',
      '*failed to satisfy constraint*',
      'An objective with the same name already exists in the selected time frame.',
    ],
    beforeSend: (event, hint) => {
      const {
        originalException: { status, errorType, name },
      } = hint;
      // console.log('event', event);
      // console.log('hint', hint);

      if (SENTRY_IGNORE_ERROR_STATUS_CODES.includes(status)) {
        return null; // this drops the event and nothing will be sent to sentry
      }
      if (SENTRY_IGNORE_ERROR_TYPES.includes(errorType)) {
        return null;
      }
      if (SENTRY_IGNORE_ERROR_NAMES.includes(name)) {
        return null;
      }
      return event;
    },
  });

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={meshHistory}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <ChakraThemeProvider>
                <CSSReset />
                <CssBaseline />
                <Toaster />
                <QueryClientProvider client={queryClient}>
                  <Suspense
                    fallback={
                      <Box display="flex" width="100vw" height="100vh" justifyContent="center" alignItems="center">
                        <img
                          src={checkEnableCompanySupport() ? TestLoading : LogoGifWithoutLabel}
                          alt="Logo"
                          width="100px"
                          height="100px"
                        />
                      </Box>
                    }
                  >
                    <Switch>
                      {/* these come before login because they also have /login and are more specific */}
                      <Route path={routes.loginNetChexRoute} component={LoginNetChex} />
                      <Route path={routes.loginNetChexCallbackRoute} component={LoginNetChexCallback} />
                      <Route path={routes.login} component={Login} />
                      <Route path={routes.staticReviewReportESignSuccess} component={StaticReviewReportESignSuccess} />
                      <Route path="/" component={App} />
                    </Switch>
                  </Suspense>
                  <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
                </QueryClientProvider>
              </ChakraThemeProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);
