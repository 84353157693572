import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { TABLE_COLUMNS_DISPLAYNAME } from 'screens/performanceReview/manageAndInsights/ReviewManage/components/ReviewManageAGGrid/ReviewManageAGGrid';

type FeedbackTimeline = {
  reviewStage: string;
  startDate: string;
  endDate: string;
  isEnableAutoSubmit: boolean;
};

type Timelines = {
  MANAGER: FeedbackTimeline;
  RATING_CALIBRATE: FeedbackTimeline;
  SELF: FeedbackTimeline;
};

type FeedbackForm = {
  feedbackFormId: number;
  name: string;
  timelines: Timelines;
  feedbackTimelines: FeedbackTimeline[];
};

export type Company = {
  id: string;
  companyName: string;
};

export type ReviewsIntentType = {
  id: string;
  label: string;
  onClick: () => void;
  visible: () => boolean;
  isBeta?: boolean;
  value: {
    myData?: IntentValuesType;
    team?: {
      directReports: IntentValuesType;
      allReports: IntentValuesType;
    };
    company?: IntentValuesType;
  };
  queryParamValue?: string;
  tableColumnsToSelect?: Array<keyof typeof TABLE_COLUMNS_DISPLAYNAME>;
};

const initialState: {
  selectedIntent: null | ReviewsIntentType;
  selectedForm: null | FeedbackForm;
  selectedCompanies: Company[];
  allCompanies: Company[];
  selectedLocations: string[];
  allLocations: string[];
} = {
  selectedIntent: null,
  selectedForm: null,
  selectedCompanies: [],
  allCompanies: [],
  selectedLocations: [],
  allLocations: [],
};

export const reviewsIntentSlice = createSlice({
  name: 'reviewsIntent',
  initialState,
  reducers: {
    setSelectedReviewsIntent: (state, action: PayloadAction<ReviewsIntentType>) => {
      state.selectedIntent = action.payload;
    },
    setSelectedReviewsForm: (state, action: PayloadAction<FeedbackForm>) => {
      state.selectedForm = action.payload;
    },
    setSelectedCompanies: (state, action: PayloadAction<Company[]>) => {
      state.selectedCompanies = action.payload;
    },
    setAllCompanies: (state, action: PayloadAction<Company[]>) => {
      state.allCompanies = action.payload;
    },
    setSelectedLocations: (state, action: PayloadAction<string[]>) => {
      state.selectedLocations = action.payload;
    },
    setAllLocations: (state, action: PayloadAction<string[]>) => {
      state.allLocations = action.payload;
    },
  },
});

export const {
  setSelectedReviewsIntent,
  setSelectedReviewsForm,
  setSelectedCompanies,
  setAllCompanies,
  setSelectedLocations,
  setAllLocations,
} = reviewsIntentSlice.actions;

export default reviewsIntentSlice.reducer;

export const useSelectReviewsSelectedIntent = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.selectedIntent ?? {});

export const useSelectReviewsSelectedForm = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.selectedForm);

export const useSelectedReviewCompanies = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.selectedCompanies);

export const useAllReviewCompanies = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.allCompanies);

export const useSelectedReviewLocations = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.selectedLocations);

export const useAllReviewLocations = () =>
  useSelector((state: { reviewsIntent: typeof initialState }) => state.reviewsIntent.allLocations);
