import React from 'react';

import { Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Cross as CrossIcon, Search as SearchIcon } from 'assets/assetsV1/iconComponents';

const StyledInputGroup = styled(InputGroup)`
  border-radius: 4px;
  input {
    border: none;
    border-radius: 4px;
    background-color: ${(props) => props.theme.colors.neutral.EBEFF3};
    color: ${(props) => props.theme.colors.text.g3};
  }

  svg: {
    color: ${(props) => props.theme.colors.text.g3};
  }

  .clear-icon {
    visibility: hidden;
  }

  &.filled {
    .clear-icon {
      visibility: visible;
    }

    input {
      background-color: #eef3f8;
      color: ${(props) => props.theme.colors.text.g2};
    }
    svg: {
      color: ${(props) => props.theme.colors.text.g2};
    }
  }

  &:hover {
    input {
      color: ${(props) => props.theme.colors.text.g2};
    }
    svg: {
      color: ${(props) => props.theme.colors.text.g2};
    }
  }

  &:active,
  &:focus-within {
    .clear-icon {
      visibility: hidden;
    }

    input {
      color: ${(props) => props.theme.colors.text.g1};
      background-color: #ffffff;
      border: 1px solid ${(props) => props.theme.colors.primary['1565BC']};
    }
  }
`;

const SearchBar = ({
  // conditional functional props
  withFilter = false,
  isFilterActive = false,
  onClearInput,
  // default styling props
  width = '100%',
  searchRef, // THIS IS ADDED BECAUSE FOR AUTO FOCUS

  // rest props ( all other props like value, onChange etc should come )
  ...inputProps
}) => (
  <StyledInputGroup className={inputProps?.value?.length > 0 ? 'filled' : ''}>
    <InputLeftElement>
      <SearchIcon boxSize="18px" style={{ marginRight: '8px' }} />
    </InputLeftElement>
    <Input ref={searchRef} {...inputProps} />
    <InputRightElement onClick={onClearInput}>
      <CrossIcon className="clear-icon" boxSize="18px" />
    </InputRightElement>
  </StyledInputGroup>
);

export default SearchBar;
