import React from 'react';

import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { StyledTooltip } from 'styles/common';

import { Info } from 'assets/assetsV1/iconComponents';

const NonStyledAnchor = styled.a`
  font-size: 0;
`;

function TooltipInfoV1({ title = '', linkUrl = '', ...wrapperProps }) {
  return (
    <Box display="inline-flex" {...wrapperProps}>
      <StyledTooltip title={title}>
        <Flex>
          {linkUrl ? (
            <NonStyledAnchor target={`_${linkUrl}`} href={linkUrl}>
              <Info boxSize="20px" color="icon.g2" />
            </NonStyledAnchor>
          ) : (
            <Info boxSize="20px" color="icon.g2" />
          )}
        </Flex>
      </StyledTooltip>
    </Box>
  );
}

export { TooltipInfoV1 };
