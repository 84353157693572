// Libraries
import { FormControl, FormHelperText, FormLabel } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ReactSelect from 'react-select';

import { SELECT_VARIANTS } from './Select';

export const StyledReactSelect = styled(ReactSelect)`
  .select__control {
    border: none;
    font-size: ${(props) => props.theme.customFontStyles.size.bodyM};
    color: ${(props) => props.theme.colors.text.g1};
    background: ${(props) => (props.withBackground ? props.theme.colors.common.white : 'transparent')};
    font-weight: 600;
    min-height: auto;
    cursor: pointer;
  }

  .select__control--is-focused {
    border-width: 0 0 1px 0;
    border-radius: 0;
    box-shadow: none;
  }

  .select__value-container {
    padding: 0;
  }

  .select__input-container {
    margin: 0;
    padding: 0;
  }

  .select__placeholder {
    margin: 0;
    padding: 0;
  }

  .select__menu {
    min-width: ${(props) => props.minWidth ?? '250px'};
    margin-left: -5%;
    background: ${(props) => props.theme.colors.common.white};
    padding: 8px;
    border-radius: 8px;
    box-shadow: ${(props) => props.theme.shadows.large};

    .select__menu-list {
      padding: 0;

      .select__option {
        padding: 8px;
        border-radius: 4px;
        font-size: ${(props) => props.theme.customFontStyles.size.bodyM};
        color: ${(props) => props.theme.colors.text.g1};
        font-weight: 600;
        cursor: pointer;
      }

      .select__option--is-focused {
        background: ${(props) => props.theme.colors.functional.E6F1FD};
      }

      .select__option--is-selected {
        background: ${(props) => props.theme.colors.functional.E6F1FD};
      }

      .select__option:not(:last-of-type) {
        margin-bottom: 8px;
      }

      .select__option--is-disabled {
        opacity: 24%;
        cursor: not-allowed;
      }
    }
  }
`;

// currently applies on review form dropdown
export const stylesForMenuPortalReviewDropdown = {
  borderRadius: '8px',
  '.select__menu-list': {
    borderRadius: '8px',
    padding: '8px',
    boxShadow: '2px 0px 4px 3px rgba(0, 0, 0, 0.12), 0px 4px 4px 3px rgba(0, 0, 0, 0.08)',

    '.select__option': {
      padding: '8px',
      borderRadius: '4px',
      fontSize: '14px',
      color: '#333333',
      fontWeight: 600,
      cursor: 'pointer',
    },

    '.select__option--is-focused': {
      background: '#E6F1FD',
    },

    '.select__option--is-selected': {
      background: '#E6F1FD',
    },

    '.select__option:not(:last-of-type)': {
      marginBottom: '8px',
    },

    '.select__option--is-disabled': {
      opacity: '24%',
      cursor: 'not-allowed',
    },
  },
};

export const StyledFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => !['withBackground', 'variant', 'withBorderBottom'].includes(prop),
})`
  background: ${(props) => (props.withBackground ? props.theme.colors.common.white : 'transparent')};

  padding: ${(props) => (props.withBackground ? '12px' : '0')};
  border-radius: 8px;
  border: ${(props) =>
    props.variant === SELECT_VARIANTS.outlined ? `1px solid ${props.theme.colors.neutral.CCCCCC}` : 0};
  & .select__control {
    border-bottom: ${(props) => (props.withBorderBottom ? `1px dashed #999999 !important` : 0)};
    border-bottom-left-radius: ${(props) => (props.withBorderBottom ? `0` : 'inherit')};
    border-bottom-right-radius: ${(props) => (props.withBorderBottom ? `0` : 'inherit')};
  }
`;
// &:hover {
//   background: ${props =>
//     props.withBackground & !props.isDisabled
//       ? props.theme.colors.formFields.hoverBg
//       : props.theme.colors.formFields.transparentBg};
// }

// &:focus-within {
//   background: ${props =>
//     props.withBackground
//       ? props.theme.colors.formFields.focusBg
//       : props.theme.colors.formFields.transparentBg};
// }

export const StyledFormLabel = styled(FormLabel)`
  margin: 0;

  &[disabled],
  &[aria-disabled='true'],
  &[data-disabled] {
    opacity: 1;
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  text-align: right;
  margin: 0;
`;
