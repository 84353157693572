import VisierAuthSlice from 'Deer/components/Visier/slice/VisierAuth.slice';
import competenciesIntentSlice from 'Deer/intent/Competencies/slice/CompetenciesIntentSlice';
import engagementIntentSlice from 'Deer/intent/Engagement/slice/EngagementIntentSlice';
import checkInIntentSlice from 'Deer/intent/Goals/slice/CheckInIntentSlice';
import goalsIntentSlice from 'Deer/intent/Goals/slice/GoalsIntentSlice';
import lifecycleInsightsIntentSlice from 'Deer/intent/Lifecycle/slice/LifecycleInsightsIntentSlice';
import reviewsIntentSlice from 'Deer/intent/Reviews/slice/ReviewsIntentSlice';
import initiativeIntentSlice from 'Deer/intent/initiative/slice/InitiativeIntentSlice';
import engagementSurveyCreationSlice from 'Deer/pages/Company/Engagement/Surveys/SurveyCreation/SurveyCreation.slice';
import deerCollapsibleCardSlice from 'Deer/slice/DeerCollapsibleCard.slice';
import deerNavSlice from 'Deer/slice/DeerNav.slice';
import metricCardNavigateMetaDataSlice from 'Deer/slice/MetricCardNavigateMetaData.slice';
import loggedInUsersAccessesSlice from 'Deer/slice/UserAccessesSlice';
import competencyDrawerSlice from 'components/Develop/competencyDrawerSlice';
/* eslint-disable */
import InitiativeCreationPanelSlice from 'components/RabbitInitiativeCreationPanel/InitiativeCreationPanelSlice';
import initiativeSecondaryPanelSlice from 'components/RabbitInitiativeSidePanel/initiativeSecondaryPanelSlice';
import initiativeSlice from 'components/RabbitInitiativeSidePanel/initiativeSlice';
import OKRCreationPanelSlice from 'components/RabbitOKRCreationPanel/OKRCreationPanelSlice';
import okrDrawerChangeDetailsSlice from 'components/RabbitOKRCreationPanel/PrimaryPanel/V2/slice/okrDrawerChangeDetailsSlice';
import KRslice from 'components/RabbitOKRSidePanel/KRslice';
import krDrawerSecondaryPanelSlice from 'components/RabbitOKRSidePanel/krDrawerSecondaryPanelSlice';
import okrDrawerControlSlice from 'components/RabbitOKRSidePanel/okrPanelControlSlice';
import okrPanelKebabMenuActionsSlice from 'components/RabbitOKRSidePanel/okrPanelKebabMenuActionsSlice';
import realignModalReducer from 'components/RealignModal/redux/reducer';
import unsavedDataAlertSlice from 'reducers/unsavedDataAlertSlice';
import { combineReducers } from 'redux';
import goalsSlice from 'screens/Team/Rabbit/Goals/slice/GoalsSlice';
import competencySettingsSlice from 'screens/admin/Competencies/competencySettingsSlice';
import createCustomRoleSlice from 'screens/admin/CustomRole/CreateCustomRole/createCustomRoleSlice';
import meshMavenSlice from 'screens/common/MLCommentTextGenerationCompV1/slice/meshMavenSlice';
import OneOnOneInsightsFiltersSlice from 'screens/oneOnOneRabbit/Insights/slice/OneOnOneInsightsFiltersSlice';
import OneOnOneInstancePageSlice from 'screens/oneOnOneRabbit/InstancePage/slice/OneOnOneInstancePageSlice';
import OneOnOneEventBasedAgendaSlice from 'screens/oneOnOneRabbit/SettingsPage/slice/OneOnOneEventBasedAgendaSlice';
import CreateOneOnOneDrawerSlice from 'screens/oneOnOneRabbit/creationDrawer/slice/CreateOneOnOneDrawerSlice';
import FeedbackSlice from 'screens/performanceReview/components/ContinuousFeedback/Drawers/slice/feedbackSlice';
import personaInsightsSlice from 'screens/personaInsights/reducer/personaInsightsSlice';

import expandedContainerSlice from 'uiToolkitV1/ExpandableContainerProvider/ExpandedContainerSlice';

import accessControlSlice from '../screens/admin/Objectives/GoalPreferences/slice/accessControlSlice';
import post, { addPostModal } from './addPost';
import agendaQueueReducer from './agendaQueueReducer';
import authentication from './authentication';
import cohortBaseResetModal from './cohortBaseResetModal';
import competencySidePanelReducer from './competencySidePanelReducer';
import continuousFeedback from './continuousFeedback';
import createInitiativePanelReducer from './createInitiativePanelReducer';
import createKRPanelReducer from './createKRPanelReducer';
import createObjectivePanelReducer from './createObjectivePanelSlice';
import createSubObjectiveModal from './createSubObjectiveModal';
import datePicker from './datePicker';
import dateTimePicker from './dateTimePicker';
import EmbeddedAnalyticsPanelSlice from './embeddedAnalytics';
import employeeGoalsAccessPrivileges from './employeeGoalsAccessPrivileges';
import enabledFeatureFlagsReducer from './enabledFeatureFlagsReducer';
import enterComment from './enterComment';
import { feed } from './feed';
import filtersMenu from './filtersMenu';
import genericDrawer from './genericDrawer';
import gifModal from './gifModal';
import globalFilters from './globalFilters';
import globalGoalsReducer from './globalGoalsReducer';
import gmailOutlookApp from './gmailOutlookApp';
import helloBarContext from './hellobar';
import homePageViewSlice from './homePageViewSlice';
import imagePreview from './imagePreview';
import keyResultPromptModalReducer from './keyResultPromptModal';
import localStorageToReduxPersistenceDataSlice from './localStorageToReduxPersistenceDataSlice';
import loggedInEmployee from './loggedInEmployee';
import msTeamsContext from './msTeamsContext';
import mySummaryViewSlice from './mySummaryViewSlice';
import nominatePeers from './nominatePeers';
import objectiveCreationModalReducer from './objectiveCreationModalReducer';
import objectiveDetailsPanelReducer from './objectiveDetailsPanelReducer';
import objectivePromptModal from './objectivePromptModal';
import okrsPageReducer from './okrsPageSlice';
import oneOnOne from './oneOnOne';
import oneOnOnePageReducer from './oneOnOnePageSlice';
import orgData from './orgData';
import questionDetails from './questionDetails';
import refetchAgendaListSlice from './refetchAgendaListSlice';
import reorderConfirmationModalReducer from './reorderConfirmationModalReducer';
import reviewCalibrationModalReducer from './reviewCalibrationModalReducer';
import reviewCreation from './reviewCreationSlice';
import reviewCreationV1 from './reviewCreationV1Slice';
import reviewFormSlice from './reviewFormSlice';
import reviewManageSidepanel from './reviewManageSidepanelSlice';
import reviewResultSlice from './reviewResultSlice';
import sidebarNavReducer from './sidebarNav-slice';
import snackbar from './snackbar';
import talentPlanningSidepanel from './talentPlanningSidepanelSlice';
import tasksReducer from './task';
import taskDetails from './taskDetails';
import viewsSlice from './viewsSlice';

// this is root reducer file, used to create
export default combineReducers({
  loggedInEmployee,
  orgData,
  post,
  // addPostVisibility,
  snackbar,
  feed,
  enterComment,

  authentication,
  imagePreview,
  taskDetails,
  datePicker,
  dateTimePicker,
  tasksReducer,
  msTeamsContext,
  continuousFeedback,
  questionDetails,
  nominatePeers,
  globalFilters,
  filtersMenu,
  oneOnOne,
  hellobar: helloBarContext,
  gmailOutlookApp,
  genericDrawer,
  enabledFeatureFlags: enabledFeatureFlagsReducer,
  addToAgendaState: agendaQueueReducer,
  sidebarNav: sidebarNavReducer,
  globalGoals: globalGoalsReducer,
  refetchAgendaList: refetchAgendaListSlice,
  homePageViewSlice,
  mySummaryViewSlice,
  okrsPage: okrsPageReducer,
  reviewFormSlice,
  reviewCreation,
  reviewCreationV1,
  reviewManageSidepanel,
  oneOnOnePage: oneOnOnePageReducer,
  reviewResultSlice,
  personaInsights: personaInsightsSlice,

  // MODALS
  objectiveCreationModal: objectiveCreationModalReducer,
  reviewCalibrationModal: reviewCalibrationModalReducer,
  reorderConfirmationModal: reorderConfirmationModalReducer,
  objectivePromptModal,
  cohortBaseResetModal,
  realignModal: realignModalReducer,
  keyResultPromptModal: keyResultPromptModalReducer,
  gifModal,
  createSubObjectiveModal,
  addPostModal,
  accessControlSlice,

  /* Rabbit OKR Panels */
  okrCreationPanelState: OKRCreationPanelSlice,
  /*  */

  // PANELS
  createObjectivePanel: createObjectivePanelReducer,
  createKRPanel: createKRPanelReducer,
  createInitiativePanel: createInitiativePanelReducer,
  objectiveDetailsPanel: objectiveDetailsPanelReducer,
  competencySidePanelDetails: competencySidePanelReducer,
  OneOnOneInstancePage: OneOnOneInstancePageSlice,
  views: viewsSlice,
  OneOnOneInsightsFiltersSlice,
  OneOnOneEventBasedAgendaSlice,
  CreateOneOnOneDrawerSlice,
  krDrawer: KRslice,
  krDrawerSecondaryPanel: krDrawerSecondaryPanelSlice,
  initiativeDrawer: initiativeSlice,
  initiativeSecondaryPanel: initiativeSecondaryPanelSlice,
  initiativeCreationDrawer: InitiativeCreationPanelSlice,
  okrPanelKebabMenuActions: okrPanelKebabMenuActionsSlice,
  okrDrawerControl: okrDrawerControlSlice,
  employeeGoalsAccessPrivileges,
  // CollapseSideBar: CollapseSideBarSlice
  FeedbackSlice,
  okrDrawerChangeDetails: okrDrawerChangeDetailsSlice,
  reviewsMeshMaven: meshMavenSlice,
  deerNav: deerNavSlice,
  competencyDrawer: competencyDrawerSlice,
  competencySettings: competencySettingsSlice,
  unsavedDataAlertSlice,
  visierAuth: VisierAuthSlice,
  EmbeddedAnalyticsPanelSlice,
  createCustomRoleSlice,
  deerCollapsibleCardSlice,
  metricCardNavigateMetaData: metricCardNavigateMetaDataSlice,
  loggedInUsersAccesses: loggedInUsersAccessesSlice,
  goalsSlice,
  talentPlanningSidepanel,
  goalsIntent: goalsIntentSlice,
  checkInIntent: checkInIntentSlice,
  reviewsIntent: reviewsIntentSlice,
  initiativeIntent: initiativeIntentSlice,
  competenciesIntent: competenciesIntentSlice,
  expandedContainer: expandedContainerSlice,
  localStorageToReduxPersistenceData: localStorageToReduxPersistenceDataSlice,
  lifecycleInsightsIntent: lifecycleInsightsIntentSlice,
  engagementIntent: engagementIntentSlice,
  engagementSurveyCreation: engagementSurveyCreationSlice,
});
