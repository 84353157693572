/**
 *  Redux Store
 */
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'reducers';
// import thunk from 'redux-thunk';
import logger from 'redux-logger';

// const createHistory = require("history").createBrowserHistory;

// export const history = createHistory()

const enhancers = [];
// let middleware = [thunk, routerMiddleware(meshHistory)];

// if (process.env.NODE_ENV !== 'production') {
//   middleware = [...middleware, logger];
// }

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      if (process.env.PUBLIC_APP_USE_REDUX_LOGGER === 'true') {
        return getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        }).concat(logger);
      }
      return getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      });
    }
    return getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });
  },
  devTools: process.env.NODE_ENV === 'development',
});

// store is also being directly imported in some helper methods for direct access.
// option 1: https://daveceddia.com/access-redux-store-outside-react/
export default store;
