import { routes } from './routes';

// Todo: mixpanel this will be removed after all references are removed

export const routesForTracking = {
  // @todo: vikalp netchex ---- add netchex login and callback page for tracking
  login: { path: '/login', pageName: 'Login' },
  searchResults: { path: '/search', pageName: 'searchResults' },
  profile: { path: '/employee/:employeeId', pageName: 'UserProfile' },
  home: { path: '/', pageName: 'HomePage' },
  post: { path: '/feed/:feedId', pageName: 'Post' },
  team: { path: '/managers/team/:teamId', pageName: 'TeamPage' },
  // -------- signup routes --------------
  emailRegister: { path: '/signup', pageName: 'EmailCapture' },
  validateEmail: { path: '/emailVerification', pageName: 'EmailValidation' },
  forgotPassword: { path: '/forgotPassword', pageName: 'ForgotPassword' },
  forgotPasswordConfirmation: {
    path: '/forgotPasswordConfirmation',
    pageName: 'ForgotPasswordConfirmation',
  },
  selectDepartment: { path: '/selectDepartment', pageName: 'DeptSelection' },
  selectTeam: { path: '/selectTeam', pageName: 'TeamName' },
  invite: { path: '/invite', pageName: 'TeamInvites' },
  // -------- signup routes end -------------
  teamSettings: {
    path: '/settings/teams/:teamId',
    pageName: 'teamSettings',
  },
  // settings: '/settings/myProfile',
  referral: { path: '/settings/referral', pageName: 'referral' },
  settings: { path: '/settings', pageName: 'Settings' },
  myProfileSettings: {
    path: '/settings/myProfile',
    subPageName: 'MyProfileSettings',
    pageName: 'Settings',
  },
  referralSettings: {
    path: '/settings/referral',
    subPageName: 'ReferralSettings',
    pageName: 'Settings',
  },
  tagDetail: { path: '/tag/:tagId', pageName: 'HashTagPage' },

  // new routes.
  // Analytics routes start here
  analyticsGoals: {
    path: routes.analytics.goals,
    pageName: 'GoalAnalytics',
  },
  analyticsOneOnOne: {
    path: routes.analytics.oneOnOne,
    pageName: 'OneOnOneAnalytics',
  },
  analyticsFeedback: {
    path: routes.analytics.feedback,
    pageName: 'FeedbackAnalytics',
  },
  analyticsDevelop: {
    path: routes.analytics.levelUp,
    pageName: 'DevelopAnalytics',
  },

  // settings Routes start here.
  // meshTagsSettings: {
  //   path: routes.settings.meshTags,
  //   pageName: 'MeshTagsSettings'
  // },
  objectivesSettings: {
    path: routes.settings.objectives,
    pageName: 'ObjectivesSettings',
  },
  competenciesSettings: {
    path: routes.settings.competencies,
    pageName: 'CompetenciesSettings',
  },
  companySettings: {
    path: routes.settings.company,
    pageName: 'CompanySettings',
  },
  applauseSettings: {
    path: routes.settings.applause,
    pageName: 'ApplauseSettings',
  },
  employeesSettings: {
    path: routes.settings.employees,
    pageName: 'EmployeesSettings',
  },
  slackPluginSettings: {
    path: routes.settings.plugins.slack,
    pageName: 'SlackPluginSettings',
  },
  msTeamsPluginSettings: {
    path: routes.settings.plugins.msTeams,
    pageName: 'MsTeamsPluginSettings',
  },
  salesforcePluginSettings: {
    path: routes.settings.plugins.salesforce,
    pageName: 'salesforcePluginSettings',
  },
  jiraPluginSettings: {
    path: routes.settings.plugins.jira,
    pageName: 'JiraPluginSettings',
  },
  darwinPluginSettings: {
    path: routes.settings.plugins.darwinbox,
    pageName: 'DarwinPluginSettings',
  },
  userGuideSettings: {
    path: routes.settings.userguide,
    pageName: 'UserGuideSettings',
  },
  troubleshootSettings: {
    path: routes.settings.troubleshootSettings,
    pageName: 'TroubleshootSettings',
  },
  // checkinSettings: {
  //   path: routes.settings.checkIn.settings,
  //   pageName: 'OneOnOneSettings'
  // },
  // checkinQuestionsSettings: {
  //   path: routes.settings.checkIn.questions,
  //   pageName: 'OneOnOneQuestionsSettings'
  // },
  reportBugSettings: {
    path: routes.settings.reportBug,
    pageName: 'ReportBugSettings',
  },
  reviewProcessSettings: {
    path: routes.settings.reviewProcess,
    pageName: 'ReviewProcessSettings',
  },
  reviewCycleSettings: {
    path: routes.settings.reviewCycle,
    pageName: 'ReviewCycleSettings',
  },
  reviewQuestionsSettings: {
    path: routes.settings.questions,
    pageName: 'ReviewQuestionsSettings',
  },
  // engagementSettings: {
  //   path: routes.settings.engagement.pulseSurvey,
  //   pageName: 'PulseSurveySettings'
  // },
  engagementKickStartSettings: {
    path: routes.settings.engagement.pulseKickstart,
    pageName: 'PulseKickStartSettings',
  },
  // engagementQuestionsSettings: {
  //   path: routes.settings.engagement.questions,
  //   pageName: 'PulseQuestionsSettings'
  // },
  engagementLanding: { path: '/engagement', pageName: 'Engagement' },
  engagementQuestionnaire: {
    path: routes.engagement.surveyQuestionnaire,
    pageName: 'PulseSurveyQuestionnaire',
  },
  engagementPastSurvey: {
    path: routes.engagement.pastSurvey,
    pageName: 'PulsePastSurvey',
  },

  reviewLandingPage: { path: routes.performanceReview.index, pageName: 'Review' },
  // reviewTeamStatusPage: { path: routes.performanceReview.teamStatus, pageName: 'ReviewTeamStatus' },
  reviewForm: { path: routes.performanceReview.forms, pageName: 'ReviewForms' },

  oneOnOneLandingPage: {
    path: routes.oneOnOne.index,
    pageName: 'OneOnOne',
  },

  // oneOnOneInstancePage: {
  //   path: routes.oneOnOne.instance,
  //   pageName: '1:1 instance page'
  // },
  employeesOneOnOneList: {
    path: routes.oneOnOne.timeline,
    pageName: 'TimelinePage',
  },

  developLandingPage: {
    path: routes.levelUp.index,
    pageName: 'DevelopLandingPage',
  },
  developTeamPage: {
    path: routes.levelUp.myTeam,
    pageName: 'DevelopMyTeamPage',
  },

  // rabbit routes for tracking ************************************************
  // reviews
  myReviewsExplorePage: {
    path: routes.performanceReview.audit,
    pageName: 'MyReviews',
    type: 'Explore',
  },
  myReviewsPerformancePreviewPage: {
    path: routes.performanceReview.performancePreview,
    pageName: 'MyReviews',
    type: 'PerformancePreview',
  },
  reviewsCohortExplorePage: {
    path: routes.performanceReview.manage,
    pageName: 'ReviewsCohortExplorePage',
    type: 'Explore',
  },
  reviewsCohortInsightsPage: {
    path: routes.performanceReview.insights,
    pageName: 'ReviewsCohortInsightsPage',
    type: 'Analytics',
  },
};
