import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { deerEndpoints } from 'Deer/constants/endpoints';
import { setLoggedInUsersAccesses } from 'Deer/slice/UserAccessesSlice';
import { LoggedInUsersAccesses } from 'Deer/types/logeedInUsersAccesses';
import { updateLoggedInEmployeeAction } from 'actions/loggedInEmployee';
import { sendRequest } from 'api/sendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoggedInEmployeeId } from 'selectors/loggedInEmployee';

export const fetchLoggedInUsersAccesses = async ({ employeeId }: { employeeId: string | number }) => {
  try {
    const url = deerEndpoints.employeeAccesses(employeeId);
    // @ts-expect-error queryparams in required in sendRequest
    const res = await sendRequest({
      url,
      credentials: 'include',
      method: 'GET',
    });
    return res.entity;
  } catch (error) {
    console.error("Error fetching user's accesses", error);
    throw error;
  }
};

export const loggedInUsersAccessesKey = 'loggedInUsersAccesses';

export const useLoggedInUsersAccessesQuery = () => {
  const dispatch = useDispatch();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const loggedInEmployeeId = useSelector(selectLoggedInEmployeeId);
  const { data, isLoading, ...rest } = useQuery<LoggedInUsersAccesses>(
    [loggedInUsersAccessesKey],
    () =>
      fetchLoggedInUsersAccesses({
        employeeId: loggedInEmployeeId,
      }),
    {
      enabled: !!loggedInEmployeeId,
      staleTime: 1000 * 60 * 5, // 5 minutes
    }
  );

  const history = useHistory();

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        setLoggedInUsersAccesses({
          ...data,
        })
      );

      // inserting the isHRISManager and enabledFeatures in the loggedInEmployee object
      dispatch(
        updateLoggedInEmployeeAction({
          isHRISManager: data?.directManager,
          enabledFeatures: data?.enabledFeatures,
        })
      );

      setLoadingComplete(true);
    }
  }, [isLoading, data, dispatch, history.location.pathname]);

  return {
    data,
    isLoading,
    loadingComplete,
    ...rest,
  };
};
