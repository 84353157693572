import { docCookies } from 'cookieHelper';
import { selectMSTeamsContext } from 'selectors/msTeamsSelector';

import { DEMO_APP_DOMAIN, MARKETING_SITE, WEB_APP_COOKIE_DOMAIN } from 'constants/ProjectConstants';
import { clearLoginType } from 'constants/localStorage';
import { destroySession } from 'constants/moengage';
import { routes } from 'constants/routes';

import { getAuth0LogoutUrl, msTeamsDisconnectUser } from 'services/auth';
import { showErrorSnackbar } from 'services/snackbar';

import {
  AUTHENTICATE_LOGGED_IN_EMPLOYEE,
  UPDATE_LOGGED_IN_EMPLOYEE,
  LOGOUT,
  ATTEMPT_TO_AUTHENTICATE,
} from './actionTypes';

export function authenticateAction(isAuthenticated) {
  return (dispatch) => {
    dispatch({
      type: AUTHENTICATE_LOGGED_IN_EMPLOYEE,
      data: isAuthenticated,
    });
  };
}

export function authenticatingAction(isAuthenticating) {
  return (dispatch) => {
    dispatch({
      type: ATTEMPT_TO_AUTHENTICATE,
      data: isAuthenticating,
    });
  };
}

export function logoutAction() {
  return {
    type: LOGOUT,
  };
}

export function updateLoggedInEmployeeAction(loggedInEmployee, shouldUpdateOnboardingState = true) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_LOGGED_IN_EMPLOYEE,
      data: loggedInEmployee,
    });
    // because we change routes on team hub and then it repeats the step
    // if (!isLastStepTeamHubOrAfter(loggedInEmployee))
    // const state = getState();
    // if (shouldUpdateOnboardingState)
    //   dispatch({
    //     type: ONBOARDING_UPDATE,
    //     data: { stepIndex: state.onboarding.stepIndex },
    //   });
  };
}

export function logoutLoggedInEmployee() {
  const isDemoDomain = window.location.host === DEMO_APP_DOMAIN;

  // clear all localstorage for app on logout
  window.localStorage.clear();
  return async (dispatch, getState) => {
    // dispatch(logoutAction());
    const state = getState();
    const msTeamsContext = selectMSTeamsContext(state);
    const isMSTeamsSession = !!(msTeamsContext && Object.keys(msTeamsContext).length > 0);
    try {
      if (isMSTeamsSession) {
        await msTeamsDisconnectUser();
      }
    } catch (err) {
      console.error(err);
    }

    getAuth0LogoutUrl()
      .then((logoutLink) => {
        clearLoginType();
        destroySession();
        // to delete manually added authToken in outlook app - not absolutely required though as token is invalidated at logout
        docCookies.removeItem('authToken', '/', WEB_APP_COOKIE_DOMAIN);
        if (isDemoDomain) {
          window.location.href = MARKETING_SITE;
        } else if (isMSTeamsSession) {
          let route = '';
          const { entityId } = msTeamsContext;
          if (entityId === 'goals') {
            route = routes.goals.list;
          } else if (entityId === 'tasks') {
            route = routes.initiative.audit;
          } else if (entityId === 'feedback') {
            route = routes.msTeams.Feedback;
          } else if (entityId === 'oneOnOne') {
            route = routes.oneOnOne.audit;
          }
          if (entityId === 'reviews') {
            route = routes.performanceReview.audit;
          } else {
            route = routes.goals.list;
          }
          window.location.href = route;
        } else {
          window.location.href = logoutLink;
        }
      })
      .catch((err) => {
        dispatch(showErrorSnackbar(err.message));
      });
  };
}

export function setUserTimeZoneAction(timezone) {
  return (dispatch) => {
    dispatch({
      type: 'SET_USER_TIMEZONE',
      payload: { timezone },
    });
  };
}
