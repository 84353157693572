import { useRef, useState } from 'react';

import { Flex, Image, Box, Divider, Link } from '@chakra-ui/react';
import { Button } from '@mesh.ai/rabbit';
import { useToast } from 'components/hooks/useToast';

import { Cross, File } from 'assets/assetsV1/iconComponents';
import UploadSvg from 'assets/assetsV1/illustrations/deerIllustrations/upload_file.svg';

import { sampleEmployeeSelectionFileURI } from 'constants/ProjectConstants';

import { Button as ButtonToolkitVersion } from 'uiToolkitV1/Button';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

import UploadedFileEmailValidation from './UploadedFileEmailValidation';
import { uploadParticipantsCSVApi } from './dataHooks';

const FileIcon = () => <File boxSize="24px" color="inherit" />;

const UploadEmployeesPanel = ({ reviewCycleId, onSuccess, handleClose }) => {
  const toast = useToast();
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);

  const onChooseFile = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files && files.length) {
      setFile(files[0]);
    }
  };

  const showErrorToast = (errorMessage) => {
    toast({
      title: errorMessage || 'An error occurred while uploading the participants',
      status: 'error',
    });
  };

  const uploadFile = () => {
    uploadParticipantsCSVApi(file, reviewCycleId)
      .then((res) => {
        onSuccess(res?.entity);
      })
      .catch((err) => {
        showErrorToast(err?.message);
      })
      .finally(() => {
        handleClose();
      });
  };

  return (
    <Flex flexDir="column" w="full" h="378px">
      <Flex bg="white" flex={1} p="24px">
        {file ? (
          <Flex flex={1} flexDir="column" alignItems="center" justifyContent="center">
            <Image w="140px" src={UploadSvg as unknown as string} />
            <Flex alignItems="center" p="24px">
              <File />
              <TextBodyMedium color="text.g2" maxW="160px" isTruncated>
                {file.name}
              </TextBodyMedium>
              <Divider mx="8px" orientation="vertical" borderColor="#CFD7DF" />
              <Cross boxSize="24px" color="text.g2" cursor="pointer" onClick={() => setFile(null)} />
            </Flex>
            <UploadedFileEmailValidation uploadedFile={file} disableUploadButton={setIsUploadDisabled} />
          </Flex>
        ) : (
          <Flex flex={1} flexDir="column" alignItems="center" justifyContent="center">
            <Image w="140px" src={UploadSvg as unknown as string} />
            <input
              ref={fileUploadRef}
              type="file"
              id="fileUpload"
              accept=".csv"
              style={{
                display: 'none',
              }}
              onChange={onFileChange}
            />
            <Box p="24px">
              <Button iconLeft={FileIcon} onClick={onChooseFile}>
                Choose file
              </Button>
            </Box>
            <Box>
              <TextBodyMedium textAlign="center">
                <>
                  <Link href={sampleEmployeeSelectionFileURI} download="MeshCSVSample.csv">
                    <ButtonToolkitVersion
                      sx={{
                        height: 'auto!important',
                        padding: '0px!important',
                        marginRight: '4px',
                        marginLeft: '4px',
                        marginTop: '-2px',
                        '.chakra-text': { fontSize: '14px', margin: '0px!important', lineHeight: '20px' },
                      }}
                      variant="tertiary"
                      onClick={() => {}}
                    >
                      Download
                    </ButtonToolkitVersion>
                  </Link>
                  our sample CSV file to refer to the upload format.
                </>
              </TextBodyMedium>
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex h="72px" bg="surface.F5F7F9" justifyContent="space-between" alignItems="center" px="16px">
        <Button variant="transparentOutline" style={{ width: '80px' }} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={isUploadDisabled} variant="filled" style={{ width: '80px' }} onClick={uploadFile}>
          Upload
        </Button>
      </Flex>
    </Flex>
  );
};

export { UploadEmployeesPanel };
