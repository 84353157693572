// Libraries
import React, { useCallback } from 'react';

// import { useTheme } from '@emotion/react';
import { Flex, InputGroup, useOutsideClick } from '@chakra-ui/react';
import { components } from 'react-select';

// Icons
import { ArrowDown, Check, Cross } from 'assets/assetsV1/iconComponents';

import { isMobileView } from 'constants/isMobileView';

// Typography
import { TextBodyMedium } from 'uiToolkitV1/Typography';

// Styles
import { StyledFormControlV2, StyledReactSelect } from './SelectDashed.styles';

const reactSelectComponents = {
  MultiValueContainer: ({ selectProps, data }) => {
    const values = selectProps.value;
    if (values) {
      return values[values.length - 1].value === data.value ? data.label : `${data.label}, `;
    }
    return '';
  },
  DropdownIndicator: () => <ArrowDown boxSize="24px" />,
  IndicatorSeparator: () => null,
  Option: ({ ...props }) => {
    const { children, isSelected } = props ?? {};
    return (
      <components.Option {...props}>
        <Flex alignItems="center">
          <TextBodyMedium color={isSelected ? 'button.blue.focus' : 'text.g1'}>{children}</TextBodyMedium>
          {isSelected && <Check ml="auto" color="button.blue.focus" />}
        </Flex>
      </components.Option>
    );
  },
  ClearIndicator: (props) => {
    const {
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div {...restInnerProps} ref={ref} style={{ display: 'flex', alignItems: 'center' }}>
        <Cross boxSize="16px" />
      </div>
    );
  },
};

const SelectDashed = ({
  placeholder,
  value,
  options,
  handleChange,
  customClassName = '',
  isMulti = false,
  isDisabled = false,
  className = `randomId${Math.floor(Math.random() * (100 - 0 + 1) + 0)}`,
  isSearchable = true,
  minWidth = '140px',
  isClearable = false,
  width = '100%',
  getOptionValue,
  getOptionLabel,
  boldText = false,
  selectRef,
  hideBorder = false,
  controlHeight = null,
  controlPadding = null,
  selectProps = {},
  ...props
}) => {
  const ref = React.useRef();

  const [isMultiSelectDropdownOpen, setIsMultiSelectDropdownOpen] = React.useState(false);
  const handleClickOutside = useCallback(() => {
    setIsMultiSelectDropdownOpen(false);
  }, []);

  useOutsideClick({
    ref,
    handler: handleClickOutside,
  });

  const otherProps = { ...selectProps };
  if (isMulti) {
    otherProps.menuIsOpen = isMultiSelectDropdownOpen;
  }
  if (props?.onFocus) {
    otherProps.onFocus = props.onFocus;
  }
  if (props?.onBlur) {
    otherProps.onBlur = props.onBlur;
  }

  if (props?.menuPlacement) otherProps.menuPlacement = props.menuPlacement;
  if (props?.styles) otherProps.styles = props.styles;

  return (
    <StyledFormControlV2
      isDisabled={isDisabled}
      className={className}
      // {...formControlProps}
      onClick={(e) => {
        if (!isMulti) return e;
        // if clicked on options, return
        const select = document.querySelector(`.${className}`);
        const selectOptionsList = select.querySelector('.select__menu');
        if (selectOptionsList && selectOptionsList.contains(e.target)) {
          return null;
        }

        setIsMultiSelectDropdownOpen((val) => !val);
        return null;
      }}
      ref={ref}
      isMobileView={isMobileView()}
      hideBorder={hideBorder}
      controlHeight={controlHeight}
      controlPadding={controlPadding}
    >
      <InputGroup style={{ caretColor: 'transparent' }}>
        <StyledReactSelect
          options={options}
          getOptionValue={getOptionValue}
          getOptionLabel={getOptionLabel}
          // menuIsOpen={true}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          className={`select ${customClassName}`}
          classNamePrefix="select"
          isDisabled={isDisabled}
          isMulti={isMulti}
          isClearable={isClearable}
          hideSelectedOptions={false}
          isSearchable={isSearchable}
          minWidth={minWidth}
          width={width}
          components={{ ...reactSelectComponents, ...(props.components ?? {}) }}
          menuPlacement="auto"
          fontWeight={boldText ? '600' : '400'}
          ref={selectRef}
          {...otherProps}
        />
      </InputGroup>
    </StyledFormControlV2>
  );
};

export { SelectDashed };
