import { Box, Flex, Image } from '@chakra-ui/react';

// import { useDispatch } from 'react-redux';
// import { setReviewCreationKey, useReviewCreationV1Data } from 'reducers/reviewCreationV1Slice';

import EventType from 'assets/assetsV1/illustrations/deerIllustrations/event_type.svg';

import { TooltipInfoV1 } from 'uiToolkitV1/TooltipInfoV1';
// import TriggerCondition from 'assets/assetsV1/illustrations/deerIllustrations/trigger_condition.svg';

// import { InputV2 } from 'uiToolkitV1/InputV2';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

const ContinuousReviewSettings = () => {
  // const { triggerDaysCount } = useReviewCreationV1Data();
  // const dispatch = useDispatch();

  // const onChangeTriggerDays = (e) => {
  //   const val = e.target.value === '' ? '' : Number(e.target.value);
  //   dispatch(setReviewCreationKey({ triggerDaysCount: val }));
  // };

  return (
    <Box gridColumn="2 / span 2">
      <TextBodyMedium py="12px" mb={2}>
        All employees who meet the trigger criteria
      </TextBodyMedium>
      <Flex gap={10}>
        <Flex alignItems="center" mb={3}>
          <Image src={EventType} height="16px" width="16px" mr="4px" />
          <TextBodyMedium bold mr={2}>
            Criteria
          </TextBodyMedium>
          <TextBodyMedium mr={2}>has completed a certain period post their Date of Joining</TextBodyMedium>
          <TooltipInfoV1 title="Define the review workflow vis-a-vis date of joining in the Timeline section below." />
        </Flex>

        {/* <Flex alignItems="center">
          <Image src={TriggerCondition} height="16px" width="16px" mr={1} />
          <TextBodyMedium bold mr={2}>
            Trigger condition:
          </TextBodyMedium>
          <TextBodyMedium mr={2}>Add to the review </TextBodyMedium>
          <Box w="38px">
            <InputV2
              step={1}
              type="number"
              isDashed
              value={triggerDaysCount}
              min={0}
              max={365}
              onChange={onChangeTriggerDays}
            />
          </Box>
          <TextBodyMedium ml={2}>days post date of joining.</TextBodyMedium>
        </Flex> */}
      </Flex>
    </Box>
  );
};

export { ContinuousReviewSettings };
