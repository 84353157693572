import { REVIEW_CYCLE_STEPS_V1 } from 'reducers/reviewCreationSlice';

import { VIEWS_FILTER_KEYS } from './ProjectConstants';

export const REACT_QUERY_KEYS = {
  sidebarCohorts: 'sidebarCohorts',
  loggedInUser: 'loggedInUser',
  mySummary: {
    mySummaryReviews: 'mySummaryReviews',
    mySummaryFeedback: 'mySummaryFeedback',
    mySummaryCompetency: 'mySummaryCompetency',
    mySummaryGoals: 'mySummaryGoals',
    mySummaryInitiatives: 'mySummaryInitiatives',
    mySummaryOneOnOne: 'mySummaryOneOnOne',
    mySummaryEngagement: 'mySummaryEngagement',
  },
  profileData: 'profileData',
  CompetencySidePanel: 'CompetencySidePanel',
  LevelUpCompetencyData: 'LevelUpCompetencyData',
  LevelUpReporteeData: 'LevelUpReporteeData',
  ReviewFormSidebar: 'ReviewFormSidebar',
  ReviewGoalFormData: 'ReviewGoalFormData',
  ReviewCompetencyFormData: 'ReviewCompetencyFormData',
  ReviewOverallFormData: 'ReviewOverallFormData',
  ReviewAllCompetencySummary: 'ReviewAllCompetencySummary',
  ReviewFormPeerFeedback: 'ReviewFormPeerFeedback',
  ReviewGoalData: 'ReviewGoalData',
  postField: {
    userMention: 'userMention',
    competencyMention: 'competencyMention',
  },
  reviewsLanding: {
    pendingOnMe: 'reviewsLanding.pendingOnMe',
    pendingOnOthers: 'reviewsLanding.pendingOnOthers',
    completedByMe: 'reviewsLanding.completedByMe',
    pastReviews: 'reviewsLanding.pastReviews',
  },
  ReviewPeriodSummaryFeedback: 'ReviewPeriodSummaryFeedback',
  ReviewFAQData: 'ReviewFAQData',
  ReviewPeriodSummaryOkrs: 'ReviewPeriodSummaryOkrs',
  InitiativeStatsData: 'InitiativeStatsData',
  InitiativeTableData: 'InitiativeTableData',
  InitiativeCardsData: 'InitiativeCardsData',
  ReviewResultSidebar: 'ReviewResultSidebar',
  ReviewGoalResultData: 'ReviewGoalResultData',
  ReviewCompetencyResultData: 'ReviewCompetencyResultData',
  ReviewOverallResultData: 'ReviewOverallResultData',
  ReviewRatingResultData: 'ReviewRatingResultData',
  ReviewFormFillingRatingData: 'ReviewFormFillingRatingData',
  ReviewResponseGoalResultData: 'ReviewResponseGoalResultData',
  ReviewResponseCompetencyResultData: 'ReviewResponseCompetencyResultData',
  ReviewResponseOverallResultData: 'ReviewResponseOverallResultData',
  RequestedFeedbackFullComment: 'RequestedFeedbackFullComment',
  FeedbackAnalysis: 'FeedbackAnalysis',
  RephraseText: 'RephraseText',
  CustomerFeedback: 'CustomerFeedback',
  SharedOrReceivedFullComment: 'SharedOrReceivedFullComment',
  CFTabs: 'CFTabs',
  EngagementExpandedTable: 'EngagementExpandedTable',
  EngagementCountData: 'EngagementCountData',
  EngagementActiveSurveys: 'EngagementActiveSurveys',
  EngagementSubmittedSurveys: 'EngagementSubmittedSurveys',
  EngagementLineChartData: 'EngagementLineChartData',
  EngagementAggregatedChartData: 'EngagementAggregatedChartData',
  EngagementPerformanceGridData: 'EngagementPerformanceGridData',
  EngagementPerformanceGridClickedData: 'EngagementPerformanceGridClickedData',
  EngagementFilterSources: 'EngagementFilterSources',
  settings: {
    oneOnOne: {
      SettingsOneOnOneCalendarAuth: 'SettingsOneOnOneCalendarAuth',
    },
    EmployeeRoster: 'EmployeeRoster',
  },
  CFBarChartData: 'CFBarChartData',
  GoalsCascadedData: 'GoalsCascadedData',
  GoalsRealignData: 'GoalsRealignData',
  CFBarTimeLineGraphData: 'CFBarTimeLineGraphData',
  ReviewPreviewPerformanceOKRData: 'ReviewPreviewPerformanceOKRData',
  GoalsInsightsGroupByIndividualData: 'GoalsInsightsGroupByIndividualData',
  GoalsInsightsGroupByGoalsData: 'GoalsInsightsGroupByGoalsData',
  views: {
    views: 'views',
    filters: 'filters',
    baseCohort: 'baseCohort',
    grade: VIEWS_FILTER_KEYS.GRADE,
    role: VIEWS_FILTER_KEYS.ROLE,
    function: VIEWS_FILTER_KEYS.FUNCTION,
    department: VIEWS_FILTER_KEYS.DEPARTMENT,
    location: VIEWS_FILTER_KEYS.LOCATION,
    gender: VIEWS_FILTER_KEYS.GENDER,
    ethnicity: VIEWS_FILTER_KEYS.ETHNICITY,
    performanceGrid: 'performanceGrid',
    managers: VIEWS_FILTER_KEYS.MANAGERS,
    employees: VIEWS_FILTER_KEYS.EMPLOYEES,
    csvEmployees: VIEWS_FILTER_KEYS.CSV_EMPLOYEES,
    sidebarViewCohorts: VIEWS_FILTER_KEYS.SIDEBAR_VIEW_COHORTS,
    filteredEmployeesCount: 'filteredEmployeesCount',
  },
  ReviewSections: 'ReviewSections',
  PerformancePreviewCompetencyTable: 'PerformancePreviewCompetencyTable',
  DepartmentChildFunctionsIds: 'DepartmentChildFunctionsIds',
  DepartmentHierarchy: 'DepartmentHierarchy',
  MyInitiativeTableData: 'MyInitiativeTableData',
  okrsTimelineData: 'okrsTimelineData',
  functionTree: 'functionTree',
  ManagerAISummary: {
    ManagerAISummary: 'ManagerAISummary',
  },
  okrTreeView: 'okrTreeView',

  // REusing same object to avoid inconsistency
  reviewCreation: {
    ...REVIEW_CYCLE_STEPS_V1,
    // reviewees sub call
    views: 'views',
    overlappingViewEmployees: 'overlappingViewEmployees',

    // elements sub call
    templateList: 'templateList',

    reviewCycles: 'reviewCycles',

    // validations
    validation: 'validation',
  },
  employeeIsManagerOfManager: 'employeeIsManagerOfManager',
  goalPreferences: {
    AllTemplateData: 'AllTemplateData',
    SpecificTemplateData: 'SpecificTemplateData',
    SelectedTemplateData: 'SelectedTemplateData',
    ObjectiveSettingsData: 'ObjectiveSettingsData',
    CheckinReminderData: 'CheckinReminderData',
  },
  thirdPartyPlugin: 'thirdPartyPlugin',
  partialPublish: 'partialPublish',
  bulkNotify: 'bulkNotify',
  reviewsManageTabularData: 'reviewsManageTabularData',
  reviewTemplateList: {
    activeTemplates: 'activeTemplates',
    archivedTemplates: 'archivedTemplates',
    sampleTemplates: 'sampleTemplates',
  },
  reviewQuestionList: {
    activeQuestions: 'activeQuestions',
    archivedQuestions: 'archivedQuestions',
    sampleQuestions: 'sampleQuestions',
  },
  reviewsManage: 'reviewsManage',
  reviewsManageMetrics: 'reviewsManageMetrics',
  reviewsManageReviewHistoryGraph: 'reviewsManageReviewHistoryGraph',
  archivedOkrs: 'archivedOkrs',
  reviewsManageFilters: 'reviewsManageFilters',
  reviewsGenAi: {
    rephraseText: 'reviewsGenAi.rephraseText',
    analyzeText: 'reviewsGenAi.analyzeText',
    customerFeedback: 'reviewsGenAi.customerFeedback',
  },
  meshRecommends: {
    nudgeCards: 'meshRecommends.nudgeCards',
  },
  competencyDrawerNew: {
    levelUpDetails: 'competencyDrawerNew.levelUpDetails',
    levelUpOptions: 'competencyDrawerNew.levelUpOptions',
    posts: 'competencyDrawerNew.posts',
    competencyTasks: 'competencyDrawerNew.competencyTasks',
    labelConfig: 'competencyDrawerNew.labelConfig',
    competencySettings: 'competencyDrawerNew.competencySettings',
    competencyScoreAlert: 'competencyDrawerNew.competencyScoreAlert',
  },
  orgModuleSettings: 'orgModuleSettings',
  competencyManage: {
    myCompetencies: 'competencyManage.myCompetencies',
    teamCompetencies: 'competencyManage.teamCompetencies',
  },
  deerReviewsOverview: {
    performanceGrid: 'deerReviewsOverview.performanceGrid',
  },
  spiderChart: 'spiderChart',
  goalsCardView: 'goalsCardView',
  talentPlanning: {
    tabularData: 'talentPlanning.tabularData',
    sidepanel: 'talentPlanning.sidepanel',
    sidepanelCalibrationHistory: 'talentPlanning.sidepanelCalibrationHistory',
    sidepanelReviewHistory: 'talentPlanning.sidepanelReviewHistory',
  },
  reviewManage: {
    sidepanel: 'reviewManage.sidepanel',
    providers: 'reviewManage.providers',
  },
  reviews: {
    reviewCyclesAndViews: 'reviews.reviewCyclesAndViews',
    reviewTimeline: 'reviews.reviewTimeline',
  },
  bulkCheckIn: 'bulkCheckIn',
  feedbackTable: 'feedbackTable',
  reviewActionPlan: {
    revieweeList: 'revieweeList',
    actionPlanList: 'actionPlanList',
    competencyInitiatives: 'competencyInitiatives',
  },
  reviewAiCalibrationMetrics: 'reviewAiCalibrationMetrics',
  reviewAcknowledge: {
    acknowledgeRequest: 'acknowledgeRequest',
    acknowledgeRequestPolling: 'acknowledgeRequestPolling',
  },
  checkIns: {
    currentCheckIn: 'currentCheckIn',
    pastCheckInsHistory: 'pastCheckInsHistory',
    checkInInstance: 'checkInInstance',
  },
  reviewCreationV2: {
    reviewData: 'reviewCreationV2.reviewData',
    overlappingViewEmployees: 'reviewCreationV2.overlappingViewEmployees',
    views: 'reviewCreationV2.views',
    forms: 'reviewCreationV2.forms',
    formsPanel: 'reviewCreationV2.formsPanel',
    timelineReminders: 'reviewCreationV2.timelineReminders',
    calibrationAndDeliverables: 'reviewCreationV2.calibrationAndDeliverables',
  },
  engagementSurveyResults: 'engagementSurveyResults',
};
