import { useMemo } from 'react';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

export const useParsedCohortQueryParam = () => {
  const { search } = useLocation();

  return useMemo(() => {
    const queryParams = queryString.parse(search);

    let pC = null;
    if (queryParams.cohort) {
      try {
        pC = JSON.parse(queryParams.cohort);
      } catch (err) {
        console.error('error in parsing cohort', err);
      }
    }
    return pC;
  }, [search]);
};
