import { Box } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { REVIEW_FORM_STAGE, setReviewCreationKey, useReviewCreationV1Data } from 'reducers/reviewCreationV1Slice';

import { InputV2 } from 'uiToolkitV1/InputV2';
import { SelectDashed } from 'uiToolkitV1/Select';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

import { LabelFieldWrapper } from '../components/LabelFieldWrapper';
import { SectionWrapper } from '../components/SectionWrapper';
import { useUpdateReviewName, useUpdateReviewType } from '../dataHooks/nameAndRevieweesSectionHooks';
import { ContinuousReviewSettings } from './nameAndRevieweesComps/ContinuousReviewSettings';
import ParticipantSelectionDropdown from './nameAndRevieweesComps/ParticipantSelectionDropdown';

export const REVIEW_CREATION_TYPES = {
  DEFAULT: 'DEFAULT',
  TRIGGERED: 'TRIGGERED',
};

export const REVIEW_TYPE_OPTIONS = [
  { label: 'One time', value: REVIEW_CREATION_TYPES.DEFAULT },
  { label: 'Continuous', value: REVIEW_CREATION_TYPES.TRIGGERED },
];

const TYPE_INFO_TEXT = {
  'One time': 'A single review for a set group over a defined period—ideal for year-end reviews and skill assessments.',
  Continuous:
    'Triggered for employees upon reaching milestones, auto-adding them based on set criteria—perfect for probation and performance improvement.',
};

export const NameAndReviewees = () => {
  const dispatch = useDispatch();
  const { reviewCycleName, reviewType, reviewStage } = useReviewCreationV1Data();
  const { mutate: updateReviewName } = useUpdateReviewName();
  const { mutate: updateReviewType } = useUpdateReviewType();

  const isDraft = reviewStage === REVIEW_FORM_STAGE.DRAFT;

  const onChangeName = (e) => {
    dispatch(setReviewCreationKey({ reviewCycleName: e.target.value }));
  };

  const handleNameBlur = (e) => {
    updateReviewName({ reviewCycleName: e.target.value });
  };

  const onChangeType = ({ value }) => {
    dispatch(setReviewCreationKey({ reviewType: value }));
    updateReviewType({ reviewType: value });
  };

  return (
    <SectionWrapper
      title="Review name, type and participants"
      subTitle="Review Name, Review Type, Participants, Reviewers"
      restrictAccess={!isDraft}
    >
      <LabelFieldWrapper label="Review cycle name">
        <InputV2
          placeholder="Enter a cycle name"
          isDashed
          onChange={onChangeName}
          onBlur={handleNameBlur}
          value={reviewCycleName}
          width="100%"
        />
      </LabelFieldWrapper>

      <LabelFieldWrapper label="Type of review">
        <Box>
          <SelectDashed
            value={REVIEW_TYPE_OPTIONS.find(({ value }) => reviewType === value)}
            options={REVIEW_TYPE_OPTIONS}
            handleChange={onChangeType}
            width="100%"
          />
          <TextBodyMedium mt={2} color="text.g2">
            {TYPE_INFO_TEXT[reviewType]}
          </TextBodyMedium>
        </Box>
      </LabelFieldWrapper>

      <LabelFieldWrapper label="Who is part of the survey">
        {reviewType === REVIEW_CREATION_TYPES.TRIGGERED ? (
          <ContinuousReviewSettings />
        ) : (
          <ParticipantSelectionDropdown />
        )}
      </LabelFieldWrapper>
    </SectionWrapper>
  );
};
