/* eslint-disable no-lone-blocks */

/* eslint-disable default-case */
import { useQuery } from '@tanstack/react-query';
import { sendRequest } from 'api/sendRequest';

import { endpoints } from 'constants/endpoints';

const ENABLED_FILTERS = {
  LOCATION: 'LOCATION',
  COMPANY_ID: 'COMPANY_ID',
};

const REACT_QUERY_KEYS = {
  GET_ALL_FILTERS: 'GET_ALL_FILTERS',
};

const getFiltersApi = () =>
  sendRequest({
    url: endpoints.reviewCreationV2.filteredReviewee,
    credentials: 'include',
    method: 'POST',
    body: [ENABLED_FILTERS.LOCATION, ENABLED_FILTERS.COMPANY_ID],
  });

export const useGetAllFilters = () =>
  useQuery([REACT_QUERY_KEYS.GET_ALL_FILTERS], () => getFiltersApi().then((res) => res.entity.filters));

export const uploadParticipantsCSVApi = (fileObject, reviewCycleId) => {
  const url = endpoints.reviewCreationV2.uploadParticipantsFile;
  const data = new FormData();
  data.append('file', fileObject);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    body: data,
    isMultiPart: true,
    queryParams: {
      reviewCycleId,
    },
  });
};
