import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { L2_VALUES } from 'Deer/constants/routes';
import { sendRequest } from 'api/sendRequest';
import { useToast } from 'components/hooks/useToast';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setReviewCreationKey, useReviewCreationV1Data, AUTO_UPDATE_STATUSES } from 'reducers/reviewCreationV1Slice';

import { endpoints } from 'constants/endpoints';
import { goToDeerModuleSettingsPage } from 'constants/helper';
import { REACT_QUERY_KEYS } from 'constants/reactQueryKeys';

import useReviewCreationFormId from '../hooks/useReviewCreationFormId';
import { REVIEW_CREATION_TYPES } from '../sections/NameAndReviewees';

const updateReviewPeriod = ({
  reviewCycleId,
  reviewPeriodStart = undefined,
  reviewPeriodEnd = undefined,
  reviewPeriodDetails = undefined,
}) =>
  sendRequest({
    url: endpoints.reviewCreationV2.updateReviewPeriod,
    method: 'PUT',
    body: {
      reviewCycleId,
      reviewPeriodStart,
      reviewPeriodEnd,
      reviewPeriodDetails,
    },
    credentials: 'include',
  }).then((res) => res.entity);

export const useUpdateReviewPeriod = () => {
  const { reviewCycleId, reviewType, reviewPeriodDetails, reviewPeriodStart, reviewPeriodEnd } =
    useReviewCreationV1Data();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ changedPropertyKey, changedPropertyValue }) => {
      if (reviewType === REVIEW_CREATION_TYPES.DEFAULT) {
        if (changedPropertyKey === 'reviewPeriodStart') {
          return updateReviewPeriod({
            reviewCycleId,
            reviewPeriodStart: changedPropertyValue,
            reviewPeriodEnd,
            // reviewPeriodDetails: null,
          });
        }
        // else property is reviewPeriodEnd
        return updateReviewPeriod({
          reviewCycleId,
          reviewPeriodEnd: changedPropertyValue,
          reviewPeriodStart,
          // reviewPeriodDetails: null,
        });
      }
      // else property is reviewPeriodDetails
      return updateReviewPeriod({
        reviewCycleId,
        reviewPeriodDetails: { ...reviewPeriodDetails, [changedPropertyKey]: changedPropertyValue },
      });
    },

    onMutate: () => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_SAVING }));
    },
    onSuccess: (data) => {
      setTimeout(() => dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED })), 500);
    },
    onError: (data) => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_ERROR }));
    },
  });
};

const getTimelineReminders = ({ reviewCycleId }) =>
  sendRequest({
    url: endpoints.reviewCreationV2.timelineReminders,
    credentials: 'include',
    queryParams: {
      reviewCycleId,
    },
  }).then((res) => res.entity);

export const useGetTimelineReminders = () => {
  const dispatch = useDispatch();

  const reviewCycleId = useReviewCreationFormId();
  return useQuery(
    [REACT_QUERY_KEYS.reviewCreationV2.timelineReminders, reviewCycleId],
    () =>
      getTimelineReminders({ reviewCycleId }).then((data) => {
        dispatch(
          setReviewCreationKey({
            ...data,
          })
        );
        return data;
      }),
    { enabled: !!reviewCycleId }
  );
};

const updateTimelineReminders = ({
  reviewCycleId,
  timelines = undefined,
  providerTimelineDetails = undefined,
  reminders = undefined,
}) =>
  sendRequest({
    url: endpoints.reviewCreationV2.updateLaunchInfo,
    method: 'PUT',
    body: {
      reviewCycleId,
      timelines,
      providerTimelineDetails,
      reminders,
    },
    credentials: 'include',
  }).then((res) => res.entity);

export const useUpdateTimelineReminders = () => {
  const { reviewCycleId, reviewType, reminders, timelines, providerTimelineDetails } = useReviewCreationV1Data();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: () => {
      if (reviewType === REVIEW_CREATION_TYPES.DEFAULT) {
        return updateTimelineReminders({
          reviewCycleId,
          reminders,
          timelines,
        });
      }
      return updateTimelineReminders({
        reviewCycleId,
        reminders,
        providerTimelineDetails,
      });
    },

    onMutate: () => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_SAVING }));
    },
    onSuccess: (data) => {
      dispatch(setReviewCreationKey({ isTimelineRemindersPanelOpen: false }));

      setTimeout(() => dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED })), 500);
    },
    onError: (data) => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_ERROR }));
    },
  });
};

const updateLaunchMail = ({ reviewCycleId, isSendLaunchComm }) =>
  sendRequest({
    url: endpoints.reviewCreationV2.updateMailPreference,
    method: 'PUT',
    body: {
      reviewCycleId,
      isSendLaunchComm,
    },
    credentials: 'include',
  }).then((res) => res.entity);

export const useUpdateLaunchMailPreference = () => {
  const { reviewCycleId } = useReviewCreationV1Data();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ isSendLaunchComm }) => updateLaunchMail({ reviewCycleId, isSendLaunchComm }),

    onMutate: () => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_SAVING }));
    },
    onSuccess: (data) => {
      setTimeout(() => dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED })), 500);
    },
    onError: (data) => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_ERROR }));
    },
  });
};

const lauchReviewCycleApi = ({ reviewCycleId }) => {
  const url = endpoints.reviewCreationV2.launchReviewCycle;

  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: { reviewCycleId },
  });
};

export const useLaunchReviewCycle = () => {
  const toast = useToast();
  // const queryClient = useQueryClient();
  const history = useHistory();

  const { reviewCycleId } = useReviewCreationV1Data();

  return useMutation({
    mutationFn: () =>
      lauchReviewCycleApi({
        reviewCycleId,
      }),
    onSuccess: () => {
      // queryClient.removeQueries([REACT_QUERY_KEYS.reviewCreationV2.root]);

      goToDeerModuleSettingsPage({
        history,
        module: L2_VALUES.review,
        activeTabId: 'review_cycles',
      });
    },
    onError: (error: { body?: { message?: string } }) => {
      const errorMessage = error.body?.message ?? 'Could not launch review cycle!';
      toast({ title: errorMessage, status: 'error' });
    },
  });
};

const getReviewCycleValidation = ({ reviewCycleId }) => {
  const url = endpoints.reviewCreationV2.validation;
  return sendRequest({
    url,
    credentials: 'include',
    queryParams: {
      reviewCycleId,
    },
  });
};

const useGetReviewCycleValidation = ({ reviewCycleId }) =>
  useQuery(
    [REACT_QUERY_KEYS.reviewCreation.validation, reviewCycleId],
    () => getReviewCycleValidation({ reviewCycleId }).then((res) => res.entity),
    { enabled: !!reviewCycleId }
  );

export const useGetGoalsMissing = () => {
  const { reviewCycleId } = useReviewCreationV1Data();
  const { data: validation, isLoading, isSuccess } = useGetReviewCycleValidation({ reviewCycleId });

  return {
    data: validation?.employeesWithoutGoals || [],
    isLoading,
    isSuccess,
  };
};

export const useManagerMissing = () => {
  const { reviewCycleId } = useReviewCreationV1Data();

  const { data: validation, isLoading, isSuccess } = useGetReviewCycleValidation({ reviewCycleId });

  return {
    data: validation?.employeesWithoutManager || [],
    isLoading,
    isSuccess,
  };
};

// ----------------------  Invalidators -------------------------------------

export const useInvalidateTimelineRemindersPanelData = () => {
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: [REACT_QUERY_KEYS.reviewCreationV2.timelineReminders],
    });
};
