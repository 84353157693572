/* eslint-disable arrow-body-style */
import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ChevronRight } from '@mesh.ai/rabbit';

import { TextBodyMedium } from 'uiToolkitV1/Typography';

const StyledCategoryItem = styled(Box)`
  &:hover {
    background-color: #e9f8fc;
    transition: 0.1s ease-in-out;
  }
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 0px 8px 4px;
`;

const CategoryWrapper = ({ handleCategorySelect, categoryList, selectedCategory }) => {
  return (
    <Box flexGrow={1} overflow="auto">
      {categoryList.map((category) => {
        const { label, subItemCount, id } = category;
        const isSelectedCategory = id === selectedCategory?.id;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <StyledCategoryItem
            onClick={() => handleCategorySelect(category)}
            key={id}
            backgroundColor={isSelectedCategory ? '#cae8f0' : ''}
          >
            <TextBodyMedium>{`${label} (${subItemCount || '0'})`}</TextBodyMedium>
            <Box my="auto">
              <ChevronRight color={isSelectedCategory ? '#0A7795' : '#666666'} />
            </Box>
          </StyledCategoryItem>
        );
      })}
    </Box>
  );
};

export default CategoryWrapper;
