import { sendRequest } from 'api/sendRequest';
import { check1on1V2Enabled } from 'utils/helpers/featureFlags';

import { MODULE_KEYS1 } from 'constants/ProjectConstants';
import { endpoints } from 'constants/endpoints';

export const getViewsKeysApi = ({ pageType, reviewId, baseCohortId = null }) => {
  const url = endpoints.views.cohort.values;

  const queryParams = {
    pageType,
    reviewCycleId: reviewId,
  };
  if (baseCohortId) {
    queryParams.baseCohortId = baseCohortId;
  }
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams,
  });
};

export const getBaseCohortsApi = ({ pageType }) => {
  const url = endpoints.views.cohort.baseCohorts;

  const queryParams = {
    pageType,
  };

  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams,
  });
};

export const getManagersListApi = ({ baseCohortId, pageType }) => {
  const url = endpoints.views.getManagerList;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      pageType,
      baseCohortId,
    },
  });
};

export const getOrganizationFunctionsApi = ({ baseCohortId, pageType }) => {
  const url = endpoints.views.getFunctionTree;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      pageType,
      baseCohortId,
    },
  });
};

export const getReviewFiltersApi = () => {
  const url = endpoints.analytics.review.filtersV1;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
};

export const getPerformanceGridDataApi = ({ cohort, source }) => {
  const url = endpoints.analytics.review.calibration.performanceGridV1;
  // https://linear.app/peoplemesh/issue/ENG-17343/update-review-page-apis-request-to-filter-reviewtype
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reviewType, ...sourceWithoutReviewType } = source ?? {};
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: {
      cohortId: cohort?.id,
      reviewCycleId: source?.id,
    },
  });
};

export const getEngagementFiltersApi = () => {
  const url = endpoints.analytics.engagement.filtersV1;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
  });
};

export const getOrganizationTagsApi = ({ teamId, tagType }) => {
  const url = endpoints.admin.organizationTag;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      tagType,
      teamId,
    },
  });
};

export const getEmployeesApi = ({ moduleType, cohortId }) => {
  const url = endpoints.views.employees;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    queryParams: {
      moduleType,
      cohortId,
    },
  });
};

export const saveView = (view, moduleType, isLastUsed) => {
  const url = endpoints.views.cohort.save;
  return sendRequest({
    url,
    method: 'POST',
    credentials: 'include',
    body: view,
    queryParams: {
      moduleType,
      isLastUsed,
    },
  });
};

export const editView = (id, view, moduleType) => {
  const url = `${endpoints.views.cohort.edit}/${id}`;
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    body: view,
    queryParams: {
      moduleType,
    },
  });
};

export const deleteView = ({ viewId }) => {
  const url = endpoints.views.cohort.delete;
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    queryParams: {
      cohortId: viewId,
    },
  });
};

export const getViews = ({ moduleType }) => {
  const url = endpoints.views.cohort.getAll;
  return sendRequest({
    url,
    method: 'GET',
    credentials: 'include',
    queryParams: {
      moduleType,
    },
  });
};

export const parseUploadEmployeeListCsv = ({ fileObject }) => {
  const url = endpoints.views.parseEmployeeCsv;
  const data = new FormData();
  data.append('file', fileObject);
  return sendRequest({
    url,
    method: 'PUT',
    credentials: 'include',
    body: data,
    isMultiPart: true,
    // queryParams: {
    //   form_id: formId
    // }
  });
};

export function getModuleSidebarNavCohortListApi({ moduleType }) {
  if (moduleType === MODULE_KEYS1.ONE_ON_ONE && check1on1V2Enabled()) {
    moduleType = `${MODULE_KEYS1.ONE_ON_ONE}V2`;
  }
  const url = endpoints.views.cohortList;
  return sendRequest({
    url,
    queryParams: { moduleType },
    credentials: 'include',
  });
}

export const getViewById = ({ id, moduleType }) => {
  const url = `${endpoints.views.cohort.getById}/${id}`;
  return sendRequest({
    url,
    queryParams: { moduleType },
    credentials: 'include',
  });
};

export const getFilteredEmployeesCount = ({ moduleType, view }) => {
  const url = endpoints.views.getFilteredEmployeeCount;
  return sendRequest({
    url,
    method: 'POST',
    body: view,
    queryParams: { moduleType },
    credentials: 'include',
  });
};
