import React, { useMemo } from 'react';

import { Button as ChakraButton, useTheme, forwardRef } from '@chakra-ui/react';
import styled from '@emotion/styled';
// import { Link } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { TextButton } from 'uiToolkitV1/Typography';

export const BUTTON_VARIANT = {
  filled: 'filled',
  outlined: 'outlined',
  // designer ki wajah se h
  outlinedSmall: 'outlinedSmall',
  tertiary: 'tertiary',
  rowButtonOutlined: 'rowButtonOutlined',
  rowButtonTertiary: 'rowButtonTertiary',
};

export const BUTTON_SIZE = {
  regular: 'regular',
};

export const BUTTON_COLORS = {
  blue: 'blue',
  red: 'red',
  green: 'green',
  black: 'black',
};

const TransientProps = [
  'size',
  'isIconOnlyButton',
  'isLeftIcon',
  'buttonColor',
  'isRightIcon',
  'withBackground',
  'isDropdown',
];

const getBaseButtonStyles = (theme, props) => ({
  boxShadow: props?.boxShadow ?? 'none !important', // <- important to remove from active state of button

  height: props.height ?? (props.withBackground ? '40px' : 'auto'),
  textTransform: 'none',
  padding: props.padding || props.withBackground ? '8px' : 0,
  borderRadius: '4px', // <- need to discuss button size
  border: 0,
  minWidth: 'auto',

  '& .chakra-button__icon': {
    margin: '0',
  },
  '&:hover:enabled ': {
    cursor: 'pointer',
    backgroundColor: theme.colors.button[props.buttonColor].hover,
    '& svg': {
      color: props.svgHoverColor ? props.svgHoverColor : theme.colors.icon.white,
    },
  },

  '&:active:enabled, &.active-styles': {
    backgroundColor: theme.colors.button[props.buttonColor].focus,
    '& svg': {
      color: theme.colors.icon.white,
    },
  },

  '&:disabled': {
    backgroundColor: theme.colors.button[props.buttonColor].disabled,
    opacity: '1',
    '&>*': {
      opacity: '50%',
    },
  },
});

const StyledButton = styled(ChakraButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  ...getBaseButtonStyles(theme, props),
  backgroundColor: theme.colors.button[props.buttonColor].default,
  '&:hover:disabled': {
    backgroundColor: theme.colors.button[props.buttonColor].disabled,
  },
}));

const OutlineButton = styled(ChakraButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  ...getBaseButtonStyles(theme, props),
  backgroundColor: 'transparent',
  '&>p': {
    color: theme.colors.button[props.buttonColor].default,
  },
  border: `1px solid ${theme.colors.button[props.buttonColor].default}`,
  '&:active:enabled': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    color: theme.colors.button[props.buttonColor].default,
  },
  '&:hover:enabled, &.hovering ': {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    borderColor: theme.colors.button[props.buttonColor].hover,
    '&>p': {
      color: theme.colors.button[props.buttonColor].hover,
    },
    '& svg': {
      color: theme.colors.button[props.buttonColor].hover,
    },
  },
  '&:active:enabled, &.active-styles': {
    borderColor: theme.colors.button[props.buttonColor].focus,
    '&>p': {
      color: theme.colors.button[props.buttonColor].focus,
    },
    '& svg': {
      color: theme.colors.button[props.buttonColor].focus,
    },
  },
  '&:disabled': {
    borderColor: theme.colors.button[props.buttonColor].disabled,
    '&>p': {
      color: theme.colors.button[props.buttonColor].disabled,
    },
    '& svg': {
      color: theme.colors.button[props.buttonColor].disabled,
    },
    opacity: '1',
    '&>*': {
      opacity: '50%',
    },
  },
}));

const OutlineButtonSmall = styled(OutlineButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  padding: '0',
  height: 'min-content',
}));

const TertiaryButton = styled(ChakraButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  ...getBaseButtonStyles(theme, props),
  backgroundColor: 'transparent',
  '&>p': {
    color: theme.colors.button[props.buttonColor].default,
  },
  '&:active:enabled': {
    backgroundColor: 'transparent',
  },
  '& svg': {
    color: theme.colors.button[props.buttonColor].default,
  },
  '&:hover:enabled, &.hovering ': {
    cursor: 'pointer',
    backgroundColor: props.isDropdown ? theme.colors.button.blue.dropdownHover : 'transparent', // hover colors
    '&>p': {
      color: theme.colors.button[props.buttonColor].hover,
    },
    '& svg': {
      color: theme.colors.button[props.buttonColor].hover,
    },
  },
  '&:active:enabled, &.active-styles': {
    '&>p': {
      color: theme.colors.button[props.buttonColor].focus,
    },
    backgroundColor: props.isDropdown ? theme.colors.button.blue.dropdownHover : 'transparent', // hover colors

    '& svg': {
      color: theme.colors.button[props.buttonColor].focus,
    },
  },
  '&:disabled': {
    '&>p': {
      color: theme.colors.button[props.buttonColor].disabled,
    },
    '& svg': {
      color: theme.colors.button[props.buttonColor].disabled,
    },
    opacity: '1',
    '&>*': {
      opacity: '50%',
    },
  },
}));

const RowButtonOutlined = styled(OutlineButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  '&:active:enabled': {
    backgroundColor: theme.colors.functional.E6F1FD,
  },

  '&:hover:enabled, &.hovering ': {
    backgroundColor: theme.colors.functional.E6F1FD,
  },

  height: '28px',
  padding: '2px 8px 2px 2px',
}));

const RowButtonTertiary = styled(TertiaryButton, {
  shouldForwardProp: (prop) => !TransientProps.includes(prop),
})(({ theme, ...props }) => ({
  '&:active:enabled': {
    backgroundColor: theme.colors.functional.E6F1FD,
  },

  '&:hover:enabled, &.hovering ': {
    backgroundColor: theme.colors.functional.E6F1FD,
  },

  height: '28px',
  padding: '2px 8px 2px 2px',
}));

export const Button = forwardRef(
  (
    {
      children,
      variant = BUTTON_VARIANT.filled,
      size = BUTTON_SIZE.regular,
      color = BUTTON_COLORS.blue,
      StartIcon,
      startIconProps = {},
      EndIcon,
      endIconProps = {},
      // selected means render in active state
      selected = false,
      className = '',
      withBackground = true,
      isLink = false,
      link,
      iconSize = '24px',
      isDropdown = false,
      ...props
    },
    ref
  ) => {
    let ButtonComp = null;
    let StartIconComp;
    let EndIconComp;
    let buttonColor = null;
    const isIconOnlyButton = !children;
    const isLeftIcon = !!StartIcon;
    const isRightIcon = !!EndIcon;
    const isTextOnlyButton = !StartIcon && !EndIcon;

    let iconMargin = 0;

    if (
      [BUTTON_VARIANT.rowButtonOutlined, BUTTON_VARIANT.rowButtonTertiary, BUTTON_VARIANT.outlinedSmall].includes(
        variant
      )
    ) {
      iconSize = '16px';
      iconMargin = 1;
    }

    const theme = useTheme();

    switch (variant) {
      case BUTTON_VARIANT.outlined:
        ButtonComp = OutlineButton;
        break;
      case BUTTON_VARIANT.outlinedSmall:
        ButtonComp = OutlineButtonSmall;
        break;
      case BUTTON_VARIANT.tertiary:
        ButtonComp = TertiaryButton;
        break;
      case BUTTON_VARIANT.rowButtonOutlined:
        ButtonComp = RowButtonOutlined;
        break;
      case BUTTON_VARIANT.rowButtonTertiary:
        ButtonComp = RowButtonTertiary;
        break;
      case BUTTON_VARIANT.filled:
        ButtonComp = StyledButton;
        break;
      default:
        ButtonComp = StyledButton;
        console.error(`Error: Invalid button variant : ${variant} . Pick one from [${Object.keys(BUTTON_VARIANT)}]`);
    }

    switch (color) {
      case BUTTON_COLORS.blue:
      case BUTTON_COLORS.red:
      case BUTTON_COLORS.green:
      case BUTTON_COLORS.black:
        buttonColor = color;
        break;
      default:
        console.error(`Error: Invalid button color : ${color} . Pick one from [${Object.keys(BUTTON_COLORS)}]`);
        buttonColor = BUTTON_COLORS.blue;
    }

    if (StartIcon) {
      StartIconComp = (
        <StartIcon color={theme.colors.icon.white} boxSize={iconSize} {...startIconProps} m={iconMargin} />
      );
    }

    if (EndIcon) {
      EndIconComp = <EndIcon color={theme.colors.icon.white} boxSize={iconSize} m={iconMargin} {...endIconProps} />;
    }

    const TextLabelComp = TextButton;

    const labelMargins = useMemo(() => {
      if (variant === BUTTON_VARIANT.outlinedSmall) {
        if (!children) {
          // in case of icon only button
          return 0;
        }
        if (isTextOnlyButton) {
          // in case of text only button
          return '0 6px';
        }
        if (isLeftIcon && !isRightIcon) {
          return '0 6px 0 0';
        }
        // EndIcon case
        return '0 0 0 6px';
      }

      if (
        [
          BUTTON_VARIANT.rowButtonOutlined,
          BUTTON_VARIANT.rowButtonTertiary,
          // BUTTON_VARIANT.outlinedSmall
        ].includes(variant)
      ) {
        return 0;
      }

      if (!children) {
        // in case of icon only button
        return 0;
      }
      if (isTextOnlyButton) {
        // in case of text only button
        return '0 8px';
      }
      if (isLeftIcon && !isRightIcon) {
        return '0 8px 0 8px';
      }
      // EndIcon case
      return '0 8px 0 8px';
    }, [children, isTextOnlyButton, isLeftIcon, isRightIcon]);

    const buttonComp = (
      <ButtonComp
        size={size}
        leftIcon={StartIconComp}
        rightIcon={EndIconComp}
        isIconOnlyButton={isIconOnlyButton}
        isLeftIcon={isLeftIcon}
        isRightIcon={isRightIcon}
        className={selected ? `${className} active-styles` : className}
        buttonColor={buttonColor}
        withBackground={withBackground}
        isDropdown={isDropdown}
        ref={ref}
        {...props}
      >
        <TextLabelComp color="white" m={labelMargins}>
          {children}
        </TextLabelComp>
      </ButtonComp>
    );

    if (isLink) {
      return <Link to={link}>{buttonComp}</Link>;
    }

    return buttonComp;
  }
);
