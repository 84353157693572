import { useMutation, useQuery } from '@tanstack/react-query';
import { L3_PATHS } from 'Deer/constants/routes';
import { sendRequest } from 'api/sendRequest';
import { useToast } from 'components/hooks/useToast';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setReviewCreationKey, useReviewCreationV1Data, AUTO_UPDATE_STATUSES } from 'reducers/reviewCreationV1Slice';

import { endpoints } from 'constants/endpoints';
import { REACT_QUERY_KEYS } from 'constants/reactQueryKeys';

import useReviewCreationFormId from '../hooks/useReviewCreationFormId';
import { useInvalidateTimelineRemindersPanelData } from './timelineAndRemindersSectionHooks';

export const createReviewApi = () =>
  sendRequest({
    url: endpoints.reviewCreationV2.create,
    method: 'POST',
    credentials: 'include',
  }).then((res) => res.entity);

export const useCreateNewReviewMutation = () => {
  const toast = useToast();
  const history = useHistory();

  return useMutation({
    mutationFn: createReviewApi,
    onSuccess: (data) => {
      history.push(L3_PATHS.company.review.reviewCreation.replace(':reviewId', data?.reviewCycleId));
    },
    onError: (data) => {
      toast({
        title: data?.message || 'New review creation failed, please try again',
        status: 'error',
      });
    },
  });
};

const getReviewCycleMetadata = ({ reviewCycleId }) =>
  sendRequest({
    url: endpoints.reviewCreationV2.getReviewMetadata,
    credentials: 'include',
    queryParams: {
      reviewCycleId,
    },
  }).then((res) => res.entity);

export const useGetReviewCycleMetadata = () => {
  const dispatch = useDispatch();

  const reviewCycleId = useReviewCreationFormId();
  return useQuery(
    [REACT_QUERY_KEYS.reviewCreationV2.reviewData, reviewCycleId],
    () =>
      getReviewCycleMetadata({ reviewCycleId }).then((data) => {
        dispatch(setReviewCreationKey(data));
        return data;
      }),
    { enabled: !!reviewCycleId }
  );
};

const updateNameApi = ({ reviewCycleId, reviewCycleName }) =>
  sendRequest({
    url: endpoints.reviewCreationV2.updateName,
    method: 'PUT',
    body: {
      reviewCycleId,
      reviewCycleName,
    },
    credentials: 'include',
  }).then((res) => res.entity);

export const useUpdateReviewName = () => {
  const { reviewCycleId } = useReviewCreationV1Data();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ reviewCycleName }) => updateNameApi({ reviewCycleId, reviewCycleName }),

    onMutate: () => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_SAVING }));
    },
    onSuccess: (data) => {
      setTimeout(() => dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED })), 500);
    },
    onError: (data) => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_ERROR }));
    },
  });
};

const updateTypeApi = ({ reviewCycleId, reviewType }) =>
  sendRequest({
    url: endpoints.reviewCreationV2.updateType,
    method: 'PUT',
    body: {
      reviewCycleId,
      reviewType,
    },
    credentials: 'include',
  }).then((res) => res.entity);

export const useUpdateReviewType = () => {
  const { reviewCycleId } = useReviewCreationV1Data();
  const invalidateTimelineReminders = useInvalidateTimelineRemindersPanelData();

  const dispatch = useDispatch();

  return useMutation({
    mutationFn: ({ reviewType }) => updateTypeApi({ reviewCycleId, reviewType }),

    onMutate: () => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_SAVING }));
    },
    onSuccess: (data) => {
      invalidateTimelineReminders();

      setTimeout(() => dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.UPDATED })), 500);
    },
    onError: (data) => {
      dispatch(setReviewCreationKey({ autoUpdateStatus: AUTO_UPDATE_STATUSES.IS_ERROR }));
    },
  });
};

export const saveParticipantsApi = (payload) =>
  sendRequest({
    url: endpoints.reviewCreationV2.saveReviewCycleParticipants,
    credentials: 'include',
    method: 'PUT',
    body: payload,
  }).then((res) => res.entity);

export const downloadSelectedParticipantsCSVApi = async (queryParams) => {
  const url = endpoints.reviewCreationV2.downloadSelectedParticipantsCSV;
  try {
    const res = await sendRequest({
      url,
      method: 'GET',
      credentials: 'include',
      queryParams,
    });

    // Get the file content (base64) and file name from the response
    const { fileContent, fileType } = res?.entity || {};

    if (!fileContent) {
      throw new Error('No file content or file name found in the response');
    }

    // Decode base64 to binary data
    const binaryData = atob(fileContent);

    // Create a Uint8Array to hold the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([uint8Array], { type: fileType });

    const link = document.createElement('a');
    const participantsSampleFileName = 'EmployeeCSVSample';
    link.href = URL.createObjectURL(blob);
    link.download = participantsSampleFileName;
    link.click();
  } catch (err) {
    console.log(err.message);
  }
};
