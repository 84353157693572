/* eslint-disable default-case */

/* eslint-disable arrow-body-style */

/* eslint-disable react/jsx-no-undef */
import { useEffect, useRef, useState } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import SearchBar from 'components/RabbitOKRSidePanel/Components/SearchBar';

import { Checkbox } from 'uiToolkitV1/Checkbox';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

const MultiSelectOptions = ({ options, handleCheckbox, selectedCategory }) => {
  const [searchText, setSearchText] = useState('');
  const optionsListRef = useRef(null);

  const handleInputChange = (e) => {
    setSearchText(e?.target?.value);
  };

  const clearSearchText = () => {
    setSearchText('');
  };

  useEffect(() => {
    clearSearchText();
  }, [selectedCategory]);

  const filteredSearchOptions = options?.filter(
    (option) => option?.label?.toLowerCase().includes(searchText?.toLowerCase())
  );

  const displayOptions = searchText ? filteredSearchOptions : options;

  return (
    <Flex flexDirection="column">
      <Box>
        <SearchBar
          value={searchText}
          placeholder="Search"
          onChange={(e) => handleInputChange(e)}
          searchRef={optionsListRef}
          onClearInput={clearSearchText}
        />
      </Box>

      <Box maxH="280px" overflow="auto">
        {displayOptions &&
          displayOptions.map((option) => {
            const { id, label, isSelected } = option;
            return (
              <Flex key={id} flexDirection="row" padding="8px 4px">
                <Checkbox
                  isChecked={isSelected}
                  onChange={(e) => handleCheckbox(e?.target?.checked, label)}
                  disabled={false}
                  id={label}
                  colorScheme="#0D819F"
                  borderColor={isSelected ? '#0D819F' : '#666666'}
                  color="default"
                />
                <TextBodyMedium>{label}</TextBodyMedium>
              </Flex>
            );
          })}
      </Box>
    </Flex>
  );
};

export default MultiSelectOptions;
