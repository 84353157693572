import * as Sentry from '@sentry/react';
import { stringify } from 'query-string';

import { endpoints } from 'constants/endpoints';

// TODO: if error is not authenticated, redirect the user to login page? and otherwise throw error?
export const sendRequest = ({
  url,
  method = 'GET',
  credentials = null,
  body = undefined,
  headers = {},
  queryParams = null,
  isMultiPart = false,
  noHeaders = false,
}) => {
  let mergedHeaders;
  let modifiedUrl = url;
  if (isMultiPart) mergedHeaders = new Headers({ ...headers });
  else mergedHeaders = new Headers({ 'content-type': 'application/json', ...headers });
  if (noHeaders) mergedHeaders = {};

  // don't stringify when uploading form data
  const options = {
    method,
    headers: mergedHeaders,
    body: body ? (isMultiPart ? body : JSON.stringify(body)) : null,
  };
  if (credentials) options.credentials = credentials;
  if (queryParams) {
    modifiedUrl = `${url}?${stringify(queryParams)}`;
  }

  return fetch(modifiedUrl, options)
    .then(
      (res) => {
        if (res.ok) {
          if (res.headers.get('Content-Type')?.indexOf('application/json') > -1) return res.json();
          return res;
        }
        return res.json().then((json) => {
          try {
            const err = new Error('');
            err.message = json.message;
            err.errorType = json.error;
            err.status = res.status;
            err.statusText = res.statusText;
            throw err;
          } catch (err) {
            sendSentryError({
              err,
              name: `[SEV2]: API request failure`,
              url,
              queryParams,
              body,
            });
          }

          return Promise.reject({
            status: res.status,
            ok: false,
            message: json.message,
            body: json,
            handled: true,
          });
        });

        // return res.json().then(function(data) {
        //   throw new Error(data ? data.message : 'Sorry an error occured');
        // });
      },
      (res) => {
        console.error(res);
        // fetch rejects only on network failure
        return Promise.reject({
          ok: false,
          message: 'Could not complete the request! Please check your connection and try again.',
          handled: true,
        });
      }
    )
    .catch((err) => {
      // fetch has failed here. throw same error.
      if (!err.handled) {
        sendSentryError({
          err,
          name: `[SEV2]: Failed to fetch`,
          url,
          queryParams,
          body,
        });
      }

      throw err;
    });
};

const sendSentryError = ({ err, url = '', queryParams, body, name }) => {
  // filter out authenticate request. We don't need to send authenticate fail to sentry
  if (isAuthenticateRequest(url)) return;

  err.message = `${err?.message} : ${url}`;
  err.name = name;
  Sentry.withScope((scope) => {
    scope.setExtras({
      queryParams: JSON.stringify(queryParams),
      requestBody: JSON.stringify(body),
      httpErrorCode: err?.status,
      HttpErrorStatusText: err?.statusText,
      errorType: err?.errorType,
    });
    scope.setTag('endpoint', url);
    scope.setTag('severity', 2);
    scope.setTag('HttpErrorCode', err?.status);
    scope.setTag('HttpErrorStatusText', err?.statusText);
    scope.setTag('errorType', err?.errorType);
    scope.setLevel('error');
    Sentry.captureException(err);
  });
};

const isAuthenticateRequest = (url) => endpoints.employee.login === url;
