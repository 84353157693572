import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from 'Deer/intent/Reviews/slice/ReviewsIntentSlice';
import { IntentValuesType } from 'Deer/intent/types/IntentCommonTypes';
import { useSelector } from 'react-redux';

export type GoalsIntentType = {
  label: string;
  onClick: () => void;
  visible: () => boolean;
  value: {
    myData?: IntentValuesType;
    team?: {
      directReports: IntentValuesType;
      allReports: IntentValuesType;
      view?: IntentValuesType;
    };
    company?: IntentValuesType;
  };
  applicableGroupByValues?: {
    value: string;
    label: string;
  }[];
  showGroupByDropdown?: boolean;
  applicableLayoutValues?: string[];
  showLayoutDropdown?: boolean;
  applicableOKRViewValues?: {
    value: string;
    label: string;
    onClick: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Icon: (props: any) => React.JSX.Element;
  }[];
  showOKRViewDropdown?: boolean;
  showTimelineDropdown?: boolean;
  category: 'Manage' | 'Analyze' | 'Checkin' | 'Configure' | 'Create';
  showGoalOwnershipFilter?: boolean;
  isDefault?: boolean;
  timelineDropdownOnChangeCallback?: (onChange: () => void) => void;
  ownershipDropdownOnChangeCallback?: (onChange: () => void) => void;
};

const initialState: {
  selectedIntent: null | GoalsIntentType;
  selectedCompanies: Company[];
  allCompanies: Company[];
  selectedLocations: string[];
  allLocations: string[];
} = {
  selectedIntent: null,
  selectedCompanies: [],
  allCompanies: [],
  selectedLocations: [],
  allLocations: [],
};

export const goalsIntentSlice = createSlice({
  name: 'goalsIntent',
  initialState,
  reducers: {
    setSelectedGoalsIntent: (state, action: PayloadAction<GoalsIntentType>) => {
      const newState = { ...state };
      newState.selectedIntent = action.payload;
      return newState;
    },
    setSelectedCompanies: (state, action: PayloadAction<Company[]>) => {
      state.selectedCompanies = action.payload;
    },
    setAllCompanies: (state, action: PayloadAction<Company[]>) => {
      state.allCompanies = action.payload;
    },
    setSelectedLocations: (state, action: PayloadAction<string[]>) => {
      state.selectedLocations = action.payload;
    },
    setAllLocations: (state, action: PayloadAction<string[]>) => {
      state.allLocations = action.payload;
    },
  },
});

export const { setSelectedGoalsIntent, setSelectedCompanies, setAllCompanies, setSelectedLocations, setAllLocations } =
  goalsIntentSlice.actions;

export default goalsIntentSlice.reducer;

export const useSelectGoalsSelectedIntent = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.selectedIntent);

export const useSelectedGoalsCompanies = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.selectedCompanies);

export const useAllGoalsCompanies = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.allCompanies);

export const useSelectedGoalsLocations = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.selectedLocations);

export const useAllGoalsLocations = () =>
  useSelector((state: { goalsIntent: typeof initialState }) => state.goalsIntent.allLocations);
