import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import female from 'assets/images/femaleProfilePic.png';
import female2 from 'assets/images/dummyProfileWoman1.svg';
import female3 from 'assets/images/dummyProfileWoman2.svg';
import male from 'assets/images/maleProfilePic.png';
import monk from 'assets/images/monk.png';
import monk2x from 'assets/images/monk@2x.png';
// all main mesh in icon imports
import deliver from 'assets/images/deliver.svg';
import develop from 'assets/images/develop.png';
import RulerIcon from 'assets/images/ruler.png';
import LogoWithoutName from 'assets/images/logo-without-name.png';
import DeliverableSvgI from 'assets/images/deliver.svg?react';
import DevelopSvgI from 'assets/images/develop.svg?react';

import LogoSvg from 'assets/images/logo.svg?react';
import MeshUpSvg from 'assets/images/meshUp.svg?react';
import MeshUpSelectedSvg from 'assets/images/meshUpSelected.svg?react';
import  PortfolioSvg from 'assets/images/portfolio.svg?react';
import  TeamSummarySvg from 'assets/images/portfolio.svg?react';
import MailCircular from 'assets/images/mailCircular.svg?react';
import MeshInSvg from 'assets/images/meshIn.svg?react';
import PortfolioSelectedSvg  from 'assets/images/portfolioSelected.svg?react';
import TeamSummarySelectedSvg from 'assets/images/portfolioSelected.svg?react';
import MyTeamSvg from 'assets/images/myTeam.svg?react';
import AddMoolahSvg from 'assets/images/addMoolah.svg?react';
import StrengthSvg from 'assets/images/strength.svg?react';
import ExperienceSvg from 'assets/images/drive.svg?react';
import MoolahSvg from 'assets/images/moolah.svg?react';
import GroupMoolahSvg from 'assets/images/groupMoolah.svg?react';
import ExpandSvg from 'assets/images/expand.svg?react';
import HomeSvg from 'assets/images/home.svg?react';
import HomeSelectedSvg from 'assets/images/homeSelected.svg?react';
import NotificationSvg from 'assets/images/notification.svg?react';
import Notification1Svg from 'assets/images/notification1.svg?react';
import ConnectBadgeOutsetSvg from 'assets/images/badges/connectBadgeOutset.svg?react';
import ReflectBadgeOutsetSvg from 'assets/images/badges/reflectBadgeOutset.svg?react';
import GrowBadgeOutsetSvg from 'assets/images/badges/growBadgeOutset.svg?react';
import LoginBadgeOutsetSvg from 'assets/images/badges/loginBadgeOutset.svg?react';
import TeamSvg from 'assets/images/team.svg?react';
import InsightsSvg from 'assets/images/insights.svg?react';
import AddCircularSvg from 'assets/images/addCircular.svg?react';
import KeySvg from 'assets/images/key.svg?react';
import GoogleSvg from 'assets/images/google.svg?react';
import HierarchySvg from 'assets/images/hierarchy.svg?react';
import HierarchyTwoChildSvg from 'assets/images/hierarchy-twoChild.svg?react';
import FilterIconSvg from 'assets/images/filter.svg?react';
import ChatBubbleIconSvg from 'assets/images/bubble-chat.svg?react';
import ChatBubbleFilledIconSvg from 'assets/images/bubble-chat-filled.svg?react';
import GroupSvg from 'assets/images/group-new.svg?react';
import TargetSvg from 'assets/images/target1.svg?react';
import AddUserSvg from 'assets/images/add-user.svg?react';

// Emojis
import HappyEmojiSvg from 'assets/images/emojis/happy.svg?react';
import SmartEmojiSvg from 'assets/images/emojis/smart.svg?react';
import VeryHappyEmojiSvg from 'assets/images/emojis/veryHappy.svg?react';
import SadEmojiSvg from 'assets/images/emojis/sad.svg?react';
import AngryEmojiSvg from 'assets/images/emojis/angry.svg?react';
import HappyColoredEmojiSvg from 'assets/images/emojis/happyColored.svg?react';
import SmartColoredEmojiSvg from 'assets/images/emojis/smartColored.svg?react';
import VeryHappyColoredEmojiSvg from 'assets/images/emojis/veryHappyColored.svg?react';
import SadColoredEmojiSvg from 'assets/images/emojis/sadColored.svg?react';
import AngryColoredEmojiSvg from 'assets/images/emojis/angryColored.svg?react';
import ThickOutlineSmileEmojiSvg from 'assets/images/emojis/thickOutlineSmile.svg?react';
import EmojiSmileSvg from 'assets/images/emojis/emojiSmile.svg?react';

import HeartPulseSvg from 'assets/images/heart-rate.svg?react';
import AnonymousSvg from 'assets/images/anonymous.svg?react';
import QuestionSvg from 'assets/images/question.svg?react';
import SignatureSvg from 'assets/images/signature.svg?react';
import PastSvg from 'assets/images/past.svg?react';
import PulseSvg from 'assets/images/pulse.svg?react';
import DotsSvg from 'assets/images/dots.svg?react';
import ListSvg from 'assets/images/list.svg?react';
import WinnerCupSvg from 'assets/images/winnerCup.svg?react';
import GlobeSvg from 'assets/images/globe.svg?react';
import PictureSvg from 'assets/images/pictureIcon.svg?react';
import CrossSvg from 'assets/images/cross.svg?react';
import CrossThickSvg from 'assets/images/cross-thick.svg?react';
import PlaySvg from 'assets/images/play.svg?react';
import PanningNavigationSvg from 'assets/images/panning-navigation.svg?react';

import ThumbsDown from 'assets/images/ThumbsDown';
import LogoGif from 'assets/gifs/logo.gif';
import ThumbsUpSvg from 'assets/images/thumbsUp.svg?react';
import BadgeContainer from 'components/BadgeContainer';
import { IconSizesCSS, GutterCSS, CircularIconCSS, PaddingCSS } from 'styles/common';
// ---------------------------- strength icons -----------------------------------
import  AccountabilityEffortSvg  from 'assets/images/accountabilityEffortStrength.svg?react';
import DriveCompetitivenessSvg from 'assets/images/driveCompetitivenessStrength.svg?react';
import RelationshipStrengthSvg from 'assets/images/relationshipStrength.svg?react';
import MentoringStrengthSvg from 'assets/images/mentoringStrength.svg?react';
import LearningStrengthSvg from 'assets/images/learningStrength.svg?react';
import InsightsStrengthSvg from 'assets/images/insightsStrength.svg?react';
import InnovationStrengthSvg from 'assets/images/innovationStrength.svg?react';
import InfluenceStrengthSvg from 'assets/images/influenceStrength.svg?react';
import HarmonyStrengthSvg from 'assets/images/harmonyStrength.svg?react';
import FocusStrengthSvg from 'assets/images/focusStrength.svg?react';
import Deadline1Svg from 'assets/images/deadline1.svg?react';
import Deadline2Svg from 'assets/images/deadline2.svg?react';
import InterviewSvg from 'assets/images/interview.svg?react';
import ManagementSvg from 'assets/images/management.svg?react';
import CustomerFeedbackSvg from 'assets/images/customer-feedback.svg?react';
import GoalsSvg from 'assets/images/goals.svg?react';

import Download1Svg from 'assets/images/download1.svg?react';
import UninstallSvg from 'assets/images/uninstall.svg?react';
import ReviewFilledSvg from 'assets/images/review-filled.svg?react';
import GoalsMiniSvg from 'assets/images/goalsMini.svg?react';
import UpdatedSvg from 'assets/images/updated.svg?react';
import CheckMarkSvg from 'assets/images/check-mark.svg?react';
import CheckListSvg from 'assets/images/checklist.svg?react';
import ResponsiveSvg from 'assets/images/responsive.svg?react';
import PaperSvg from 'assets/images/paper.svg?react';
//-------------------------------------------------------------------------------

// ------------ Commercial Logos ------------------
import AmazonLogo from 'assets/images/amazonLogo.png';
import JabongLogo from 'assets/images/jabongLogo.png';
import MyntraLogo from 'assets/images/myntraLogo.jpg';
import UberLogo from 'assets/images/uberLogo.jpg';
import BookMyShowLogo from 'assets/images/bookmyshow.jpeg';
import FlipkartLogo from 'assets/images/flipkartLogo.png';
import OlaLogo from 'assets/images/olaLogo.png';
import AmazonCircularLogo from 'assets/images/amazonCircularLogo.png';
import SlackLogo from 'assets/images/slackLogo.png';
import MSTeamsLogo from 'assets/images/microsoft-teams.png';
import HrisLogo from 'assets/images/hrisLogo.svg';
import JiraLogo from 'assets/images/jiraLogo.jpeg';
import SalesforceLogo from 'assets/images/salesforce-logo.png';
import DarwinBoxLogo from 'assets/images/darwinbox-logo.png';

// ------------------------------------------------
import LikeIconSvg from 'assets/images/like.svg?react';
import FilledLikeIconSvg from 'assets/images/likeFilled.svg?react';
import CommentIconSvg from 'assets/images/comment.svg?react';
import CommentFilledIconSvg from 'assets/images/commentFilled.svg?react';
import SettingsIconSvg from 'assets/images/settingsIcon.svg?react';
import SettingsFilledSvg from 'assets/images/settings.svg?react';
import SettingsCircularSvg from 'assets/images/settingsCircular.svg?react';

//
import CheckedCircleSvg from 'assets/images/checkedCircle.svg?react';
import UncheckedCircleSvg from 'assets/images/uncheckedCircle.svg?react';

import TeamColoredSvg from 'assets/images/teamColored.svg?react';
import ProblemSvg from 'assets/images/problem.svg?react';
import ApplauseSvg from 'assets/images/applause.svg?react';
import HandSvg from 'assets/images/hand.svg?react';
import ApplauseFilledSvg from 'assets/images/applauseFilled.svg?react';
import ImageIconSvg from 'assets/images/imageIcon.svg?react';
import GifIconSvg from 'assets/images/gifIcon.svg?react';
import UserIconSvg from 'assets/images/user.svg?react';

// ------- material ui icons -------
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import EmployeeIconSvg from 'assets/images/employeeIcon.svg?react';
import CheckIconSvg from 'assets/images/check.svg?react';
import HighLowIconSvg from 'assets/images/highLowIcon.svg?react';
import UpDownIconSvg from 'assets/images/upDownIcon.svg?react';
import OngoingShuttleSvg from 'assets/images/shuttle.svg?react';
import EditSvg from 'assets/images/edit.svg?react';
import DelayedSvg from 'assets/images/delayed.svg?react';
import ProgressSvg from 'assets/images/progress.svg?react';
import LoadingSvg from 'assets/images/loading.svg?react';
import LogoutSvg from 'assets/images/log-out.svg?react';

// Performance review icons
import ReviewNavIconSelectedSvg from 'assets/images/review-header-icon-selected.svg?react';
import NoRecognitionFeedbackPlaceholderSvg from 'assets/images/no-recognition-feedback.svg?react';
import ReviewChartMiniIconSvg from 'assets/images/chart.svg?react';
import GrowthGraphMiniIconSvg from 'assets/images/graph.svg?react';
import ReviewMiniIconSvg from 'assets/images/review.svg?react';
import ReviewTeamStatusIconSvg from 'assets/images/group.svg?react';
import TaskTypeIconSvg from 'assets/images/taskType.svg?react';
import DownloadIconSvg from 'assets/images/download.svg?react';
import PDFFileIconSvg from 'assets/images/pdf-file.svg?react';
import EmployeeIconCircleSvg from 'assets/images/employeeIconCircle.svg?react';
import HashTagIconSvg from 'assets/images/hashTag.svg?react';

// analytics icons
import CloseIconSvg from 'assets/images/close.svg?react';
import HourglassIconSvg from 'assets/images/hourglass.svg?react';
import EmployeeGrowthIconSvg from 'assets/images/growth.svg?react';
import CheckinIconSvg from 'assets/images/check-box.svg?react';
import ShareIconSvg from 'assets/images/users.svg?react';
import FormIconSvg from 'assets/images/form_1.svg?react';
import UserIcon2Svg from 'assets/images/user2_1.svg?react';
import EmailIconSvg from 'assets/images/email.svg?react';
import MIconSvg from 'assets/images/m-icon.svg?react';
import SpeakerIconSvg from 'assets/images/speaker.svg?react';
import PlaceIconSvg from 'assets/images/place_1.svg?react';
import CircularDownloadIconSvg from 'assets/images/downloadCircular.svg?react';
import BarGraphIconSvg from 'assets/images/barGraph.svg?react';
import TimelineGraphIconSvg from 'assets/images/timelineGraph.svg?react';
import ArrowDownwardIconSvg from 'assets/images/arrow-downward.svg?react';
import BackArrowSvg from 'assets/images/backArrow.svg?react';
import DownloadArrowIconSvg from 'assets/images/downloadArrow.svg?react';
import CalendarIconSvg from 'assets/images/calendar.svg?react';
import CloseIconv1Svg from 'assets/images/close_v1.svg?react';
import CheckedCheckbox from 'assets/images/checked_checkbox.svg?react';
import UncheckedCheckbox from 'assets/images/unchecked_checkbox.svg?react';

// continuous feedback icons
import ContinuousFeedbackSvg from 'assets/images/feedback.svg?react';
import ManagerViewSvg from 'assets/images/man-user.svg?react';
import PrivateViewSvg from 'assets/images/padlock.svg?react';
import PrivateManagerViewSvg from 'assets/images/2-users.svg?react';
import OneOnOneSvg from 'assets/images/oneOnOne.svg?react';
import DeleteIconSvg from 'assets/images/delete.svg?react';
import DeleteFilledIconSvg from 'assets/images/delete1.svg?react';
import LinkIconSvg from 'assets/images/link.svg?react';
import PublicViewSvg from 'assets/images/world.svg?react';
import SendTiltedSvg from 'assets/images/send-tilted.svg?react';
import ShareSvg from 'assets/images/Share.svg?react';
import RequestSvg from 'assets/images/Request.svg?react';
import WomanSvg from 'assets/images/woman.svg?react';
import ManSvg from 'assets/images/man.svg?react';
import KudosSvg from 'assets/images/kudos-icon-bw.svg?react';

import StarSvg from 'assets/images/star.svg?react';
import ScaleSvg from 'assets/images/scale.svg?react';
import TextBoxSvg from 'assets/images/textBox.svg?react';
import NumberSvg from 'assets/images/number.svg?react';
import OptionsSvg from 'assets/images/options.svg?react';
import CommentSvg from 'assets/images/comment-colored.svg?react';
import UpdatWeightage from 'assets/images/weightage.svg?react';

// Header Icons
import InsightsHeaderIconSvg from 'assets/images/insightsHeader.svg?react';
import HomeHeaderIconSvg from 'assets/images/homeHeader.svg?react';
import TeamHubHeaderIconSvg from 'assets/images/teamHeader.svg?react';
import PulseHeaderIconSvg from 'assets/images/pulseHeader.svg?react';
import NotificationHeaderIconSvg from 'assets/images/notificationHeader.svg?react';
import ReviewHeaderIconSvg from 'assets/images/reviewHeader.svg?react';
import UserChatBubbleSvg from 'assets/images/userChatBubble.svg?react';
import TasksSvg from 'assets/images/tasks.svg?react';
import PrivateTasksSvg from 'assets/images/PrivateTask.svg?react';
import ArrowDownIconSvg from 'assets/images/arrowDown.svg?react';
import SearchSvg from 'assets/images/search1.svg?react';
import OneOnOneHeaderIconSvg from 'assets/images/friends2.svg?react';
import AnonymousUserIconSvg from 'assets/images/user-silhoutte.svg?react';
import LevelUpIconSvg from 'assets/images/level_up.svg?react';

// Weightage Icons
import EditIconSvg from 'assets/images/editIcon.svg?react';
import ViewIconSvg from 'assets/images/viewIcon.svg?react';

// Nomination Icons
import AddUserSvg2 from 'assets/images/add-user-2.svg?react';
import SeniorsSvg from 'assets/images/Seniors.svg?react';
import JuniorsSvg from 'assets/images/Juniors.svg?react';
import PrimaryTeamSvg from 'assets/images/WithinTeam.svg?react';
import OutsideTeamSvg from 'assets/images/OutsideTeam.svg?react';
import AddPeerSvg from 'assets/images/addPeerIcon.svg?react';

// Calibration Grid Icons
import RoundedArrowUp from 'assets/images/RoundedArrowUp.svg?react';
import RoundedArrowRight from 'assets/images/RoundedArrowRight.svg?react';

// ReviewTemplate Icons
import RemoveIconSvg from 'assets/images/removeIcon.svg?react';
import upArrowSvg2 from 'assets/images/upArrow.svg?react';
import downArrowSvg2 from 'assets/images/downArrow.svg?react';
import DeleteIcon3Svg from 'assets/images/delete3.svg?react';
import InfoIconSvg from 'assets/images/information.svg?react';
import InfoIcon2Svg from 'assets/images/info.svg?react';
import CrossIconSvg from 'assets/images/CrossIcon.svg?react';
import PlusIconSvg from 'assets/images/PlusIcon.svg?react';
import CheckIcon2Svg from 'assets/images/CheckIcon.svg?react';
import EmptyCheckboxSvg from 'assets/images/EmptyCheckbox.svg?react';

// Objective tags icons
import CaretDown from 'assets/images/caret-down.svg?react';
import CTag from 'assets/images/c-tag.svg?react';
import CompanyTagSvg from 'assets/images/company-tag-two-tone.svg?react';
import DTag from 'assets/images/d-tag.svg?react';
import DepartmentTagSvg from 'assets/images/department-tag-two-tone.svg?react';
import ITag from 'assets/images/i-tag.svg?react';
import IndividualTagSvg from 'assets/images/individual-tag-two-tone.svg?react';
import TTag from 'assets/images/t-tag.svg?react';
import TeamTagSvg from 'assets/images/team-tag-two-tone.svg?react';
import NoTag from 'assets/images/no-tag.svg?react';
import ReplaceSvg from 'assets/images/replace.svg?react';

// thank the designer for the following icon.
import AddCircular2Svg from 'assets/images/AddCircular2.svg?react';
import ThinCurvedArrowSvg from 'assets/images/thin-curved-arrow-icon.svg?react';

// CheckInAccordion
import ManagerAndReporteeSvg from 'assets/images/ManagerReportee.svg?react';
import OnlyReporteeSvg from 'assets/images/OnlyReportee.svg?react';

// oneOnOne Icons
import TwoPeopleSvg from 'assets/images/people.svg?react';
import PlusSvg from 'assets/images/plus.svg?react';
import FilledGearSvg from 'assets/images/filled-gear.svg?react';

// OneOnOne Refreshed Icons
import RescheduleMeetingSvg from 'assets/images/RescheduleMeeting2.svg?react';
import ReopenMeetingSvg from 'assets/images/ReopenMeeting2.svg?react';
import CancelMeetingSvg from 'assets/images/CancelMeeting2.svg?react';
import CancelledMeetingsSvg from 'assets/images/CancelledMeetings.svg?react';
import ShareMeetingSvg from 'assets/images/ShareMeeting.svg?react';

// Company Settings Icons
import FlagSvg from 'assets/images/flag.svg?react';
import CoinsSvg from 'assets/images/coins.svg?react';
import ClockSvg from 'assets/images/clock.svg?react';
import ChatSvg from 'assets/images/chat.svg?react';

import ChevronLeftSvg from 'assets/images/chevronLeft.svg?react';
import ArrowThickUpSvg from 'assets/images/arrow-thick-up.svg?react';
import JustifySvg from 'assets/images/justifyParagraph.svg?react';
// Review Settings Icons
import TrophySvg from 'assets/images/trophyImage.svg?react';
import VerticalUpArrowSvg from 'assets/images/verticalUpArrow.svg?react';
import HorizontalRightArrowSvg from 'assets/images/horizontalRightArrow.svg?react';
import EditPenSvg from 'assets/images/EditPenIcon.svg?react';

// Settings panel Icons
import PinSvg from 'assets/images/pin.svg?react';

// Template Icons
import ArchiveSvg from 'assets/images/archive.svg?react';
import DuplicateSvg from 'assets/images/duplicate.svg?react';
import PreviewSvg from 'assets/images/preview.svg?react';

// arrow icons
import PositiveSentimentArrowSvg from 'assets/images/up-arrow.svg?react';
import NegativeSentimentArrowSvg from 'assets/images/top-right-arrow.svg?react';

// notification icons
import BellNotificationSvg from 'assets/images/bell_notification.svg?react';
import SlackNotificationSvg from 'assets/images/slack_notification.svg?react';
import BrowserNotificationSvg from 'assets/images/browser_notification.svg?react';
import EmailNotificationSvg from 'assets/images/email_notification.svg?react';
import NotificationReminderSvg from 'assets/images/notification-reminder.svg?react';
import NotificationTiltedSvg from 'assets/images/notification-tilted.svg?react';
import NotificationSettingsSvg from 'assets/images/notification-settings.svg?react';

import RoundedSquareCheckSvg from 'assets/images/notification-check.svg?react';
// sentiment icons
import AppreciativeFeedbackSvg from 'assets/images/feedback-appreciative.svg?react';
import ConstructiveFeedbackSvg from 'assets/images/feedback-constructive.svg?react';
import NeutralFeedbackSvg from 'assets/images/feedback-neutral.svg?react';

// React Select icons
import DownChevronSvg from 'assets/images/DownChevron.svg?react';

// Bullet point and paragraph icons
import List2Svg from 'assets/images/list_2.svg?react';
import Bullet2Svg from 'assets/images/bullet_2.svg?react';

// New OKR icons
import ObjectiveSvg from 'assets/images/objective.svg?react';
import KrSvg from 'assets/images/KR.svg?react';

import DustbinSvg from 'assets/images/dustbin.svg?react';
import MegaPhoneSvg from 'assets/images/megaphone-kudos.svg?react';
import MegaPhoneSvgColored from 'assets/images/green-praise.svg?react';
import CommentFeedbackSvg from 'assets/images/comment-feedback.svg?react';
import ImageSvg from 'assets/images/image-upload.svg?react';

import PrivateKRSvg from 'assets/images/private_kr.svg?react';
import SubDirectorySvg from 'assets/images/subdirectory.svg?react';
import ObjectiveTwoToneSvg from 'assets/images/objective-two-tone.svg?react';
import GoalsNavSvg from 'assets/images/goals_nav.svg?react';

import RealignSvg from 'assets/images/realign.svg?react';
import ParentRealignSvg from 'assets/images/parentRealign.svg?react';

import DetailsIconSvg from 'assets/images/details-icon.svg?react';
// level up icons
import SkilledSvg from 'assets/images/skilled.svg?react';
import BuildingSvg from 'assets/images/building.svg?react';
import FeedbackSvg from 'assets/images/feedback_icon.svg?react';

// RTE icons
import RteBoldSvg from 'assets/images/rte/bold.svg?react';
import RteItalicSvg from 'assets/images/rte/italic.svg?react';
import RteUnderlineSvg from 'assets/images/rte/underline.svg?react';
import RteStrikethroughSvg from 'assets/images/rte/strikethrough.svg?react';
import RteUlSvg from 'assets/images/rte/list-ul.svg?react';
import RteOlSvg from 'assets/images/rte/list-ol.svg?react';

import EditNotificationSvg from 'assets/images/editNotifications.svg?react';

import { POST_VISIBILITY_TYPES } from './ProjectConstants';
import { getColorFromKey } from './helper';

// --- all svgs directly exporting for use as it is in graphs ------
export const DeliverableSvg = DeliverableSvgI;
export const DevelopSvg = DevelopSvgI;

// ----------------------------------------------------------------

// ---------------- all png sources -------------------

export const maleIconSrc = male;
export const femaleIconSrc = female;
export const female2IconSrc = female2;
export const female3IconSrc = female3;
export const deliverIconSrc = deliver;
export const developIconSrc = develop;
export const monkSrc = monk;
export const monk2xSrc = monk2x;

// -----------------------------------------------------

// ------------ commercial logo src export  ------------
export const AmazonLogoSrc = AmazonLogo;
export const MyntraLogoSrc = MyntraLogo;
export const FlipkartLogoSrc = FlipkartLogo;
export const OlaLogoSrc = OlaLogo;
export const UberLogoSrc = UberLogo;
export const BookmyshowLogoSrc = BookMyShowLogo;
export const JabongLogoSrc = JabongLogo;
export const AmazonCircularLogoSrc = AmazonCircularLogo;
export const SlackLogoSrc = SlackLogo;
export const MSTeamsLogoSrc = MSTeamsLogo;
export const HrisLogoSrc = HrisLogo;
export const JiraLogoSrc = JiraLogo;
export const SalesforceSrc = SalesforceLogo;
export const DarwinBoxLogoSrc = DarwinBoxLogo;

// ------------------------------------------------------
export const RulerIconSrc = RulerIcon;

export function withStyledIcon(WrappedIcon) {
  return forwardRef((props, ref) => (
      <StyledIcon {...props} ref={ref}>
        <WrappedIcon />
      </StyledIcon>
    ));
}

// This Function Adds functnionality of giving SVG Icon Container size.
// Ex: Often, We get requirement of having a bordered container of 24px while icon to be 10px.
// you can pass size="fixed10px" and containerSize="fixed24px" in icon props.
export function withStyledIconV2(WrappedIcon) {
  return forwardRef((props, ref) => (
      <StyledIconV2 {...props} ref={ref}>
        <WrappedIcon />
      </StyledIconV2>
    ));
}

function isPublic(visibility) {
  return visibility === POST_VISIBILITY_TYPES.global.name;
}

export function VisibilityIcon({ visibility, onClick, size, useTeamIcon = false, ...props }) {
  return (
    <>
      {isPublic(visibility) ? (
        useTeamIcon ? (
          <TeamsIcon size={size} color="#666" onClick={onClick} />
        ) : (
          <GlobeIcon size={size} color="#666" onClick={onClick} />
        )
      ) : (
        <StyledIcon {...props} onClick={onClick} size={size}>
          <LockRoundedIcon />
        </StyledIcon>
      )}
    </>
  );
}
export const LogoutIcon = withStyledIcon(LogoutSvg);
export const EditIcon = withStyledIcon(EditSvg);
export const TaskTypeIcon = TaskTypeIconSvg;
export const LoadingIcon = withStyledIcon(LoadingSvg);
export const DelayedIcon = withStyledIcon(DelayedSvg);
export const ProgressIcon = withStyledIcon(ProgressSvg);
export const HighLowIcon = withStyledIcon(HighLowIconSvg);
export const UpArrowIcon = styled(HighLowIcon)`
  transform: rotate(270deg);
`;
export const BackArrowIcon = styled(HighLowIcon)`
  transform: rotate(180deg);
`;
export const DownArrowIcon = styled(HighLowIcon)`
  transform: rotate(90deg);
`;
export const UpDownIcon = withStyledIcon(UpDownIconSvg);
export const OngoingShuttleIcon = withStyledIcon(OngoingShuttleSvg);
export const LeftRightArrowIcon = styled(UpDownIcon)`
  transform: rotate(90deg);
`;
export const EmployeeIcon = withStyledIcon(EmployeeIconSvg);
export const CheckIcon = withStyledIcon(CheckIconSvg);
export const HierarchyIcon = withStyledIcon(HierarchySvg);
export const HierarchyTwoChildIcon = withStyledIcon(HierarchyTwoChildSvg);
export const KeyIcon = withStyledIcon(KeySvg);
export const GoogleIcon = withStyledIcon(GoogleSvg);
export const ImageIcon = withStyledIcon(ImageIconSvg);
export const GifIcon = withStyledIcon(GifIconSvg);
export const UserIcon = withStyledIcon(UserIconSvg);
export const FilterIcon = <FilterIconSvg />;

export const ApplauseIcon = withStyledIcon(ApplauseSvg);

export const HandIcon = withStyledIcon(HandSvg);

export const ApplauseFilledIcon = withStyledIcon(ApplauseFilledSvg);

export const CheckedCircle = withStyledIcon(CheckedCircleSvg);

export const UncheckedCircle = withStyledIcon(UncheckedCircleSvg);

export const TeamColored = withStyledIcon(TeamColoredSvg);

export const SettingsIcon = withStyledIcon(SettingsIconSvg);

export const SettingsIconFilled = withStyledIcon(SettingsFilledSvg);

export const SettingsCircularIcon = withStyledIcon(SettingsCircularSvg);

export const AddCircularIcon = withStyledIcon(AddCircularSvg);

export const Deadline1Icon = withStyledIcon(Deadline1Svg);

export const Deadline2Icon = withStyledIcon(Deadline2Svg);

export const InterviewIcon = withStyledIcon(InterviewSvg);

export const ManagementIcon = withStyledIcon(ManagementSvg);

export const CustomerFeedbackIcon = withStyledIcon(CustomerFeedbackSvg);

export const GoalsIcon = withStyledIcon(GoalsSvg);

export const Download1Icon = withStyledIcon(Download1Svg);

export const UninstallIcon = withStyledIcon(UninstallSvg);

export const ReviewFilledIcon = withStyledIcon(ReviewFilledSvg);

export const GoalsMiniIcon = withStyledIcon(GoalsMiniSvg);

export const UpdatedIcon = withStyledIcon(UpdatedSvg);

export const CheckMarkIcon = withStyledIcon(CheckMarkSvg);

export const CheckListIcon = withStyledIcon(CheckListSvg);

export const ChatBubbleIcon = withStyledIcon(ChatBubbleIconSvg);

export const ChatBubbleFilledIcon = withStyledIcon(ChatBubbleFilledIconSvg);

export const ResponsiveIcon = withStyledIcon(ResponsiveSvg);

export const PaperIcon = withStyledIcon(PaperSvg);

export const GroupIcon = withStyledIcon(GroupSvg);

export const TargetIcon = withStyledIcon(TargetSvg);

export const AddUserIcon = withStyledIcon(AddUserSvg);

export const HappyEmojiIcon = withStyledIcon(HappyEmojiSvg);

export const SmartEmojiIcon = withStyledIcon(SmartEmojiSvg);

export const VeryHappyEmojiIcon = withStyledIcon(VeryHappyEmojiSvg);

export const SadEmojiIcon = withStyledIcon(SadEmojiSvg);

export const AngryEmojiIcon = withStyledIcon(AngryEmojiSvg);

export const HappyColoredEmojiIcon = withStyledIcon(HappyColoredEmojiSvg);

export const SmartColoredEmojiIcon = withStyledIcon(SmartColoredEmojiSvg);

export const VeryHappyColoredEmojiIcon = withStyledIcon(VeryHappyColoredEmojiSvg);

export const SadColoredEmojiIcon = withStyledIcon(SadColoredEmojiSvg);

export const AngryColoredEmojiIcon = withStyledIcon(AngryColoredEmojiSvg);

export const AnonymousIcon = withStyledIcon(AnonymousSvg);

export const QuestionIcon = withStyledIcon(QuestionSvg);

export const SignatureIcon = withStyledIcon(SignatureSvg);

export const HeartPulseIcon = withStyledIcon(HeartPulseSvg);

export const ThickOutlineSmileIcon = withStyledIcon(ThickOutlineSmileEmojiSvg);

export const MailCircularIcon = withStyledIcon(MailCircular);

// ------------Strength Icons---------------------

export const AccountabilityEffortIcon = withStyledIcon(AccountabilityEffortSvg);

export const DriveCompetitivenessIcon = withStyledIcon(DriveCompetitivenessSvg);

export const RelationshipStrengthIcon = withStyledIcon(RelationshipStrengthSvg);

export const MentoringStrengthIcon = withStyledIcon(MentoringStrengthSvg);

export const LearningStrengthIcon = withStyledIcon(LearningStrengthSvg);

// ------------Company Settings Icons---------------------
export const FlagIcon = withStyledIcon(FlagSvg);
export const CoinsIcon = withStyledIcon(CoinsSvg);
export const ClockIcon = withStyledIcon(ClockSvg);
export const ChatIcon = withStyledIcon(ChatSvg);

// Settings panel Icons
export const PinIcon = withStyledIcon(PinSvg);
// ------------Templpate Icons---------------------
export const ArchiveIcon = withStyledIcon(ArchiveSvg);
export const DuplicateIcon = withStyledIcon(DuplicateSvg);

export const InsightsStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InsightsStrengthSvg />
  </StyledIcon>
);

export const InnovationStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InnovationStrengthSvg />
  </StyledIcon>
);

export const InfluenceStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <InfluenceStrengthSvg />
  </StyledIcon>
);

export const HarmonyStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <HarmonyStrengthSvg />
  </StyledIcon>
);

export const FocusStrengthIcon = (props) => (
  <StyledIcon {...props}>
    <FocusStrengthSvg />
  </StyledIcon>
);

// -----------------------------------------------

export const LikeIcon = withStyledIcon(LikeIconSvg);
export const FilledLikeIcon = withStyledIcon(FilledLikeIconSvg);

export const CommentIcon = withStyledIcon(CommentIconSvg);

export const CommentFilledIcon = withStyledIcon(CommentFilledIconSvg);

export const ProblemIcon = withStyledIcon(ProblemSvg);

export const LogoGifSrc = LogoGif;
export const LogoIcon = withStyledIcon(LogoSvg);
export const LogoImage = LogoSvg;
export const LogoWithoutNameImage = LogoWithoutName;
export const MeshUpIcon = MeshUpSvg;
export const MeshUpSelectedIcon = MeshUpSelectedSvg;
export const PortfolioIcon = PortfolioSvg;
export const PortfolioSelectedIcon = PortfolioSelectedSvg;
export const MyTeamIcon = MyTeamSvg;
export const AddMoolahIcon = AddMoolahSvg;
export const HomeIcon = HomeSvg;
export const HomeSelectedIcon = HomeSelectedSvg;
export const TeamSummaryIcon = TeamSummarySvg;
export const TeamSummarySelectedIcon = TeamSummarySelectedSvg;
export const DeliverableIcon = withStyledIcon(DeliverableSvg);
export const DevelopIcon = withStyledIcon(DevelopSvg);

export const MoolahIcon = ({ ref, ...props }) => (
  <StyledIcon color="secondary" ref={ref} {...props}>
    <MoolahSvg />
  </StyledIcon>
);

export const GroupMoolahIcon = ({ ref, ...props }) => (
  <StyledIcon color="green" ref={ref} {...props}>
    <GroupMoolahSvg />
  </StyledIcon>
);
export const MeshInIcon = withStyledIcon(MeshInSvg);

export const ExperienceIcon = withStyledIcon(ExperienceSvg);

export const TeamIcon = withStyledIcon(TeamSvg);

export const InsightsIcon = withStyledIcon(InsightsSvg);

// export const DeliverableIcon = Deliverable;
export const StrengthIcon = StrengthSvg;
export const NotificationIcon = NotificationSvg;
export const ThumbsDownIcon = ThumbsDown;
export const ThumbsUpIcon = ThumbsUpSvg;
export const NotificationIcon1 = withStyledIcon(Notification1Svg);
export const GrowBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <GrowBadgeOutsetSvg />
  </BadgeContainer>
);
export const LoginBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <LoginBadgeOutsetSvg />
  </BadgeContainer>
);
export const ConnectBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <ConnectBadgeOutsetSvg />
  </BadgeContainer>
);
export const ReflectBadgeOutset = (props) => (
  <BadgeContainer {...props}>
    <ReflectBadgeOutsetSvg />
  </BadgeContainer>
);

export const badgesObject = {
  grow: <GrowBadgeOutset />,
  connect: <ConnectBadgeOutset />,
  login: <LoginBadgeOutset />,
  reflect: <ReflectBadgeOutset />
};

export const CircularInfoIcon = ({
  size = 'smallest',
  backgroundColor = 'textSecondary',
  ...props // ref and props are used by the tooltip component
}) => (
  <CircularInfoContainer {...props} backgroundColor={backgroundColor} size={size} circular>
    i
  </CircularInfoContainer>
);

export function getBadge({
  key,
  iconColor = '#fafafa',
  backgroundColor = 'green',
  size = 'small'
}) {
  const params = { iconColor, backgroundColor, size };
  switch (key) {
    case 'grow':
      return <GrowBadgeOutset {...params} />;
    case 'connect':
      return <ConnectBadgeOutset {...params} />;
    case 'login':
      return <LoginBadgeOutset {...params} />;
    case 'reflect':
      return <ReflectBadgeOutset {...params} />;
    default:
      return <GrowBadgeOutset {...params} />;
  }
}

export const badges = [
  <GrowBadgeOutset backgroundColor="green" iconColor="#fafafa" />,
  <LoginBadgeOutset />,
  <ConnectBadgeOutset backgroundColor="green" iconColor="#fafafa" />,
  <ReflectBadgeOutset />
];

export const ExpandIcon = ExpandSvg;

// Need to separately import this when working directly with
// material ui icons
export function StyledIcon({ children, ...props }) {
  return <StyledSpan {...props}> {children} </StyledSpan>;
}

const StyledSpan = styled.span`
  svg {
    ${(props) => IconSizesCSS[props.size || 'smallest']};
    fill: ${(props) => getColorFromKey(props.color)};
    ${PaddingCSS};
    ${(props) =>
      props.rotate &&
      css`
        transform: rotate(${props.rotate});
      `}
    ${(props) =>
      props.stroke &&
      css`
        stroke: ${getColorFromKey(props.stroke)};
      `}
  }
  display: inline-flex;
  align-items: center;
  ${(props) =>
    (props.onClick || props.clickable) &&
    css`
      cursor: pointer;
    `};
  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `};
  ${GutterCSS};
  ${(props) =>
    props.circularOutline &&
    css`
      border: 1px solid ${getColorFromKey(props.color)};
      border-radius: 50%;
    `}
  ${(props) =>
    props.smoothEdgeOutline &&
    css`
      border: 1px solid ${getColorFromKey(props.color)};
      border-radius: 4px;
    `}
`;

// This component wraps StyledSpan and provides additional features like bgColor, circular, and container size.
export const StyledIconV2 = styled(StyledSpan)`
  justify-content: center;
  ${(props) => props.containerSize && IconSizesCSS[props.containerSize]}
  ${(props) =>
    props.bgColor &&
    css`
      background: ${getColorFromKey(props.bgColor)};
    `}
  ${(props) =>
    props.circular &&
    css`
      border-radius: 50%;
    `}
  svg {
    ${(props) =>
      props.color &&
      css`
        fill: ${(props) => getColorFromKey(props.color)};
      `}
  }
  ${(props) =>
    props.hoverShadow &&
    css`
      :hover {
        box-shadow: 3px 4px 5px 0px #0000001c;
      }
    `}
`;

const CircularInfoContainer = styled.span`
  ${(props) => props.size && IconSizesCSS[props.size]}
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.palette.text.secondary};
  ${(props) => props.circular && CircularIconCSS};
`;

export function getStrengthIcon(strengthName, iconProps = {}) {
  strengthName = strengthName.toLowerCase();
  switch (strengthName) {
    case 'accountability and effort':
      return <AccountabilityEffortIcon {...iconProps} />;
    case 'drive and competitiveness':
      return <DriveCompetitivenessIcon {...iconProps} />;
    case 'learning and application':
      return <LearningStrengthIcon {...iconProps} />;
    case 'relationship and trust':
      return <RelationshipStrengthIcon {...iconProps} />;
    case 'harmony and inclusion':
      return <HarmonyStrengthIcon {...iconProps} />;
    case 'mentoring and development':
      return <MentoringStrengthIcon {...iconProps} />;
    case 'influence and conviction':
      return <InfluenceStrengthIcon {...iconProps} />;
    case 'innovation and planning':
      return <InnovationStrengthIcon {...iconProps} />;
    case 'focus and priorities':
      return <FocusStrengthIcon {...iconProps} />;
    default:
      return <AccountabilityEffortIcon {...iconProps} />;
  }
}

export const PastIcon = PastSvg;
export const PulseIcon = PulseSvg;

// performance review icons
export const ReviewNavSelectedIcon = ReviewNavIconSelectedSvg;
export const NoRecognitionFeedbackPlaceholderImg = NoRecognitionFeedbackPlaceholderSvg;
export const ReviewChartMiniIcon = ReviewChartMiniIconSvg;
export const GrowthGraphMiniIcon = GrowthGraphMiniIconSvg;
export const ReviewMiniIcon = ReviewMiniIconSvg;
export const ReviewTeamStatusIcon = ReviewTeamStatusIconSvg;
export const PDFFileIcon = PDFFileIconSvg;
export const DownloadIcon = withStyledIcon(DownloadIconSvg);
export const EmployeeIconCircleIcon = EmployeeIconCircleSvg;
export const HashTagIcon = HashTagIconSvg;

// analytics icons

export const CloseIcon = CloseIconSvg;
export const HourglassIcon = HourglassIconSvg;
export const StyledHourGlassIcon = withStyledIcon(HourglassIcon);
export const CloseIconv1 = withStyledIcon(CloseIconv1Svg);

export const StyledCloseIcon = withStyledIcon(CloseIconSvg);

export const EmployeeProgressIcon = withStyledIcon(EmployeeGrowthIconSvg);
export const EmployeeCheckInIcon = withStyledIcon(CheckinIconSvg);

export const ShareCohortIcon = withStyledIcon(ShareIconSvg);
export const LockIcon = withStyledIcon(PrivateViewSvg);

export const FormIcon = withStyledIcon(FormIconSvg);
export const UserIcon2 = withStyledIcon(UserIcon2Svg);
export const EmailIcon = withStyledIcon(EmailIconSvg);

export const SpeakerIcon = withStyledIcon(SpeakerIconSvg);
export const MIcon = withStyledIcon(MIconSvg);
export const PlaceIcon = withStyledIcon(PlaceIconSvg);
export const CircularDownloadIcon = withStyledIcon(CircularDownloadIconSvg);
export const BarGraphIcon = withStyledIcon(BarGraphIconSvg);
export const TimelineGraphIcon = withStyledIcon(TimelineGraphIconSvg);
export const ArrowDownwardIcon = withStyledIcon(ArrowDownwardIconSvg);
export const BackArrowIcon2 = withStyledIcon(BackArrowSvg);
export const ArrowUpwardIcon = styled(ArrowDownwardIcon)`
  transform: rotate(180deg);
`;
export const DownloadArrowIcon = withStyledIcon(DownloadArrowIconSvg);
export const CalendarIcon = withStyledIcon(CalendarIconSvg);

export const CheckedCheckboxIcon = withStyledIconV2(CheckedCheckbox);
export const UncheckedCheckboxIcon = withStyledIconV2(UncheckedCheckbox);

// Continuous Feedback Icons
export const ContinuousFeedbackIcon = ContinuousFeedbackSvg;
export const ManagerViewIcon = ManagerViewSvg;
export const PrivateViewIcon = PrivateViewSvg;
export const PrivateManagerViewIcon = PrivateManagerViewSvg;
export const PublicViewIcon = PublicViewSvg;
export const SendTiltedIcon = SendTiltedSvg;
export const ShareIcon = withStyledIcon(ShareSvg);
export const RequestIcon = withStyledIcon(RequestSvg);
export const WomanIcon = withStyledIcon(WomanSvg);
export const ManIcon = withStyledIcon(ManSvg);

export const KudosSvgIcon = withStyledIconV2(KudosSvg);

// Engagement Icons
export const CloseWithStyledIcon = withStyledIcon(CloseIconSvg);
export const PreviewIcon = withStyledIconV2(PreviewSvg);

export const StarIcon = withStyledIcon(StarSvg);
// Header Icons
export const InsightsHeaderIcon = withStyledIcon(InsightsHeaderIconSvg);
export const HomeHeaderIcon = withStyledIcon(HomeHeaderIconSvg);
export const TeamHubHeaderIcon = withStyledIcon(TeamHubHeaderIconSvg);
export const PulseHeaderIcon = withStyledIcon(PulseHeaderIconSvg);
export const NotificationHeaderIcon = withStyledIcon(NotificationHeaderIconSvg);
export const ReviewHeaderIcon = withStyledIcon(ReviewHeaderIconSvg);
export const UserChatBubbleIcon = withStyledIcon(UserChatBubbleSvg);
export const TasksIcon = withStyledIcon(TasksSvg);
export const PrivateTaskIcon = withStyledIconV2(PrivateTasksSvg);
export const ArrowDownIcon = withStyledIcon(ArrowDownIconSvg);
export const SearchIcon = withStyledIcon(SearchSvg);
export const OneOnOneHeaderIcon = withStyledIcon(OneOnOneHeaderIconSvg);
export const LevelUpIcon = withStyledIcon(LevelUpIconSvg);

// Weightage Icons
export const EditWeightageIcon = withStyledIcon(EditIconSvg);
export const ViewIcon = withStyledIcon(ViewIconSvg);
export const TeamsIcon = withStyledIcon(TwoPeopleSvg);

// Nominate Icons
export const AddUserIcon2 = withStyledIcon(AddUserSvg2);
export const SeniorsIcon = withStyledIcon(SeniorsSvg);
export const JuniorsIcon = withStyledIcon(JuniorsSvg);
export const PrimaryTeamIcon = withStyledIcon(PrimaryTeamSvg);
export const OutsideTeamIcon = withStyledIcon(OutsideTeamSvg);
export const AddPeerIcon = withStyledIcon(AddPeerSvg);

export const DotsIcon = withStyledIcon(DotsSvg);
export const ListIcon = withStyledIconV2(ListSvg);
export const WinnerCupIcon = withStyledIcon(WinnerCupSvg);
export const PictureIcon = withStyledIcon(PictureSvg);
export const CrossIcon = withStyledIcon(CrossSvg);
export const CrossThickIcon = withStyledIcon(CrossThickSvg);
export const PlayIcon = withStyledIcon(PlaySvg);
export const PanningNavigationIcon = withStyledIcon(PanningNavigationSvg);
export const GlobeIcon = withStyledIcon(GlobeSvg);
export const GlobeSvgIcon = GlobeSvg;
export const EmojiSmileIcon = withStyledIcon(EmojiSmileSvg);

// Calibration Grid Icons
export const RoundedArrowUpIcon = withStyledIcon(RoundedArrowUp);
export const RoundedArrowRightIcon = withStyledIcon(RoundedArrowRight);

// Review Template Icons
export const RemoveIcon = withStyledIcon(RemoveIconSvg);
export const UpArrowIcon2 = withStyledIconV2(upArrowSvg2);
export const DownArrowIcon2 = withStyledIconV2(downArrowSvg2);
export const DeleteIcon3 = withStyledIconV2(DeleteIcon3Svg);
export const InfoIcon = withStyledIconV2(InfoIconSvg);
export const InfoIcon2 = withStyledIconV2(InfoIcon2Svg);
export const CrossIcon2 = withStyledIconV2(CrossIconSvg);
export const PlusIcon = withStyledIconV2(PlusIconSvg);
export const CheckIcon2 = withStyledIconV2(CheckIcon2Svg);
export const EmptyCheckbox = withStyledIconV2(EmptyCheckboxSvg);
export const CloseIcon2 = withStyledIconV2(CloseIconSvg);

export const OneOnOneIcon = withStyledIcon(OneOnOneSvg);
export const DeleteIcon = withStyledIcon(DeleteIconSvg);
export const DeleteIconFilled = withStyledIcon(DeleteFilledIconSvg);
export const LinkIcon = withStyledIcon(LinkIconSvg);

// Objective tags icons
export const CaretDownIcon = withStyledIcon(CaretDown);
export const CTagIcon = withStyledIcon(CTag);
export const DTagIcon = withStyledIcon(DTag);
export const ITagIcon = withStyledIcon(ITag);
export const TTagIcon = withStyledIcon(TTag);

export const CompanyTagSvgIcon = withStyledIconV2(CompanyTagSvg);
export const DepartmentTagSvgIcon = withStyledIconV2(DepartmentTagSvg);
export const IndividualTagSvgIcon = withStyledIconV2(IndividualTagSvg);
export const TeamTagSvgIcon = withStyledIconV2(TeamTagSvg);

export const NoTagIcon = withStyledIcon(NoTag);
export const ReplaceIcon = withStyledIcon(ReplaceSvg);
// thank the designer for this.
export const AddCircular2Icon = withStyledIcon(AddCircular2Svg);
export const ThinCurvedArrowIcon = withStyledIcon(ThinCurvedArrowSvg);

// Question Form icons

export const StarAnswerIcon = (props) => <StarIcon color="#FFE17D" {...props} />;
export const ScaleIcon = withStyledIcon(ScaleSvg);
export const TextBoxIcon = withStyledIcon(TextBoxSvg);
export const NumberIcon = withStyledIcon(NumberSvg);
export const OptionsIcon = withStyledIcon(OptionsSvg);
export const CommentAnswerIcon = withStyledIcon(CommentSvg);

// CheckIn Accordion
export const ManagerAndReporteeIcon = withStyledIconV2(ManagerAndReporteeSvg);
export const OnlyReporteeIcon = withStyledIconV2(OnlyReporteeSvg);

// TeamIcons
export const AddUserIcon3 = withStyledIconV2(AddUserSvg2);

// OneOnOne Icons
export const TwoPeopleIcon = TwoPeopleSvg;
export const TwoPeopleStyledIcon = withStyledIcon(TwoPeopleIcon);

// OneOnOne Refreshed Icons

export const RescheduleMeetingIcon = withStyledIconV2(RescheduleMeetingSvg);
export const ReopenMeetingIcon = withStyledIconV2(ReopenMeetingSvg);
export const CancelMeetingIcon = withStyledIconV2(CancelMeetingSvg);
export const CancelledMeetingsIcon = withStyledIconV2(CancelledMeetingsSvg);
export const ShareMeetingIcon = withStyledIconV2(ShareMeetingSvg);

export const PlusIcon2 = PlusSvg;
export const PlusIcon3 = withStyledIcon(PlusSvg);
export const FilledGearIcon = FilledGearSvg;

export const ArrowThickUpIcon = withStyledIcon(ArrowThickUpSvg);
export const ArrowThickDownIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    transform: rotate(180deg);
  `
);
export const ArrowThickRightIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    margin-right: -2px;
    transform: rotate(90deg);
  `
);
export const ArrowThickLeftIcon = withStyledIcon(
  styled(ArrowThickUpSvg)`
    margin-left: -2px;
    transform: rotate(270deg);
  `
);
export const ChevronLeftIcon = withStyledIconV2(ChevronLeftSvg);
export const ChevronRightIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(180deg);
  `
);
export const ChevronDownIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(270deg);
  `
);
export const ChevronUpIcon = withStyledIconV2(
  styled(ChevronLeftSvg)`
    transform: rotate(90deg);
  `
);

export const RemoveIconV2 = withStyledIconV2(RemoveIconSvg);
export const JustifyIcon = withStyledIconV2(JustifySvg);
// Review Settings Icons
export const TrophyIcon = withStyledIconV2(TrophySvg);
export const VerticalUpArrow = withStyledIconV2(VerticalUpArrowSvg);
export const HorizontalRightArrow = withStyledIconV2(HorizontalRightArrowSvg);
export const EditPenIcon = withStyledIconV2(EditPenSvg);

export const PositiveSentimentArrowIcon = withStyledIconV2(PositiveSentimentArrowSvg);
export const NegativeSentimentArrowIcon = withStyledIconV2(NegativeSentimentArrowSvg);

// competency redesign side panel
export const AnonymousUserIcon = withStyledIconV2(AnonymousUserIconSvg);

// notification icons

export const BellNotificationIcon = withStyledIcon(BellNotificationSvg);
export const SlackNotificationIcon = withStyledIconV2(SlackNotificationSvg);
export const BrowserNotificationIcon = withStyledIconV2(BrowserNotificationSvg);
export const EmailNotificationIcon = withStyledIconV2(EmailNotificationSvg);
export const RoundedSquareCheckIcon = withStyledIconV2(RoundedSquareCheckSvg);
// feedback sentiment icons
export const AppreciativeFeedbackIcon = withStyledIconV2(AppreciativeFeedbackSvg);
export const ConstructiveFeedbackIcon = withStyledIconV2(ConstructiveFeedbackSvg);
export const NeutralFeedbackIcon = withStyledIconV2(NeutralFeedbackSvg);
export const NotificationSettingsIcon = withStyledIconV2(NotificationSettingsSvg);
export const NotificationTiltedIcon = withStyledIconV2(NotificationTiltedSvg);
export const NotificationReminderIcon = withStyledIconV2(NotificationReminderSvg);

export const DustbinSvgIcon = withStyledIconV2(DustbinSvg);

export const DownChevronSvgIcon = withStyledIconV2(DownChevronSvg);

export const List2SvgIcon = withStyledIconV2(List2Svg);
export const Bullet2SvgIcon = withStyledIconV2(Bullet2Svg);

// feedback icon changes.
export const PraiseSvgIcon = withStyledIconV2(MegaPhoneSvg);
export const PraiseSvgIconColored = withStyledIconV2(MegaPhoneSvgColored);
export const FeedbackSvgIcon = withStyledIconV2(CommentFeedbackSvg);
export const ImagePlaceholderSvgIcon = withStyledIconV2(ImageSvg);

export const PrivateKRSvgIcon = withStyledIconV2(PrivateKRSvg);
export const ObjectiveSvgIcon = withStyledIconV2(ObjectiveSvg);
export const KrSvgIcon = withStyledIconV2(KrSvg);
// new goal icons
export const SubDirectorySvgIcon = withStyledIconV2(SubDirectorySvg);
export const ObjectiveTwoToneSvgIcon = withStyledIconV2(ObjectiveTwoToneSvg);
export const RealignSvgIcon = withStyledIconV2(RealignSvg);
export const ParentRealignIcon = withStyledIconV2(ParentRealignSvg);

// level up icons
export const SkilledIcon = withStyledIconV2(SkilledSvg);
export const BuildingIcon = withStyledIconV2(BuildingSvg);
export const FeedbackIcon = withStyledIconV2(FeedbackSvg);

// navbar icons for goals and initiatives

export const GoalsNavSvgIcon = withStyledIconV2(GoalsNavSvg);

export const DetailsSvgIcon = withStyledIconV2(DetailsIconSvg);

export const UpdateWeightageIcon = withStyledIconV2(UpdatWeightage);

// RTE Icons
export const RteBoldIcon = withStyledIconV2(RteBoldSvg);
export const RteItalicIcon = withStyledIconV2(RteItalicSvg);
export const RteUnderlineIcon = withStyledIconV2(RteUnderlineSvg);
export const RteStrikethroughIcon = withStyledIconV2(RteStrikethroughSvg);
export const RteUlIcon = withStyledIconV2(RteUlSvg);
export const RteOlIcon = withStyledIconV2(RteOlSvg);

export const EditNotificationIcon = withStyledIconV2(EditNotificationSvg);
