import { useState } from 'react';

import { Box } from '@chakra-ui/react';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownPortal,
  DropdownSub,
  DropdownSubContent,
  DropdownSubTrigger,
  DropdownTrigger,
} from '@mesh.ai/rabbit';
import CategoryMultiSelect from 'Deer/components/CategoryMultiSelect/CategoryMultiSelect';
import { UploadEmployeesPanel } from 'Deer/components/CategoryMultiSelect/UploadEmployeesPanel';
import { useToast } from 'components/hooks/useToast';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { setReviewCreationKey, useReviewCreationV1Data } from 'reducers/reviewCreationV1Slice';

import { Button as ButtonToolkitVersion } from 'uiToolkitV1/Button';
import { SelectDashed } from 'uiToolkitV1/Select';
import { TextBodyMedium } from 'uiToolkitV1/Typography';

import { saveParticipantsApi, downloadSelectedParticipantsCSVApi } from '../../dataHooks/nameAndRevieweesSectionHooks';

const PARTICIPANTS_OPTIONS = {
  ALL_EMPLOYEES: { value: 'ALL_EMPLOYEES', label: 'All employees' },
  FILTERS: { value: 'FILTERS', label: 'Filtered employees' },
  BULK_UPLOAD: { value: 'BULK_UPLOAD', label: 'Uploaded employees' },
};

const ParticipantSelectionDropdown = () => {
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const [isParticipantDropdownOpen, setIsParticipantDropdownOpen] = useState(false);

  const toast = useToast();
  const dispatch = useDispatch();
  const { reviewCycleId, totalEmployeeCount, revieweeSelectionType, selectedFilters } = useReviewCreationV1Data();

  const closeParticipantsDropdown = () => {
    setIsParticipantDropdownOpen(false);
  };

  const onUploadParticipantsSuccess = (res) => {
    toast({
      title: 'Employees CSV uploaded successfully',
      status: 'success',
    });
    dispatch(
      setReviewCreationKey({
        isAllEmployees: false,
        revieweeSelectionType: PARTICIPANTS_OPTIONS.BULK_UPLOAD.value,
        selectedFilters: null,
        totalEmployeeCount: res?.totalEmployeeCount || 0,
      })
    );
  };

  const onDownloadSelectedParticipantsCSV = () => {
    downloadSelectedParticipantsCSVApi({ reviewCycleId }).catch(() => {
      toast({
        title: 'An error occurred while uploading the participants',
        status: 'error',
      });
    });
  };

  const onChangeParticipants = async ({ value, filters = null }) => {
    const payload = {
      reviewCycleId,
      revieweeSelectionType: value,
      selectedFilters: filters,
    };

    saveParticipantsApi(payload).then((res) => {
      dispatch(
        setReviewCreationKey({
          isAllEmployees: value === PARTICIPANTS_OPTIONS.ALL_EMPLOYEES.value,
          revieweeSelectionType: value,
          selectedFilters: { ...res?.selectedFilters },
          totalEmployeeCount: res?.totalEmployeeCount || 0,
        })
      );
      closeParticipantsDropdown();
    });
  };

  const handleClickOutsideModal = () => {
    const participantSelectionModal = document.getElementById('participant-selection-modal');
    if (!participantSelectionModal) {
      setIsParticipantDropdownOpen(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Dropdown open={isParticipantDropdownOpen} onOpenChange={handleClickOutsideModal}>
        <DropdownTrigger
          style={{ width: '100%', textAlign: 'left' }}
          onClick={() => setIsParticipantDropdownOpen(true)}
        >
          <SelectDashed
            value={PARTICIPANTS_OPTIONS[revieweeSelectionType]}
            options={[]}
            handleChange={() => {}}
            placeholder=""
            getOptionValue={undefined}
            getOptionLabel={undefined}
            selectRef={undefined}
          />
        </DropdownTrigger>
        <DropdownPortal>
          <DropdownContent style={{ width: '240px' }}>
            <DropdownItem onClick={() => onChangeParticipants({ value: PARTICIPANTS_OPTIONS.ALL_EMPLOYEES.value })}>
              All employees
            </DropdownItem>
            <DropdownSub>
              <DropdownSubTrigger
                style={{ width: '100%' }}
                onPointerEnter={(event) => {
                  if (event.pointerType === 'mouse') setIsCategoryDropdownOpen(true);
                }}
              >
                Filtered employees
              </DropdownSubTrigger>
              {isCategoryDropdownOpen && (
                <DropdownPortal>
                  <DropdownSubContent style={{ minWidth: '460px', padding: 0, overflow: 'hidden' }}>
                    <Box id="participant-selection-modal">
                      <CategoryMultiSelect
                        selectedOptions={selectedFilters}
                        onSave={(filters) =>
                          onChangeParticipants({ value: PARTICIPANTS_OPTIONS.FILTERS.value, filters })
                        }
                        onCancel={closeParticipantsDropdown}
                      />
                    </Box>
                  </DropdownSubContent>
                </DropdownPortal>
              )}
            </DropdownSub>
            <DropdownSub>
              <DropdownSubTrigger style={{ width: '100%' }}>Upload employees</DropdownSubTrigger>
              <DropdownPortal>
                <DropdownSubContent style={{ minWidth: '460px', padding: 0, overflow: 'hidden' }}>
                  <UploadEmployeesPanel
                    reviewCycleId={reviewCycleId}
                    onSuccess={onUploadParticipantsSuccess}
                    handleClose={closeParticipantsDropdown}
                  />
                </DropdownSubContent>
              </DropdownPortal>
            </DropdownSub>
          </DropdownContent>
        </DropdownPortal>
      </Dropdown>
      {revieweeSelectionType === PARTICIPANTS_OPTIONS.FILTERS.value && selectedFilters && (
        <Box display="flex" flexDirection="row" my="4px" h="40px" alignItems="center">
          {revieweeSelectionType === PARTICIPANTS_OPTIONS.FILTERS.value && selectedFilters?.LOCATION && (
            <Box borderRadius="4px" boxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.25)" backgroundColor="#F2F2F2" px="4px">
              <Box>{`Location (${selectedFilters?.LOCATION?.length})`}</Box>
            </Box>
          )}
          {selectedFilters?.LOCATION?.length && selectedFilters?.COMPANY_ID?.length && (
            <TextBodyMedium mx="8px">AND</TextBodyMedium>
          )}
          {revieweeSelectionType === PARTICIPANTS_OPTIONS.FILTERS.value && selectedFilters?.COMPANY_ID && (
            <Box borderRadius="4px" boxShadow="0px 0px 1px 0px rgba(0, 0, 0, 0.25)" backgroundColor="#F2F2F2" px="4px">
              <Box>{`Company (${selectedFilters?.COMPANY_ID?.length})`}</Box>
            </Box>
          )}
        </Box>
      )}

      <Box display="flex" alignItems="center" h="40px" w="100%">
        <Box>{`${totalEmployeeCount} employees selected`}</Box>
      </Box>
      {totalEmployeeCount > 0 && (
        <Box display="flex" alignItems="center" h="40px" w="100%">
          <ButtonToolkitVersion
            sx={{
              height: 'auto!important',
              padding: '0px!important',
              marginRight: '4px',
              marginLeft: '4px',
              '.chakra-text': { fontSize: '14px', margin: '0px!important', lineHeight: '20px' },
            }}
            variant="tertiary"
            onClick={() => onDownloadSelectedParticipantsCSV()}
          >
            Download
          </ButtonToolkitVersion>
          <TextBodyMedium color="text.g2"> list of selected employees.</TextBodyMedium>
        </Box>
      )}
    </Box>
  );
};

export default ParticipantSelectionDropdown;
