// Libraries
import { FormControl } from '@chakra-ui/react';
import styled from '@emotion/styled';
import ReactSelect from 'react-select';

export const StyledReactSelect = styled(ReactSelect)`
  font-weight: ${(props) => props.fontWeight ?? '400'};
  width: ${(props) => props.width ?? '100%'};
  .select__control {
    border: none;
    font-size: ${(props) => props.theme.customFontStyles.size.bodyM};
    font-weight: inherit;
    background: ${(props) => (props.withBackground ? props.theme.colors.common.white : 'transparent')};
    min-height: auto;
    cursor: pointer;
    min-width: ${(props) => props.minWidth ?? '100px'};
  }

  .select__control--is-focused {
    box-shadow: none;
  }

  .select__value-container {
    padding: 0;
  }

  .select__single-value {
    // margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 0;
  }

  .select__placeholder {
    margin-left: 0;
    color: #999999;
  }

  .select__menu {
    background: ${(props) => props.theme.colors.common.white};
    padding: 8px;
    border-radius: 8px;
    box-shadow: ${(props) => props.theme.shadows.large};

    .select__menu-list {
      padding: 0;

      .select__option {
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
      }

      .select__option--is-focused {
        background: ${(props) => props.theme.colors.functional.E6F1FD};
      }

      .select__option--is-selected {
        background: ${(props) => props.theme.colors.functional.E6F1FD};
      }

      .select__option:not(:last-of-type) {
        margin-bottom: 8px;
      }

      .select__option--is-disabled {
        opacity: 24%;
        cursor: not-allowed;
      }
    }
  }
  .select__clear-indicator,
  .select__indicator {
    padding: 0;
  }
`;

export const StyledFormControlV2 = styled(FormControl)`
  & .select__control {
    width: 100%;
    padding: ${(props) => (props.controlPadding ? props.controlPadding : ' 5px 4px 5px 0 !important')};
    border-bottom: ${(props) => (props.hideBorder ? 'none' : '1px dashed #999999 !important')};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: ${(props) => (props.controlHeight ? props.controlHeight : ' 40px')};
    :hover {
      border-bottom-style: ${(props) => (props.hideBorder ? 'none' : 'solid !important')};
      border-color: #333333 !important;
    }
  }
  & .select__menu {
    z-index: 100 !important; // this is to make sure the dropdown works on top of performance distribution graph on review calibrate intent page
    min-width: ${(props) => (props.isMobileView ? '130px' : ' 200px')};
  }
  & .select__control--is-focused {
    border-bottom: ${(props) =>
      props.hideBorder ? 'none' : `1px solid ${props.theme.colors.button.blue.default} !important`};
  }
`;
